import { useTranslation } from 'react-i18next';

import { Modal, Button, Form, Input } from 'antd';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const StakeholderModal = (props: any) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const handleFormSubmit = () => {
		console.log('handleFormSubmit');
		form.validateFields()
			.then((values) => {
				console.log('validated', values);
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				onClick={() => {
					props.onDelete(props);
				}}
				danger={true}
				style={{ marginRight: 'auto' }}
			>
				Delete
			</Button>
			<Button
				className="mr-auto"
				onClick={() => {
					props.onHide();
				}}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	return (
		<Modal
			open={true}
			title={props.id ? 'Stakeholder edition' : 'New stakeholder'}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			maskClosable={false}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values);
					props.onHide();
				}}
				initialValues={props.values}
			>
				<Form.Item required={true} name="name" label="Name" rules={[
					{
						max: 35,
						message:
							t("messages.form.max_length_exceeded_error", { length: 35 })
					},
					{
						required: true,
						message: t("messages.form.required_error")
					}
				]}>
					<Input count={{
						show: true,
						max: 35,
					}}></Input>
				</Form.Item>

				<Form.Item
					name="description"
					label={t('models.item.fields.description')}
					rules={[
						{
							max: 300,
							message:
								t("messages.form.max_length_exceeded_error", { length: 300 })
						}
					]}
				>
					<Input.TextArea
						rows={7}
						count={{
							show: true,
							max: 300,
						}}
					></Input.TextArea>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default StakeholderModal;
