import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { useTranslation, initReactI18next } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass, faEdit, faLink } from '@fortawesome/pro-light-svg-icons';

import './Amphi.sass';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

const rotate = (
	cx: number,
	cy: number,
	x: number,
	y: number,
	angle: number
) => {
	var radians = (Math.PI / 180) * angle,
		cos = Math.cos(radians),
		sin = Math.sin(radians),
		nx = cos * (x - cx) + sin * (y - cy) + cx,
		ny = cos * (y - cy) - sin * (x - cx) + cy;
	return [nx, ny];
};

let actions = [
	{
		item: (
			<g className="medium">
				<FontAwesomeIcon icon={faCircle} />
			</g>
		),
		action: 'impact',
		value: 'medium'
	},
	{
		item: (
			<g className="big">
				<FontAwesomeIcon icon={faCircle} />
			</g>
		),
		action: 'impact',
		value: 'high'
	},
	{
		item: <FontAwesomeIcon color="#00B0A9" icon={faCircle} />,
		action: 'trend',
		value: 'green'
	},
	{
		item: <FontAwesomeIcon icon={faCircle} />,
		action: 'trend',
		value: 'neutral'
	},
	{
		item: <FontAwesomeIcon color="#FCC356" icon={faCircle} />,
		action: 'trend',
		value: 'orange'
	},

	{
		item: (
			<FontAwesomeIcon className="big" color="#E70549" icon={faCircle} />
		),
		action: 'trend',
		value: 'red'
	},
	{
		item: <FontAwesomeIcon icon={faEdit} />,
		action: 'edit'
	},
	{
		item: <FontAwesomeIcon icon={faLink} />,
		action: 'link'
	},

	{
		item: (
			<g className="small">
				<FontAwesomeIcon icon={faCircle} />
			</g>
		),
		action: 'impact',
		value: 'low'
	}
];
let [startX, startY, width] = [30, 0, 75];
let delta = 1.5;

const getCoordinates = (
	startX: number,
	endX: number,
	angle: number,
	delta: number
) => {
	let [xOutside, yOutside] = rotate(0, 0, endX, 0, angle);
	let [xInside, yInside] = rotate(0, 0, startX, 0, angle);

	let [xInsideRight, yInsideRight] = rotate(
		xOutside,
		yOutside,
		xInside,
		yInside,
		delta
	);
	let [xInsideLeft, yInsideLeft] = rotate(
		xOutside,
		yOutside,
		xInside,
		yInside,
		-delta
	);

	let [xOutsideLeft, yOutsideLeft] = rotate(
		xInside,
		yInside,
		xOutside,
		yOutside,
		delta
	);
	let [xOutsideRight, yOutsideRight] = rotate(
		xInside,
		yInside,
		xOutside,
		yOutside,
		-delta
	);

	return {
		xOutside,
		yOutside,
		xInside,
		yInside,

		xInsideRight,
		yInsideRight,
		xInsideLeft,
		yInsideLeft,
		xOutsideLeft,
		yOutsideLeft,
		xOutsideRight,
		yOutsideRight
	};
};

export function Amphi(props: any) {
	const [opened, setOpened] = React.useState<true | false>(true);

	if (!props.visible || !props.selection) return null;
	return (
		<div
			id="Amphi"
			style={{
				transform: 'translate( ' + props.x + 'px, ' + props.y + 'px )'
			}}
			className={props.visible ? 'visible' : ''}
		>
			{false && (
				<div className="actions">
					{actions.map((a, index) => {
						let startAngle = ((index + 0.5) / actions.length) * 360;
						let [x, y] = rotate(
							0,
							0,
							width - startX + 8,
							0,
							startAngle
						);

						return (
							<div
								className="action"
								style={{ top: x + 'px', left: y + 'px' }}
							>
								{a}
							</div>
						);
					})}
				</div>
			)}
			<svg
				style={{ width: '200px', height: '200px' }}
				viewBox="-100 -100 200 200"
			>
				<circle
					x="0"
					y="0"
					r="28"
					fill="#FFFFFF00"
					onClick={() => props.onDismiss()}
				></circle>

				{actions.map((a, index) => {
					let startAngle = (index / actions.length) * 360;
					let midAngle = ((index + 0.5) / actions.length) * 360;
					let endAngle = ((index + 1) / actions.length) * 360;

					let coordinatesCurrent = getCoordinates(
						startX,
						width,
						startAngle,
						delta
					);
					let coordinatesNext = getCoordinates(
						startX,
						width,
						endAngle,
						delta
					);

					let [x, y] = rotate(0, 0, width - startX + 8, 0, midAngle);

					return (
						<g
							className={[
								'sliceWrapper',
								a.action &&
								props.selection &&
								props.selection[a.action] &&
								props.selection[a.action] == a.value
									? 'selected'
									: ''
							].join(' ')}
							key={index}
							onClick={() => props.onChange(a.action, a.value)}
						>
							{
								<path
									className="slice"
									fill="#363D4D"
									d={[
										[
											'M' +
												coordinatesCurrent.xInsideLeft,
											coordinatesCurrent.yInsideLeft
										].join(' '),

										[
											'A',
											startX,
											startX,
											1,
											0,
											0,
											coordinatesNext.xInsideRight,
											coordinatesNext.yInsideRight
										].join(' '),

										[
											'L' + coordinatesNext.xOutsideRight,
											coordinatesNext.yOutsideRight
										].join(' '),

										// Arc
										[
											'A',
											width,
											width,
											0,
											0,
											1,
											coordinatesCurrent.xOutsideLeft,
											coordinatesCurrent.yOutsideLeft
										].join(' '),

										'Z'
									].join(' ')}
								></path>
							}{' '}
							<g
								className="action"
								transform={`translate(${x - 10}, ${y - 10}) scale(0.1) `}
							>
								{a.item}
							</g>
						</g>
					);
				})}
			</svg>
		</div>
	);
}
