import { useTranslation } from 'react-i18next';
import { Modal, Button, Form, Input, DatePicker } from 'antd';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const ProjectModal = (props: any) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const handleFormSubmit = () => {
		console.log('handleFormSubmit');
		form.validateFields()
			.then((values) => {
				console.log('validated', values);
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				style={{ marginRight: 'auto' }}
				onClick={() => {
					props.onHide();
				}}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	return (
		<Modal
			open={true}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			maskClosable={false}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values);
					props.onHide();
				}}
				initialValues={props.values}
			>
				<Form.Item
					name="description"
					label={t('models.project.fields.description')}
					rules={[
						{
							max: 300,
							message:
								t("messages.form.max_length_exceeded_error", { length: 300 })
						}
					]}
				>
					<Input.TextArea
						rows={5}
						count={{
							show: true,
							max: 300,
						}}
						placeholder={t(
							'models.project.fields.description_explanation'
						)}
					></Input.TextArea>
				</Form.Item>
				<Form.Item
					name="fishboneDescription"
					label={t('models.project.fields.fishbone_description')}
					rules={[
						{
							max: 300,
							message:
								t("messages.form.max_length_exceeded_error", { length: 300 })
						}
					]}
				>
					<Input.TextArea
						rows={7}
						placeholder={t(
							'models.project.fields.fishbone_description_explanation'
						)}
						count={{
							show: true,
							max: 300,
						}}
					></Input.TextArea>
				</Form.Item>
				<Form.Item name="responsible" label="Responsible name" rules={[
					{
						max: 100,
						message:
							t("messages.form.max_length_exceeded_error", { length: 100 })
					}
				]}>
					<Input count={{
						show: true,
						max: 100,
					}}></Input>
				</Form.Item>

				<div className="d-flex">
					<Form.Item name="fishboneStartOn" label="Start">
						<DatePicker format="DD/MM/YYYY"></DatePicker>
					</Form.Item>
					<Form.Item name="fishboneEndOn" label="End">
						<DatePicker format="DD/MM/YYYY"></DatePicker>
					</Form.Item>
				</div>
			</Form>
		</Modal>
	);
};

export default ProjectModal;
