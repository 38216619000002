import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faChevronRight
} from '@fortawesome/pro-light-svg-icons';

import './Nav.sass';

export default (props: any) => (
	<div
		style={{ textAlign: 'center' }}
		className="Navigation noselection d-flex justify-content-center w-100 mb-1"
	>
		<a
			onClick={props.onGoToPreviousScenario}
			className="action"
			style={{ marginRight: '1rem' }}
		>
			<FontAwesomeIcon icon={faChevronLeft} />
		</a>
		<div className={props.fullWidth ? 'w-100' : ''}>{props.children}</div>
		<a
			onClick={props.onGoToNextScenario}
			className="action"
			style={{ marginLeft: '1rem' }}
		>
			<FontAwesomeIcon icon={faChevronRight} />
		</a>
	</div>
);
