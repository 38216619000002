import React from "react";
import { Table as AntTable } from "antd";
import { isFunction } from "lodash";

const defaultSort = (a, b) => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
  };

const Table = (props) => {
  const { columns, ...otherTableProps } = props;

  const sortableColumns = columns.map((column) => {
    const { sortable, dataIndex, ...otherColumnProps } = column;

    if (sortable) {

      return {
        ...otherColumnProps,
        dataIndex,
        sorter: {
          compare: (rowA: object, rowB: object) => {
            if( isFunction(sortable) ) return sortable(rowA, rowB, dataIndex);

            return defaultSort(rowA[dataIndex], rowB[dataIndex])
          }
        }
      };
    }

    return { ...otherColumnProps, dataIndex };
  });

  return <AntTable columns={sortableColumns} {...otherTableProps} />;
};

export default Table;