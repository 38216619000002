import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import './ThankYou.sass';

export const ThankYou = (props: any) => {
	const { t } = useTranslation();
	let history = useHistory();
	const [seconds, setSeconds] = useState(5);

	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds((seconds) => {
				if (seconds <= 0) history.push('/');

				return seconds - 1;
			});
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className="ThankYou">
			<div>
				Thank you for your subscription.
				<br />
				<br />
				You will be redirected in {seconds} seconds
			</div>
		</div>
	);
};
