export default {
	project: {
		my_xs: 'My decisions',
		fields: {
			name: 'Title',
			name_explanation: 'Provide a title for your project',
			description: 'Goal',
			description_explanation: 'State your overall goal',
			description_long: 'Description',
			description_long_explanation: '',
			fishbone_description: 'Fishbone - To be',
			fishbone_description_explanation: '',
			tags: 'Tags',
			likelihoods: 'Likelihoods',
			impacts: 'Impacts',
			types: {
				stakeholders: 'Stakeholders',
				stakeholders_long: 'Stakeholders project',
				risks: 'Risks',
				risks_long: 'Risks project',
				pro: 'Strategy',
				pro_long: 'Strategy project'
			}
		},
		messages: {
			delete_confirmation: 'Delete project',
			delete_confirmation_explanation:
				'Are you sure you would like to delete the project "{{name}}"? This action can\'t be undone.',
			clone_confirmation: 'Clone project',
			clone_confirmation_explanation:
				'Are you sure you would like to clone this project?',
			clone_in_progress: 'We are cloning your project, please wait...'
		},
		actions: {
			edit: 'Edit project details',
			delete: 'Delete project',
			clone: 'Clone project'
		}
	},
	option: {
		fields: {
			name_explanation: 'Please enter a name for this option'
		}
	},
	item: {
		name: 'Stakeholder',
		name_plural: 'Stakeholders',
		activeness_zones: {
			external_outer_positive: 'Neutral',
			external_inner_positive: 'Inactive',
			external_outer_negative: 'Neutral',
			external_inner_negative: 'Inactive',

			internal_low_positive: 'Low',
			internal_medium_positive: 'Medium',
			internal_high_positive: 'High',
			internal_low_negative: 'Low',
			internal_medium_negative: 'Medium',
			internal_high_negative: 'High',

			internal_positive: 'Catalysts',
			internal_negative: 'Inhibitors'
		},
		messages: {
			delete_confirmation: 'Delete stakeholder',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this stakeholder? This action can't be undone."
		},
		fields: {
			description: 'Description',
			links: {
				outcoming: {
					name: 'Outcoming links'
				},
				incoming: {
					name: 'Incoming links'
				}
			},
			indicators: {
				name: 'Stakes relations'
			},
			constraints: {
				name: 'Indicators relations'
			},
			risks: {
				name: 'Risks relations'
			},
			opportunities: {
				name: 'Opportunities relations'
			}
		},
		actions: {
			create_new: 'Create a new stakeholder',
			edit: 'Edit details',
			delete: 'Delete stakeholder',
			clone: 'Clone stakeholder'
		},
		no_x: 'No stakeholder'
	},
	item_link: {
		no_x: 'No link',
		actions: {
			create_new: 'Create new link'
		}
	},
	item_comment: {
		no_x: 'No history',
		actions: {
			create_new: 'Create new history'
		}
	},
	tag: {
		name: 'Tag',
		name_plural: 'Tags',
		fields: {
			name: 'Tag',
			name_plural: 'Tags'
		},
		messages: {
			delete_confirmation: 'Delete tag',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this tag? This action can't be undone.",
			no_x: 'No tag'
		},
		actions: {
			add_x: 'Add a tag',
			create_new: 'Create a tag',
			edit: 'Edit tag',
			delete: 'Delete tag'
		}
	},
	indicator: {
		no_x: 'No stake',
		name: 'Stake',
		name_plural: 'Stakes',
		fields: {
			name: 'Name',
			reference: 'Reference',
			name_plural: 'Stakes',
			evaluated_at: 'Last evaluation',
			weight: 'Weight',
			trend: 'Status',
			risks: {
				name: 'Risks relations',
				name_positive: 'Opportunities relations'
			}
		},
		action: {
			add_x: 'Add a stake'
		},
		message: {
			no_x: 'No stake'
		},
		messages: {
			delete_confirmation: 'Delete stake',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this stake? This action can't be undone."
		},
		actions: {
			delete: 'Delete stake'
		}
	},

	constraint: {
		name: 'Indicator',
		name_plural: 'Indicators',
		no_x: 'No indicator',
		fields: {
			name: 'Name',
			reference: 'Reference',
			name_plural: 'Indicators',
			evaluated_at: 'Last evaluation'
		},
		action: {
			add_x: 'Add an indicator point'
		},
		message: {
			no_x: 'No indicator'
		},
		messages: {
			delete_confirmation: 'Delete indicator',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this indicator? This action can't be undone."
		},
		actions: {
			delete: 'Delete indicator'
		}
	},

	scenario: {
		no_x: 'No scenario',
		baseline: 'Baseline',
		fields: {
			name: 'Name',
			name_long: 'Scenario name',
			score: 'Scenario score'
		},
		messages: {
			delete_confirmation: 'Delete scenario',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this scenario? This action can't be undone.",
			switch_scenario: 'Switch to baseline or another scenario',

			promote_as_baseline: {
				title: 'Promote this scenario as the new baseline',
				explanation:
					"Are you sure you would like to promote the scenario “<0>{{name}}</0>” as the new baseline? This action can't be undone.",
				keep_current_baseline_as_scenario_question:
					'What would you like to do with the former baseline?',
				keep_current_baseline_as_scenario_question_yes:
					'I want to keep it and give it the following name',
				keep_current_baseline_as_scenario_question_no:
					'Nothing, it can be deleted',
				keep_scenario_question:
					'Would you like to keep a copy of the scenario now promoted as the new baseline?',
				keep_scenario_question_yes: 'Yes, keep a copy',
				keep_scenario_question_no: "No, I don't need a copy"
			}
		},
		actions: {
			create_new: 'Create a new scenario from baseline',
			clone: 'Clone scenario',
			edit: 'Edit scenario',
			delete: 'Delete scenario',
			next: 'Go to next scenario',
			previous: 'Go to previous scenario',
			previous_baseline: 'Go to baseline scenario'
		}
	},
	fishbone_branch: {
		name: 'Fishbone branch',
		name_plural: 'Fishbone branchs',
		messages: {
			delete_confirmation: 'Delete fishbone branch',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this branch? This action can't be undone."
		}
	},
	fishbone_item: {
		name: 'Fishbone measure',
		name_plural: 'Fishbone measure',
		messages: {
			delete_confirmation: 'Delete fishbone measure',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this measure? This action can't be undone."
		}
	},
	risk: {
		name: 'Risk',
		name_plural: 'Risks',
		name_positive: 'Opportunity',
		name_plural_positive: 'Opportunities',
		fields: {
			name: 'Name',
			description: 'Description - Indicators of occurence',
			reference: 'Reference',
			risk_category: 'Category',
			likelihood: 'Likelihood',
			likelihood_level: 'Likelihood level name',
			impact: 'Impact',
			impact_level: 'Impact level name',
			matching_opportunity: 'Matching opportunity',
			matching_risks: 'Matching risks',
			evaluated_at: 'Last evaluation',
			exposure: 'Exposure',
			exposure_positive: 'Upside',

			opportunity: {
				name: 'Opportunity'
			}
		},
		messages: {
			delete_confirmation: 'Delete risk',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this risk? This action can't be undone.",
			no_x: 'No risk',
			no_category: 'No category',
			no_x_positive: 'No opportunity'
		},
		actions: {
			create_new: 'Create a new risk or opportunity',
			edit: 'Edit risk',
			edit_opportunity: 'Edit opportunity',
			delete: 'Delete risk'
		}
	},
	risk_category: {
		name: 'Category',
		name_plural: 'Categories',
		fields: {
			name: 'Name'
		},
		messages: {
			delete_confirmation: 'Delete Category',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this Category? This action can't be undone.",
			no_x: 'No Category'
		},
		actions: {
			create_new: 'Create a new Category',
			edit: 'Edit Category',
			delete: 'Delete Category',
			add_x: 'Add a Category'
		}
	}
};
