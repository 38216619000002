import React, { Component, useEffect, useState } from 'react';

import { keys, isObject } from 'lodash';

import { Modal, Button, Form, Input, Slider, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const FishboneItemEditionModal = (props: any) => {
	const [form] = Form.useForm();
	const [areTooltipVisibles, setAreTooltipVisibles] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setTimeout(() => {
			setAreTooltipVisibles(true);
		}, 400)
	}, []);

	const handleFormSubmit = () => {
		console.log('handleFormSubmit FishboneItemEditionModal');
		form.validateFields()
			.then((values) => {
				console.log('validated', values);
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			{props.onDelete && (
				<Button
					onClick={() => {
						props.onDelete(props);
					}}
					danger={true}
					style={{ marginRight: 'auto' }}
				>
					Delete
				</Button>
			)}
			<Button
				onClick={() => {
					props.onHide();
				}}
				style={{ marginRight: 'auto' }}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	const isDisabledDate = (current) => {
		if (props.minDate && dayjs(current).isBefore(props.minDate))
			return true;
		if (props.maxDate && dayjs(current).isAfter(props.maxDate))
			return true;
		return false;
	};

	return (
		<Modal
			open={true}
			title={props.id ? 'Sub-bone edition' : 'Create a new sub-bone'}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			maskClosable={false}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values);
					props.onHide();
				}}
				initialValues={props.values}
			>
				<Form.Item
					name="name"
					label="Name"
					required={true}
					rules={[
						{
							max: 30,
							message:
								t("messages.form.max_length_exceeded_error", { length: 30 })
						},
						{
							required: true,
							message: t("messages.form.required_error")
						}
					]}
				>
					<Input
						autoComplete='off'
						count={{
							show: true,
							max: 30,
						}}></Input>
				</Form.Item>
				<Form.Item
					name="responsible"
					label="Responsible name"
					rules={[
						{
							max: 100,
							message:
								t("messages.form.max_length_exceeded_error", { length: 100 })
						}
					]}>
					<Input
						autoComplete='off'
						count={{
							show: true,
							max: 100,
						}}></Input>
				</Form.Item>
				<Form.Item name="description" label="Description" rules={[
					{
						max: 300,
						message:
							t("messages.form.max_length_exceeded_error", { length: 300 })
					}
				]}>
					<Input.TextArea
						rows={5}
						count={{
							show: true,
							max: 300,
						}}></Input.TextArea>
				</Form.Item>
				<div className="d-flex">
					<Form.Item name="startOn" label="Start">
						<DatePicker
							disabledDate={isDisabledDate}
							format="DD/MM/YYYY"
						></DatePicker>
					</Form.Item>
					<Form.Item name="endOn" label="End">
						<DatePicker
							disabledDate={isDisabledDate}
							format="DD/MM/YYYY"
						></DatePicker>
					</Form.Item>
				</div>

				<Form.Item name="weight" label="Weight">
					<Slider
						tooltip={{ open: areTooltipVisibles, placement: 'bottom' }}
						dots={true}
						min={1}
						max={3}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default FishboneItemEditionModal;
