import React, { useCallback, useEffect } from 'react';

import { useAuth0 } from '../../react-auth0-spa';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faClone,
	faInfoCircle,
	faSignIn,
	faTrash,
	faUser
} from '@fortawesome/pro-light-svg-icons';

import HeaderPortal from '../../layout/header/HeaderPortal';
import { ProjectsList } from './ProjectsList';
import AddButton from 'components/action/AddButton';
import Loader from 'components/Loading/Loader';
import ProjectModal from 'components/Modal/ProjectModal';

// Help
import TutorialPopover from 'components/Help/TutorialPopover';
import { getNextStep, getInitialStep } from 'help/projects';

import {
	useConsumeCodeMutation
} from 'graph/dist/generated';

import { useListProjectsQuery } from 'graph/queries';
import { Input, Segmented } from 'antd';

import './Projects.sass';
import { useProjectController } from 'hooks/useProjectController';
import WaitModal from 'components/Modal/WaitModal';

export const Projects = (props: any) => {
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const [editedItem, setEditedItem] = React.useState<any | null>(null);

	const [consumeCode] = useConsumeCodeMutation();
	const { createProject, cloneProject, deleteProject } = useProjectController();

	const [searchTerm, setSearchTerm] = React.useState<any | null>('');
	const [projectTypeFilter, setProjectTypeFilter] = React.useState<any | null>(null);

	const { data, refetch, loading } = useListProjectsQuery();

	const { t } = useTranslation();
	let history = useHistory();

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(
		null
	);
	const closeHelp = () => setVisibleHelpStep(null);
	const initialStep = getInitialStep(
		data?.listProjects?.items,
		props.isSubscriber
	);
	const nextStep = getNextStep(
		visibleHelpStep,
		data?.listProjects?.items,
		props.isSubscriber
	);
	const onNext = () => setVisibleHelpStep(nextStep);
	const hasNext = !!nextStep;

	const hasLessThanAuthorizedProjects =
		((data && data.listProjects && data.listProjects?.items?.length) || 0) <
		props.maxProjectsCount;

	const canCreateProjects =
		props.subscriptionsAreDisabled || hasLessThanAuthorizedProjects;

	useEffect(() => {
		if (props.setExpanded) props.setExpanded(false);

		if (localStorage.getItem('gerositusCode')) {
			let code = localStorage.getItem('gerositusCode');
			console.log(
				'gerositusCode found',
				localStorage.getItem('gerositusCode')
			);
			consumeCodeAndUpdateSubscription(code);
		}
	}, []);

	const handleCreateProject = async (values: any) => {
		const result = await createProject(values);

		await refetch();
		props.deselect();
		props.resetBotThread();
		history.push('/projects/' + result);
	};

	const handleCloneProject = async (id: string, values) => {
		setTimeout(() => props.pushModal(<WaitModal></WaitModal>), 100);
		await cloneProject(id, values, async (newId) => {
			props.popModal();
			await refetch();
		});
	}

	const handleSelectProject = async (project: any) => {
		props.deselect();
		props.resetBotThread();
		history.push('/projects/' + project.id);
	};

	const consumeCodeAndUpdateSubscription = async (code) => {
		await consumeCode({ variables: { id: code } });
		localStorage.removeItem('gerositusCode');
		await props.refreshSubscription();
	};

	const filteredProjects = data?.listProjects?.items?.filter((project) => {

		if (projectTypeFilter && projectTypeFilter !== "all" && projectTypeFilter !== project.type) return false;

		if (!searchTerm) return true;

		let terms = searchTerm ? searchTerm.split(' ') : [];
		return terms.every((term) => {
			return project.name.toLowerCase().includes(term.toLowerCase()) || project.reference?.toLowerCase().includes(term.toLowerCase())
		});
	});

	return (
		<div className="Projects">
			<HeaderPortal>
				{t('pages.projects.title')}

				<small className="ml-2">
					{t('pages.projects.description')}
				</small>

				{props.isFeatureEnabledProfile && (
					<FontAwesomeIcon
						style={{
							marginLeft: 'auto',
							marginRight: '1rem',
							cursor: 'pointer'
						}}
						icon={faInfoCircle}
						onClick={() => setVisibleHelpStep(initialStep)}
					/>
				)}
				{props.isFeatureEnabledProfile && (
					<TutorialPopover
						onClose={closeHelp}
						placement={'left'}
						visible={visibleHelpStep == 2}
						body="Edit your profile and/or sign out"
						onNext={onNext}
						hasNext={hasNext}
					>
						{!isAuthenticated ? (
							<>
								<a
									onClick={(e) => {
										e.preventDefault();
										loginWithRedirect();
									}}
								>
									<FontAwesomeIcon icon={faSignIn} />
								</a>
							</>
						) : (
							<>
								<a
									onClick={() => {
										history.push('/profile');
									}}
								>
									<FontAwesomeIcon icon={faUser} />
								</a>
							</>
						)}
					</TutorialPopover>
				)}
			</HeaderPortal>

			{!props.subscriptionsAreDisabled &&
				(loading || props.subscriptionIsLoading) ? (
				<Loader></Loader>
			) : (
				<>
					<div className="w-100 d-flex justify-content-center mb-4">
						<Input
							className="SearchInput"
							placeholder='Search projects'

							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
						></Input>

						<Segmented
							className="Segmented ml-2 align-self-center"
							options={[
								{
									label: "All",
									value: "all"
								},
								{
									label: t("models.project.fields.types.pro"),
									value: "pro"
								},
								{
									label: t("models.project.fields.types.stakeholders"),
									value: "stakeholders"
								},
								{
									label: t("models.project.fields.types.risks"),
									value: "risks"
								}
							]}
							onChange={(value) => {
								setProjectTypeFilter(value);
							}}
						/>
					</div>

					<ProjectsList
						pushModal={props.pushModal}
						popModal={props.popModal}
						onSelectProject={handleSelectProject}
						canClone={canCreateProjects}
						onClone={handleCloneProject}
						onDelete={async (id) => {
							await deleteProject(id);
							await refetch();
						}}
						list={filteredProjects}
						refetch={refetch}
						authorizedProjectTypes={props.authorizedProjectTypes}
						tutorialPopover={
							<TutorialPopover
								onClose={closeHelp}
								placement="bottom"
								onNext={onNext}
								hasNext={hasNext}
								visible={visibleHelpStep == 3}
								body={
									<>
										Click to open an existing stakeholder
										map.
										<br />
										You can delete it by clicking on{' '}
										<FontAwesomeIcon icon={faTrash} />
										<br />
										You can clone it by clicking on{' '}
										<FontAwesomeIcon icon={faClone} />
									</>
								}
							></TutorialPopover>
						}
					></ProjectsList>
					{canCreateProjects ? (
						<div className="footer mr-auto ml-auto" style={{ width: "90%" }}>
							<AddButton
								onClick={() => {
									setEditedItem({});
								}}
								popover={
									<TutorialPopover
										onClose={closeHelp}
										placement="top"
										onNext={onNext}
										hasNext={hasNext}
										visible={visibleHelpStep == 1}
										body="Create a new project"
									></TutorialPopover>
								}
							></AddButton>{' '}
						</div>
					) : (
						!props.isSubscriber && (
							<div
								className="subscribeNow"
								onClick={() => history.push('/profile')}
							>
								{' '}
								Welcome and thank you for connecting to
								Gerositus Stakeholders Management! <br />
								<u>Subscribe now</u> to be able to create new
								stakeholder maps.
								<br />
								Upon subscription, you have 3 days of free
								trial.
							</div>
						)
					)}
				</>
			)}

			{editedItem !== null && (
				<ProjectModal
					onHide={() => {
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						handleCreateProject(values);
						setEditedItem(null);
					}}
					id={editedItem?.id}
					key={editedItem?.id || 'new'}
					values={{
						name: editedItem?.name,
						reference: editedItem?.reference,
						description: editedItem?.description,
						descriptionLong: editedItem?.descriptionLong,
						likelihoods: editedItem?.likelihoods,
						impacts: editedItem?.impacts,
						tags: editedItem?.tags,
						riskCategories: editedItem?.riskCategories,
						type: null
					}}
					authorizedTypes={props.authorizedProjectTypes}
				></ProjectModal>
			)}


		</div>
	);
};
