import React, { Component } from 'react';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartNetwork, faChess, faPlus, faShieldExclamation, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Modal, Button, Form, Input, Tabs, Empty, Card, Select } from 'antd';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

import "./ProjectCloneModal.sass";

interface ProjectCloneModalValues {
	name: string;
	reference: string;
	description: string;
	descriptionLong: string;
	tags: any[];
	riskCategories: any[];
	likelihoods: string;
	impacts: string;
	type: string | null;
}
interface ProjectCloneModalProps {
	id?: string;
	name?: string;
	values?: ProjectCloneModalValues;
	onSave: (values: any) => void;
	onChange: (id: string, values: any) => void;
	onHide: () => void;
	authorizedTypes: {
		pro: boolean;
		stakeholders: boolean;
		risks: boolean;
	};
}

export const ProjectCloneModal = (props: ProjectCloneModalProps) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const [type, setType] = React.useState<any | null>(props.values?.type);

	console.log('ProjectCloneModal', props);

	const handleFormSubmit = () => {
		console.log('handleFormSubmit');
		form.validateFields()
			.then((values) => {
				console.log('validated', values);
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				style={{ marginRight: 'auto' }}
				onClick={() => {
					props.onHide();
				}}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	const content = (<Form
		{...formLayout}
		form={form}
		onFinish={(values) => {
			props.onChange(props.id, values);
			props.onHide();
		}}
		initialValues={{
			...props.values,
			type
		}}
	>
		<div className='d-flex w-100'>

			<Form.Item
				name="reference"
				label="Reference"
				className="mr-1"
				style={{ width: '20%' }}
				rules={[
					{
						max: 5,
						message:
							t("messages.form.max_length_exceeded_error", { length: 5 })
					}
				]}
			>
				<Input
					autoComplete='off'
					count={{
						show: true,
						max: 5,
					}}
				></Input>
			</Form.Item>


			<Form.Item
				name="name"
				required={true}
				label={t('models.project.fields.name')}
				style={{ width: '80%' }}
				rules={[
					{
						max: 50,
						message:
							t("messages.form.max_length_exceeded_error", { length: 50 })
					},
					{
						required: true,
						message: t("messages.form.required_error")
					}
				]}
			>
				<Input
					autoComplete='off'
					placeholder={t(
						'models.project.fields.name_explanation'
					)}
					count={{
						show: true,
						max: 50,
					}}
				></Input>
			</Form.Item>
		</div>
		<div className='d-flex w-100'>
			<Form.Item name="type" label="Clone as...">
				<Select
					placeholder="Select a type"
					allowClear
					style={{ width: '100%' }}
				>
					<Select.Option value="pro" disabled={!props.authorizedTypes.pro}>
						<FontAwesomeIcon icon={faChess} className="mr-1" />
						{t('models.project.fields.types.pro_long')}
					</Select.Option>
					<Select.Option value="stakeholders" disabled={!props.authorizedTypes.stakeholders}>
						<FontAwesomeIcon icon={faShieldExclamation} className="mr-1" />
						{t('models.project.fields.types.stakeholders_long')}
					</Select.Option>
					<Select.Option value="risks" disabled={!props.authorizedTypes.risks}>
						<FontAwesomeIcon icon={faChartNetwork} className="mr-1" />
						{t('models.project.fields.types.risks_long')}
					</Select.Option>
				</Select>
			</Form.Item>

		</div>
	</Form>)

	/* Organize with tabs */
	return (
		<Modal
			open={true}
			okButtonProps={{ style: { display: 'none' } }}
			title={t('models.project.actions.clone')}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			width={'60%'}
			maskClosable={false}
			className='ProjectCloneModal'
		>
			{content}
		</Modal>
	);
};

export default ProjectCloneModal;
