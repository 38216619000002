import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTrash } from '@fortawesome/pro-light-svg-icons';

import ActionButton from 'components/action/ActionButton';

import './ProjectItem.sass';
import { Tag } from 'antd';

export const ProjectItem = (props: any) => {
	const { t } = useTranslation();

	return (
		<div className="ProjectItem">
			<div onClick={(e: any) => props.onClick(props?.details)}>

				{props?.details?.reference && <>
					<span className="reference">{props?.details?.reference}</span>
					&nbsp;-&nbsp;
				</>}
				{props?.details?.name}

				<div>
					<small>
						Created on{' '}
						{dayjs(props?.details?.createdAt).format('DD/MM/YYYY HH:mm')}
					</small>
				</div>
			</div>

			{props?.details?.type && <div className="tagWrapper">
				<Tag className="type">
					{t("models.project.fields.types." + props?.details?.type)}
				</Tag>
			</div>
			}

			<ActionButton
				tooltip={t('models.project.actions.delete')}
				className="action xsmall delete"
				onClick={props.onDelete}
			>
				<FontAwesomeIcon icon={faTrash} />
			</ActionButton>

			{props.onClone && (
				<ActionButton
					tooltip={t('models.project.actions.clone')}
					className="action xsmall clone"
					onClick={props.onClone}
				>
					<FontAwesomeIcon icon={faClone} />
				</ActionButton>
			)}
		</div>
	);
};

export default ProjectItem;
