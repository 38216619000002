import React from 'react';
import { useTranslation } from 'react-i18next';


import {
	getFishboneScore,
	getIndicatorOrConstraintScore,
	getOpportunityScore,
	getRiskScore,
	vulnerability
} from 'utils/ranking';

import './SynthesisGraph.sass';
import { getItemAlteredByScenario } from 'utils/rules';
import { Tooltip } from 'antd';
import { RadarChart } from 'pages/Project/Scenarios/RadarChart';
import { compact, maxBy, values } from 'lodash';

type ISynthesisGraph = {
	scenario: any;
	scenarios: any[];
	indicators: any[];
	stakeholders: any[];
	constraints: any[];
	fishboneBranchs: any[];
	risks: any[];
	likelihoods: any[];
	impacts: any[];
	features: any;
	onClick: any;
	score: number | null;
	indexedScores: any;
};

export const SynthesisGraph = (props: ISynthesisGraph) => {
	const { t } = useTranslation();
	const [hoveredScenario, setHoveredScenario] = React.useState(null);
	const [xy, setXY] = React.useState([0, 0]);

	const {
		likelihoods = [],
		impacts = [],
		indexedScores = {},
		stakeholders = [],
		indicators = [],
		constraints = [],
		risks = [],
		scenarios = [],
		fishboneBranchs = []
	} = props;

	let allScenarios = scenarios ? [...scenarios] : [];
	allScenarios.push({ id: null, name: null });
	allScenarios.push({ id: "current", name: props.scenario?.name || "Baseline" });

	const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
	const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

	let scores = allScenarios.map((sc) => {
		return {
			id: sc.id,
			indicators: getIndicatorOrConstraintScore(
				indicators.map((i) => getItemAlteredByScenario(i, sc.id !== "current" ? sc : props.scenario))
			),
			stakeholders: stakeholders.length > 0 ? 100 - vulnerability(
				stakeholders.map((s) => getItemAlteredByScenario(s, sc.id !== "current" ? sc : props.scenario))
			) : null,
			constraints: constraints.length > 0 ? getIndicatorOrConstraintScore(
				constraints.map((s) => getItemAlteredByScenario(s, sc.id !== "current" ? sc : props.scenario))
			) : null,
			risks: getRiskScore(
				risks.filter(r => !r.isOpportunity).map((s) => getItemAlteredByScenario(s, sc.id !== "current" ? sc : props.scenario)),
				maxLikelihood, maxImpact),
			opportunities: getOpportunityScore(
				risks.filter(r => !!r.isOpportunity).map((s) => getItemAlteredByScenario(s, sc.id !== "current" ? sc : props.scenario)),
				maxLikelihood, maxImpact),
			fishbone: getFishboneScore(
				fishboneBranchs.map(b => {
					return {
						...b,
						items: b.items.map((i) => getItemAlteredByScenario(i, sc.id !== "current" ? sc : props.scenario))
					}
				})
			),

			width: sc.id === "current" ? 3 : sc.id === null ? 1 : 1,
			color: sc.id === "current" ? '#FCC356' : sc.id === null ? "white" : "#FCC35645",
			score: indexedScores[sc?.id]?.score,
			selected: (sc.id === props.scenario?.id) || (sc.id === null && !props.scenario?.id)
		};
	})

	return (
		<div className="SynthesisGraphWrapper">
			<Tooltip title="Scenario score">
				{props.score !== null && <div className="score">{props.score?.toFixed(0)} <small>max 100</small></div>}
			</Tooltip>

			<RadarChart
				data={scores}
				width={700}
				height={700}
				axisConfig={compact([
					props.features?.indicators && indicators.length > 0 && { name: 'indicators', label: t('models.indicator.name_plural'), max: 100 },
					props.features?.constraints && indicators.length > 0 && { name: "constraints", label: t('models.constraint.name_plural'), max: 100 },
					props.features?.risks && risks.length > 0 && { name: "opportunities", label: t('models.risk.name_plural_positive'), max: 100 },
					stakeholders.length > 0 && { name: "stakeholders", label: t('models.item.name_plural'), max: 100 },
					props.features?.risks && risks.length > 0 && { name: "risks", label: t('models.risk.name_plural'), max: 100 },
					props.features?.fishbone && fishboneBranchs.length > 0 && { name: "fishbone", label: t('pages.fishbone.title'), max: 100 },
				])}

				onMouseOver={(s, x, y) => {
					setHoveredScenario(s);

					setXY([
						x,
						y
					]);
				}}
				onMouseMove={(s, x, y) => {
					setXY([
						x,
						y
					]);
				}}
				onMouseOut={() => {
					setHoveredScenario(null);
				}}
				onClick={(scenarioId) => {
					const index = scenarioId ? allScenarios.findIndex((s) => s.id === scenarioId) : null;
					props.onClick(index !== -1 ? index : null);
				}}
			/>

			{false && <Tooltip className="tooltip" style={{ zIndex: 1000 }} title={hoveredScenario?.id && <div>
				<b>{hoveredScenario ? hoveredScenario.name : null}</b>

				<div className="mt-2">
					Impact: {impacts[parseInt(hoveredScenario.impact)] ? hoveredScenario.impact + " - " + impacts[parseInt(hoveredScenario.impact)]?.name : hoveredScenario.impact}
				</div>
				<div className="mb-2">
					Likelihood: {likelihoods[parseInt(hoveredScenario.likelihood)] ? hoveredScenario.likelihood + " - " + likelihoods[parseInt(hoveredScenario.likelihood)]?.name : hoveredScenario.likelihood}
				</div>

				<div className='text-center'>
					<small>
						Click to edit
					</small>
				</div>
			</div>} open={hoveredScenario}>
				<div id="Tooltip-root" key={hoveredScenario?.id || "empty"}
					style={{ zIndex: 1000, position: "absolute", top: xy ? xy[1] + 50 - 15 + "px" : 0, left: xy ? xy[0] + 0 + "px" : 0 }}>

				</div>
			</Tooltip>}


		</div>
	);
};
