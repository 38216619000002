import React from 'react';

import { faClone, faClose, faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton from "components/action/ActionButton";

import { useTranslation } from "react-i18next";

import "./IndicatorGeneralTab.sass";
import { Button, Tag, Tooltip } from 'antd';
import { find, keyBy, sortBy } from 'lodash';
import Weight from 'components/controls/Weight';

interface IndicatorGeneralTabProps {
    selection: any;
    onEdit: Function;
    onClone: Function;
    onClose: Function;
    onDelete: Function;
    scenario: any;

    risks: any[];

    indicators: any[];
    createIndicatorImpact: Function;
    deleteIndicatorImpact: Function;
    updateImpactStrength: Function;
}

const getImpactingIndicatorDetails = (impactedIndicators, indicatorId: string) => {
    return find(
        impactedIndicators || [],
        (ii: any) => ii.indicatorId == indicatorId
    );
};

const isImpactingIndicator = (indexedRisks, impactedIndicators, riskId: string) => {
    if (
        indexedRisks[riskId] &&
        impactedIndicators
            ?.map((ii: any) => ii.riskId)
            .includes(riskId)
    )
        return true;
    return false;
};

export const IndicatorGeneralTab = (props: IndicatorGeneralTabProps) => {

    const { risks = [] } = props;
    const { t } = useTranslation();

    const realRisks = risks.filter(r => !r.isOpportunity);
    const realOpportunities = risks.filter(r => r.isOpportunity);

    const indexedRisks = keyBy(risks, 'id');

    return (
        <div className="IndicatorGeneralTab">

            <div className="d-flex flex-column flex-grow">

                <div className="d-flex flex-row align-items-start mb-1">
                    <div className="w-100 text-left">
                        <div className="title">{props.selection?.name}</div>
                        <small>{t("models.indicator.name")}</small>
                    </div>
                    <Tooltip title={t('actions.close')} placement='left'>
                        <Button
                            shape="circle"
                            className="mini ml-auto"
                            tooltip={t('models.item.actions.edit')}
                            style={{ marginRight: '-0.9rem' }}
                            onClick={props.onClose}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </Button></Tooltip>
                </div>

                <ul>
                    {props.selection?.description && (
                        <li className="EditableListItem">
                            <div>{props.selection?.description}</div>
                        </li>
                    )}
                </ul>

                {false && !props.scenario && (
                    <div className="Actions mt-2 mb-4 pl-1 text-center ml-auto mr-auto">
                        <ActionButton
                            className="small ml-05"
                            tooltip={t('models.item.actions.edit')}
                            onClick={() => props.onEdit(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>

                        {false && <ActionButton
                            className="small"
                            tooltip={t('models.item.actions.clone')}
                            onClick={() => props.onClone(props.selection)}
                        >
                            <FontAwesomeIcon icon={faClone} />
                        </ActionButton>}

                        <ActionButton
                            className="small"
                            tooltip={t('models.item.actions.delete')}
                            onClick={() => props.onDelete(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </ActionButton>
                    </div>
                )}

                {(
                    <div className="d-flex flex-column flex-grow mt-3">
                        <div className="text-left mb-05">
                            {t('models.indicator.fields.risks.name')}
                        </div>
                        <div className="text-left">
                            {realRisks && realRisks.length ? (
                                sortBy(realRisks, 'name').map(
                                    (r: any, index) => {
                                        const impactedIndicator = r.impactedIndicators.find(ii => ii.indicatorId == props.selection?.id);
                                        return (
                                            <Tag
                                                key={index}
                                                onClick={async (e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    if (
                                                        !impactedIndicator ||
                                                        !impactedIndicator.id
                                                    ) {
                                                        props.createIndicatorImpact(
                                                            r.id,
                                                            props.selection?.id,

                                                        );
                                                    } else if (
                                                        impactedIndicator.strength <
                                                        3
                                                    ) {
                                                        props.updateImpactStrength(
                                                            impactedIndicator,
                                                            impactedIndicator.strength +
                                                            1
                                                        );
                                                    } else {
                                                        props.deleteIndicatorImpact(
                                                            impactedIndicator.id,
                                                            r.id
                                                        );
                                                    }
                                                }}
                                                className={[
                                                    'ImpactedIndicator tag',
                                                    !!impactedIndicator
                                                        ? 'selected'
                                                        : 'unselected'
                                                ].join(' ')}
                                            >

                                                {r.name}

                                                {impactedIndicator ? (
                                                    <Weight onClick={() => { }}>
                                                        {
                                                            impactedIndicator.strength
                                                        }
                                                    </Weight>
                                                ) : null}
                                            </Tag>
                                        );
                                    }
                                )
                            ) : (
                                <div
                                    className="text-center"
                                    style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                                >
                                    {t('models.indicator.no_x')}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {(
                    <div className="d-flex flex-column flex-grow mt-4 mb-4">
                        <div className="text-left mb-05">
                            {t('models.indicator.fields.risks.name_positive')}
                        </div>
                        <div className="text-left">
                            {realOpportunities && realOpportunities.length ? (
                                sortBy(realOpportunities, 'name').map(
                                    (r: any, index) => {
                                        const impactedIndicator = r.impactedIndicators.find(ii => ii.indicatorId == props.selection?.id);

                                        return (
                                            <Tag
                                                key={index}
                                                onClick={async (e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    if (
                                                        !impactedIndicator ||
                                                        !impactedIndicator.id
                                                    ) {
                                                        props.createIndicatorImpact(
                                                            r.id,
                                                            props.selection?.id,

                                                        );
                                                    } else if (
                                                        impactedIndicator.strength <
                                                        3
                                                    ) {
                                                        props.updateImpactStrength(
                                                            impactedIndicator,
                                                            impactedIndicator.strength +
                                                            1
                                                        );
                                                    } else {
                                                        props.deleteIndicatorImpact(
                                                            impactedIndicator.id,
                                                            r.id
                                                        );
                                                    }
                                                }}
                                                className={[
                                                    'ImpactedIndicator tag',
                                                    !!impactedIndicator
                                                        ? 'selected'
                                                        : 'unselected'
                                                ].join(' ')}
                                            >

                                                {r.name}

                                                {impactedIndicator ? (
                                                    <Weight onClick={() => { }}>
                                                        {
                                                            impactedIndicator.strength
                                                        }
                                                    </Weight>
                                                ) : null}
                                            </Tag>
                                        );
                                    }
                                )
                            ) : (
                                <div
                                    className="text-center"
                                    style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                                >
                                    {t('models.indicator.no_x')}
                                </div>
                            )}
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
}