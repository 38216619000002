// @ts-nocheck
import { Tag } from 'antd';
import { max } from 'd3';
import { keyBy, maxBy, range, rangeRight } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import { RiskModel } from 'model/RiskModel';
import { getItemAlteredByScenario } from 'utils/rules';
import { useTranslation } from 'react-i18next';

type RiskTooltipContentProps = {
    attribute: string;
    scenario: object;
    scenarios: object[];
    risk: RiskModel;
    likelihoods: object[];
    impacts: object[];
}

const exposureValue = (impact, likelihood) => {
    return impact * likelihood;
}

const riskValue = (risk, scenario, attribute) => {

    let item = getItemAlteredByScenario(risk, scenario)
    if (attribute == "exposure") {
        return exposureValue(item.impact, item.likelihood);
    } else {
        return item[attribute];
    }
}

export function RiskTooltipContent(props: RiskTooltipContentProps) {

    const indexedValues = keyBy(props.values, "id");
    const indexedNames = keyBy(props[props.attribute + "s"], "id");
    const { t } = useTranslation();

    let baselineExposure = props.risk?.o?.likelihood * props.risk?.o?.impact;
    let scenarioExposure = props.risk.likelihood * props.risk.impact;

    let item = getItemAlteredByScenario(props.risk, props.scenario)

    const values = [
        {
            label: t("models.scenario.baseline"),
            value: riskValue(props.risk, null, props.attribute),
            scenarioId: null
        },

        ...props.scenarios.map((s: any) => ({
            label: s.name,
            value: riskValue(props.risk, s, props.attribute),
            scenarioId: s.id
        }))
    ]

    return (
        <div className={"RiskTooltipContent"}>
            {(values).map((v: any, index) => {

                const isCurrentScenario = (v.scenarioId == props.scenario?.id) || (!props.scenario && v.scenarioId === null)
                return <div
                    key={index}
                    style={{
                        color: isCurrentScenario ? "#FFFFFF" : "#888888",

                    }}
                    className={`d-flex ${v.scenarioId == props.scenario?.id}`}
                >
                    <div style={{ width: "60%" }}>
                        {v.label}
                    </div>
                    <div>
                        {indexedNames[parseInt(v.value)] ? v.value + " - " + indexedNames[parseInt(v.value)].name : v.value}
                    </div>
                </div>
            }

            )}

            {props.attribute != "exposure" && <div className="text-center mt-2">
                <small>Click to change</small>
            </div>}

        </div>
    );
}

export default RiskTooltipContent;
