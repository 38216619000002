import React, { Component } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Input, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

export const TagsTab = (props: any) => {
	const { t } = useTranslation();

	const [enteredTags, setEnteredTags] = React.useState<any | null>('');

	let indexedAssigned = {}
	if (props.selection) {
		props.selection.taggings.forEach(t => {
			indexedAssigned[t.tagId] = true
		})
	}

	return <>
		<div className="d-flex flex-column flex-grow mt-4">
			<div className="text-left mb-05 d-flex align-items-center">
				{t('pages.navigator.tabs.tags.title')}

				<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}} onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					props.setPreferences(
						'pages',
						'navigator.tags',
						!props.areTagsVisible
					);
				}}>
					<FontAwesomeIcon
						icon={!props.areTagsVisible ? faChevronDown : faChevronUp}
					></FontAwesomeIcon>
				</Button>
			</div>
			{props.areTagsVisible && <div className="text-left">
				{(props.allTags || []).length > 0 ? (
					(props.allTags || []).map((currentTag, index) => {
						return (<Tag
							key={index}
							onClick={() => {

								const oldTaggings = props.selection && props.selection.taggings || []

								const newTags = indexedAssigned[currentTag.id] ? oldTaggings.filter(
									(st) =>
										st.tagId !=
										currentTag.id
								) : [
									...oldTaggings,
									{
										tagId: currentTag.id,
										tag: currentTag,
									},
								];

								props.onChangeItemTags(
									props.selection,
									newTags
								);
							}}
							className={[
								'tag',
								indexedAssigned[currentTag.id] ? "" : "unselected"

							].join(' ')}
						>
							{currentTag.name}
						</Tag>
						)
					})
				) : (

					<div style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
						{t('pages.navigator.no_tag')}
					</div>
				)
				}

			</div>}

			{!props.scenario && props.areTagsVisible && (
				<div className="mt-1">
					<Input
						key={props.selection ? props.selection.id : 'empty'}
						onChange={(event) => {
							setEnteredTags(
								event.target.value
									? event.target.value.slice(0, 30)
									: ''
							);
						}}
						onKeyPress={async (event) => {
							if (event.key === 'Enter') {
								let newTags = enteredTags.trim().split(',');
								// Create new tags
								await Promise.all(
									newTags.map((t: string) =>
										props.onCreateTag(t)
									)
								);
								setEnteredTags('');
							}
						}}
						value={enteredTags}
						className='TagInput'
						placeholder="Create new tags"
					></Input>
				</div>
			)}
		</div>
	</>

};

export default TagsTab;
