// @ts-nocheck
import React from 'react';

import { Switch } from 'antd';
import './OpportunitySwitch.sass';

const width = document.body.offsetWidth;

export const OpportunitySwitch = (props: any) => {
	const [score, setScore] = React.useState<any | null>(0);

	return (
		<div>
            Risk
			<Switch
					{...props}
                    className={"OpportunitySwitch " + (props.className || "")}
				/>
            Opportunity
		</div>
	);
};
