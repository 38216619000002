import React from 'react';
import { useTranslation } from 'react-i18next';

import { findIndex, maxBy, sortBy, values } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRight,
	faEquals
} from '@fortawesome/pro-light-svg-icons';

import './ScenarioResult.sass';

import {
	getScoreColor,
	scenarioScore,
	getStakeholderScoreForScenarioDetailsColor,
	vulnerability,
	getRiskScore,
	getIndividualRiskScore,
	getOpportunityScore,
	singleHue,
} from 'utils/ranking';
import HorizontalVulnerabilityScale from 'components/Radar/HorizontalVulnerabilityScale';
import {
	getItemAlteredByScenario,
	vulnerabilityConstraints,
	vulnerabilityIndicators
} from 'utils/rules';
import { Tooltip } from 'antd';
import Nav from './Nav';

export const ScenarioResult = (props) => {

	const { t } = useTranslation();
	const { stakeholders = [], constraints = [], indicators = [], risks = [], likelihoods = [], impacts = [] } = props;

	const scenarioIndex = findIndex(props.scenarios, { id: props.scenario?.id }) + 1;

	if (!props.scenarios) {
		return (
			<div className="ScenarioResult">
				<h2>No scenario</h2>
			</div>
		);
	}

	let stakeholderScore = stakeholders && stakeholders.length > 0 ?
		100 -
		vulnerability(
			stakeholders.map((s: any) =>
				getItemAlteredByScenario(s, props.scenario)
			)
		) : null;
	let constraintsScore = vulnerabilityConstraints(
		constraints.map((c: any) =>
			getItemAlteredByScenario(c, props.scenario)
		)
	);
	let indicatorsScore = vulnerabilityIndicators(
		indicators.map((i: any) =>
			getItemAlteredByScenario(i, props.scenario)
		)
	);
	const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
	const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

	const allRisks = risks.filter((r: any) => !r.isOpportunity);
	const allOpportunities = risks.filter((r: any) => r.isOpportunity);

	//console.log('allRisks', allRisks);
	//console.log('allOpportunities', allOpportunities);

	let risksScore = getRiskScore(
		allRisks.map((i: any) =>
			getItemAlteredByScenario(i, props.scenario)
		),
		maxLikelihood,
		maxImpact
	);

	let opportunitiesScore = getOpportunityScore(
		allOpportunities.map((i: any) =>
			getItemAlteredByScenario(i, props.scenario)
		),
		maxLikelihood,
		maxImpact
	);

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: scenarioScore(props, sc),
		};
	});

	vulnerabilities.push({
		id: null,
		vulnerability: scenarioScore(props, null)
	});

	//console.log('vulnerabilities', vulnerabilities);

	return (
		<div className="ScenarioResult">
			<Nav
				fullWidth
				count={props.scenarios.length}
				options={props.scenarios}
				onGoToPreviousScenario={props.onGoToPreviousScenario}
				onGoToNextScenario={props.onGoToNextScenario}

			>
				<div className="d-flex justify-content-center align-self-center">
					<h2 title={props.scenarios ? props.scenario?.id : ''}>
						{props.scenario?.name || t("models.scenario.baseline")}{' '}
					</h2>

					{scenarioScore(props, props.scenario) !== null ? `
						(${Math.round(scenarioScore(props, props.scenario))} max 100)
					` : null}
				</div>

			</Nav>

			<HorizontalVulnerabilityScale
				t={t}
				scenarios={props.scenarios}
				scenario={props.scenario}
				vulnerabilities={vulnerabilities}
			></HorizontalVulnerabilityScale>

			<div className="ElementsRank d-flex mt-4 justify-content-center">
				{props.features?.constraints &&
					<div data-type="constraints" className="RankCriteriaColumn">
						<div className="title">
							{t('models.constraint.name_plural')}{' '}
							{constraintsScore !== undefined &&
								constraintsScore !== null && (
									<Tooltip title="Score">
										<div className="ml-auto">
											<small>
												({constraintsScore.toFixed(0)})
											</small>
										</div>
									</Tooltip>
								)}
						</div>
						{sortBy(
							constraints,
							(s: any) =>
								-getItemAlteredByScenario(s, props.scenario).trend
						).map((s: any) => {
							let sAltered = getItemAlteredByScenario(
								s,
								props.scenario
							);
							let baselineScore = parseFloat(s.trend);
							let sScore = parseFloat(sAltered.trend);

							return (
								<div className="RankCriteriaWrapper">
									<div
										className="RankCriteria"
										style={{
											borderLeftWidth: '8px',
											borderLeftStyle: 'solid',
											borderLeftColor: getScoreColor(
												getItemAlteredByScenario(
													s,
													props.scenario
												).trend
											)
										}}
									>
										{props.scenario &&
											sScore < baselineScore && (
												<div className={'rp45'}>
													<Tooltip title="Worst than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore > baselineScore && (
												<div className={'rm45'}>
													<Tooltip title="Better than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore == baselineScore && (
												<div className={'r0'}>
													<Tooltip title="Same as baseline">
														<FontAwesomeIcon
															icon={faEquals}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{s.name}
									</div>
								</div>
							);
						})}
					</div>
				}
				{props.features?.indicators &&
					<div data-type="indicators" className="RankCriteriaColumn">
						<div className="title">
							{t('models.indicator.name_plural')}{' '}
							{indicatorsScore !== undefined &&
								indicatorsScore !== null && (
									<Tooltip title="Score">
										<div className="ml-auto">
											<small>
												({indicatorsScore.toFixed(0)})
											</small>
										</div>
									</Tooltip>
								)}
						</div>

						{sortBy(
							indicators,
							(s: any) =>
								-getItemAlteredByScenario(s, props.scenario).trend
						).map((s: any) => {
							let sAltered = getItemAlteredByScenario(
								s,
								props.scenario
							);
							let baselineScore = parseFloat(s.trend);
							let sScore = parseFloat(sAltered.trend);
							return (
								<div className="RankCriteriaWrapper">
									<div
										className="RankCriteria"
										style={{
											borderLeftWidth: '8px',
											borderLeftStyle: 'solid',
											borderLeftColor: getScoreColor(
												getItemAlteredByScenario(
													s,
													props.scenario
												).trend
											)
										}}
									>
										{props.scenario &&
											sScore < baselineScore && (
												<div className={'rp45'}>
													<Tooltip title="Worst than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore > baselineScore && (
												<div className={'rm45'}>
													<Tooltip title="Better than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore == baselineScore && (
												<div className={'r0'}>
													<Tooltip title="Same as baseline">
														<FontAwesomeIcon
															icon={faEquals}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{s.name}
									</div>
								</div>
							);
						})}
					</div>
				}

				<div data-type="stakeholders" className="RankCriteriaColumn">
					<div className="title">
						{t('models.item.name_plural')}{' '}
						{stakeholderScore !== undefined &&
							stakeholderScore !== null && (
								<Tooltip title="Score">
									<div className="ml-auto">
										<small>
											({stakeholderScore.toFixed(0)})
										</small>
									</div>
								</Tooltip>
							)}
					</div>
					{sortBy(
						stakeholders,
						(s: any) =>
							-getStakeholderScoreForScenarioDetailsColor(getItemAlteredByScenario(s, props.scenario))
					).map((s: any) => {
						let sAltered = getItemAlteredByScenario(s, props.scenario);
						let baselineScore = getStakeholderScoreForScenarioDetailsColor(s);
						let sScore = getStakeholderScoreForScenarioDetailsColor(sAltered);

						return (
							<div className="RankCriteriaWrapper">
								<div
									className="RankCriteria"
									style={{
										borderLeftWidth: '8px',
										borderLeftStyle: 'solid',
										borderLeftColor: getScoreColor(
											getStakeholderScoreForScenarioDetailsColor(sAltered)
										)
									}}
								>
									{props.scenario &&
										sScore < baselineScore && (
											<div className={'rp45'}>
												<Tooltip title="Worst than baseline">
													<FontAwesomeIcon
														icon={faArrowRight}
														size="lg"
													/>
												</Tooltip>
											</div>
										)}
									{props.scenario &&
										sScore > baselineScore && (
											<div className={'rm45'}>
												<Tooltip title="Better than baseline">
													<FontAwesomeIcon
														icon={faArrowRight}
														size="lg"
													/>
												</Tooltip>
											</div>
										)}
									{props.scenario &&
										sScore == baselineScore && (
											<div className={'r0'}>
												<Tooltip title="Same as baseline">
													<FontAwesomeIcon
														icon={faEquals}
														size="lg"
													/>
												</Tooltip>
											</div>
										)}
									{s.name}{' '}
								</div>
							</div>
						);
					})}
				</div>
				{
					props.features?.risks && <div data-type="risks" className="RankCriteriaColumn">
						<div className="title">
							{t('models.risk.name_plural')}{' '}
							{risksScore !== undefined &&
								risksScore !== null && (
									<Tooltip title="Score">
										<div className="ml-auto">
											<small>
												({risksScore.toFixed(0)})
											</small>
										</div>
									</Tooltip>
								)}
						</div>
						{sortBy(
							allRisks,
							(s: any) =>
								-getIndividualRiskScore(
									getItemAlteredByScenario(s, props.scenario)
								)
						).map((s: any) => {
							let sAltered = getItemAlteredByScenario(s, props.scenario);
							let baselineScore = getIndividualRiskScore(s);
							let sScore = getIndividualRiskScore(sAltered);
							let colorScore = (getRiskScore([getItemAlteredByScenario(s, props.scenario)], maxLikelihood, maxImpact))

							//console.log('sScore', sScore);
							//console.log('baselineScore', baselineScore);

							return (
								<div className="RankCriteriaWrapper">
									<div
										className="RankCriteria"
										style={{
											borderLeftWidth: '8px',
											borderLeftStyle: 'solid',
											borderLeftColor: singleHue(colorScore, false)
										}}
									>

										{props.scenario &&
											sScore > baselineScore && (
												<div className={'rp45'}>
													<Tooltip title="Worst than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore < baselineScore && (
												<div className={'rm45'}>
													<Tooltip title="Better than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore == baselineScore && (
												<div className={'r0'}>
													<Tooltip title="Same as baseline">
														<FontAwesomeIcon
															icon={faEquals}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{s.name}{' '}
									</div>
								</div>
							);
						})}
					</div>
				}
				{
					props.features?.risks && <div data-type="opportunities" className="RankCriteriaColumn">
						<div className="title">
							{t('models.risk.name_plural_positive')}{' '}
							{opportunitiesScore !== undefined &&
								opportunitiesScore !== null && (
									<Tooltip title="Score">
										<div className="ml-auto">
											<small>
												({opportunitiesScore.toFixed(0)})
											</small>
										</div>
									</Tooltip>
								)}
						</div>
						{sortBy(
							allOpportunities,
							(s: any) =>
								-getIndividualRiskScore(
									getItemAlteredByScenario(s, props.scenario)
								)
						).map((s: any) => {
							let sAltered = getItemAlteredByScenario(
								s,
								props.scenario
							);
							let baselineScore = getIndividualRiskScore(s);
							let sScore = getIndividualRiskScore(sAltered);
							let colorScore = (getOpportunityScore([getItemAlteredByScenario(s, props.scenario)], maxLikelihood, maxImpact))

							return (
								<div className="RankCriteriaWrapper">
									<div
										className="RankCriteria"
										style={{
											borderLeftWidth: '8px',
											borderLeftStyle: 'solid',
											borderLeftColor: singleHue(colorScore, true)
										}}
									>
										{props.scenario &&
											sScore < baselineScore && (
												<div className={'rp45'}>
													<Tooltip title="Worst than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore > baselineScore && (
												<div className={'rm45'}>
													<Tooltip title="Better than baseline">
														<FontAwesomeIcon
															icon={faArrowRight}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{props.scenario &&
											sScore == baselineScore && (
												<div className={'r0'}>
													<Tooltip title="Same as baseline">
														<FontAwesomeIcon
															icon={faEquals}
															size="lg"
														/>
													</Tooltip>
												</div>
											)}
										{s.name}{' '}
									</div>
								</div>
							);
						})}
					</div>
				}
			</div>

			<Nav
				fullWidth
				count={props.scenarios.length}
				options={props.scenarios}
				onGoToPreviousScenario={props.onGoToPreviousScenario}
				onGoToNextScenario={props.onGoToNextScenario}
			>
				<div className="d-flex h-100 justify-content-center align-items-center">
					{scenarioIndex + 1} / {props.scenarios.length + 1}
				</div>
			</Nav>
		</div >
	);
};
