import React from 'react';

import _, {
	findIndex,
	keyBy,
	pick,
	uniq,
	sortBy,
	omit,
	compact,
	values
} from 'lodash';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Tag } from 'antd';

import { faInfoCircle, faMinus, faPlus, fal } from '@fortawesome/pro-light-svg-icons';

import { IconPicker } from 'components/IconPicker';
import MainRadar from 'components/Radar/MainRadar';
import AddButton from 'components/action/AddButton';
import StakeholderModal from 'components/Modal/StakeholderModal';
import { SummaryColumn } from './SummaryColumn/SummaryColumn';

import Weight from 'components/controls/Weight';
import LinksTab from './Tabs/LinksTab';
import CommentsTab from './Tabs/CommentsTab';
import GeneralTab from './Tabs/GeneralTab';

import { ConfirmModal } from 'components/Modal/ConfirmModal';

// Help
import TutorialPopover from 'components/Help/TutorialPopover';
import { getNextStep, getInitialStep } from 'help/navigator';

import {
	useCreateStakeholderMutation,
	useUpdateStakeholderPositionMutation,
	useUpdateStakeholderIconMutation,
	useUpdateStakeholderImpactMutation,
	useUpdateStakeholderTrendMutation,
	useDeleteStakeholderMutation,
	useUpdateStakeholderMutation,
	useUpdateStakeholderScenarioMutation,
	useCreateStakeholderLinkMutation,
	useDeleteStakeholderLinkMutation,
	useUpdateStakeholderLinkStrengthMutation,
	useCreateTaggingMutation,
	useDeleteTaggingMutation
} from 'graph/dist/generated';

import './Navigator.sass';
import HeaderPortal from 'layout/header/HeaderPortal';
import { getItemAlteredByScenario } from 'utils/rules';
import useScreenSize from 'hooks/useScreenSize';
import { remainingSpace } from 'utils/layoutUtils';
import { optimisticlyUpdateScenario } from 'utils/graphql';
import { StakeholderModel } from 'model/StakeholderModel';
import BotTab from 'components/tabs/BotTab';
import { useAuth0 } from '../../../react-auth0-spa';
import { filterStakeholders } from './Navigator.utils';

// Props
interface NavigatorProps {
	stakeholders: Array<any>;
	scenario: any;
	scenarios: Array<any>;
	selection: any;
	searchTerm: String;
	setSearchTerm: Function;
	select: Function;
	deselect: Function;
	refetch: Function;
	getProject: any;
	portalContent: any;
	expanded: any;
	features: any;
	tags: any;
	aiConsent: string | null;

	indicators: any;
	constraints: any;
	risks: any;

	displayConstraints: boolean;
	displayIndicators: boolean;
	displayRisks: boolean;
	onCreateTag: (name: string, callback: (tagId: string) => void) => void;

	pushModal: Function;
	popModal: Function;

	preferences: any;
	setPreferences: any;

	botThread: any;
}

export const Navigator = (props: NavigatorProps) => {
	const params: any = useParams();
	const location = useLocation();
	const { t } = useTranslation();

	const { stakeholders = [], indicators = [], constraints = [], risks = [], tags = [] } = props;

	const { user } = useAuth0();

	// Stakeholder
	const [createStakeholder] = useCreateStakeholderMutation();
	const [updateStakeholder] = useUpdateStakeholderMutation();
	const [updateStakeholderScenario] =
		useUpdateStakeholderScenarioMutation();
	const [updateStakeholderPosition] = useUpdateStakeholderPositionMutation();
	const [updateStakeholderIcon] = useUpdateStakeholderIconMutation();
	const [updateStakeholderImpact] = useUpdateStakeholderImpactMutation();
	const [updateStakeholderTrend] = useUpdateStakeholderTrendMutation();
	const [deleteStakeholder] = useDeleteStakeholderMutation();

	const [createTagging] = useCreateTaggingMutation();
	const [deleteTagging] = useDeleteTaggingMutation();

	// Stakeholder links
	const [createStakeholderLink] = useCreateStakeholderLinkMutation();
	const [deleteStakeholderLink] = useDeleteStakeholderLinkMutation();
	const [updateStakeholderLinkStrength] =
		useUpdateStakeholderLinkStrengthMutation();

	const [editedStakeholder, setEditedStakeholder] = React.useState<
		any | null
	>(null);
	const [mode, setMode] = React.useState<any | null>({ name: 'initial' });
	const [isTransitioning, setIsTransitioning] = React.useState<any | null>(
		false
	);

	const areAllLinksVisible = props.preferences?.pages?.navigator?.links || false
	const areTagsVisible = props.preferences?.pages?.navigator?.tags || false
	const areConstraintsVisible = props.preferences?.pages?.navigator?.constraints || false
	const areIndicatorsVisible = props.preferences?.pages?.navigator?.indicators || false
	const areRisksVisible = props.preferences?.pages?.navigator?.risks || false
	const areOpportunitiesVisible = props.preferences?.pages?.navigator?.opportunities || false

	const [tagFilter, setTagFilter] = React.useState<any | null>([]);
	const [iconFilter, setIconFilter] = React.useState<any | null>(null);
	const [indicatorFilter, setIndicatorFilter] = React.useState<any | null>(
		null
	);
	const [constraintFilter, setConstraintFilter] = React.useState<any | null>(
		null
	);

	const [riskFilter, setRiskFilter] = React.useState<any | null>(location?.state?.riskFilter || null);

	let indexedRisks = keyBy(risks, 'id');

	// Usage
	let usedRisks = {};
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedRisks) {
			s.impactedRisks.forEach((r) => {
				if (indexedRisks[r.riskId] && !indexedRisks[r.riskId].isOpportunity)
					usedRisks[r.riskId] = true;
			})
		}
	})

	let usedOpportunities = {};
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedRisks) {
			s.impactedRisks.forEach((r) => {
				if (indexedRisks[r.riskId] && indexedRisks[r.riskId].isOpportunity)
					usedOpportunities[r.riskId] = true;
			})
		}
	})


	let usedTags = {};
	const indexedTags = keyBy(tags, 'id');
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.taggings) {
			s.taggings.forEach((t) => {
				if (indexedTags[t.tagId]) {
					usedTags[t.tagId] = true;
				}
			})
		}
	})

	let usedIndicators = {};
	const indexedIndicators = keyBy(indicators, 'id');
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedIndicators) {
			s.impactedIndicators.forEach((i) => {
				if (indexedIndicators[i.indicatorId]) {
					usedIndicators[i.indicatorId] = true;
				}

			})
		}
	})

	let usedConstraints = {};
	const indexedConstraints = keyBy(constraints, 'id');
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedConstraints) {
			s.impactedConstraints.forEach((c) => {
				if (indexedConstraints[c.constraintId]) {
					usedConstraints[c.constraintId] = true;
				}
			})
		}
	})

	const [showAllConstraints, setShowAllConstraints] = React.useState<any | null>(false);
	const [showAllIndicators, setShowAllIndicators] = React.useState<any | null>(false);
	const [showAllRisks, setShowAllRisks] = React.useState<any | null>(false);
	const [showAllOpportunities, setShowAllOpportunities] = React.useState<any | null>(false);
	const [showAllTags, setShowAllTags] = React.useState<any | null>(false);

	const [opened, setOpened] = React.useState<true | false>(true);
	const [selectedTab, setSelectedTab] = React.useState<string>('general');
	const [selectedGlobalTab, setSelectedGlobalTab] =
		React.useState<string>('filters');

	const [hasRadarLegend, setHasRadarLegend] = React.useState<true | false>(
		true
	);
	const isFullMode = props.features?.fishbone;

	const allRisks = sortBy(risks, 'name').filter(r => !r.isOpportunity);
	const allOpportunities = sortBy(risks, 'name').filter(r => r.isOpportunity);

	const filteredStakeholders = filterStakeholders(
		stakeholders,
		{
			name: props.searchTerm,
			tags: tagFilter,
			icon: iconFilter,
			indicator: indicatorFilter,
			constraint: constraintFilter,
			risk: riskFilter
		},
		props.scenario
	);

	const hasFilters =
		props.searchTerm !== '' ||
		tagFilter.length > 0 ||
		!!iconFilter ||
		!!indicatorFilter ||
		!!constraintFilter;

	// Timer for animation
	const updateTimer = React.useRef(null);
	React.useEffect(() => {
		if (!updateTimer.current) {
			setIsTransitioning(true);
		}
	}, [props.scenario]);

	const screenSize = useScreenSize();
	let blocks = compact([
		{ id: 'header', verticalAlign: 'top', height: 70 },
		{ id: 'summary', horizontalAlign: 'left', width: 320 },
		{ id: 'right', horizontalAlign: 'right', width: 310 },
		{ id: 'bottom', verticalAlign: 'bottom', height: 10 }
	]);

	//console.log('blocks', blocks)
	let remainingSpaceForCenter = remainingSpace({
		...screenSize,
		blocks
	});
	//console.log('remainingSpaceForCenter', remainingSpaceForCenter)

	// Enable / Disable left text close to radar
	let maxWidth = Math.min(
		remainingSpaceForCenter.width,
		remainingSpaceForCenter.height
	);
	let offsetX =
		(remainingSpaceForCenter.width - maxWidth) / 2 +
		remainingSpaceForCenter.x1;
	let offsetY = remainingSpaceForCenter.y1;

	//console.log('offsetX', offsetX)
	//console.log('offsetY', offsetY)

	if (
		remainingSpaceForCenter.width < remainingSpaceForCenter.height ||
		offsetX < 350
	) {
		if (hasRadarLegend) {
			setHasRadarLegend(false);
		}
	} else {
		if (!hasRadarLegend) {
			setHasRadarLegend(true);
		}
	}

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(
		null
	);
	const closeHelp = () => setVisibleHelpStep(null);
	const initialStep = getInitialStep();
	const nextStep = getNextStep(visibleHelpStep);
	const onNext = () => setVisibleHelpStep(nextStep);
	const hasNext = !!nextStep;

	const allTags: any = sortBy(tags, 'name');

	const allIcons: any = uniq(
		stakeholders.map((i: any) => (i.icon ? i.icon : 'faUser'))
	).sort();

	const indexedStakeholders = keyBy(stakeholders, 'id');

	//console.log('stakeholders', stakeholders)

	// Create
	const handleCreateStakeholder = async (values, defaults) => {
		let variables = {
			projectId: params.id,
			...(defaults ? omit(defaults, ['id']) : {}),
			name: (defaults && defaults?.name) || values?.name,
			description:
				(defaults && defaults?.description) ||
				values?.description ||
				'',
			tags: (defaults && defaults?.tags) || values?.tags || [],
			trend: (defaults && defaults?.trend) || values?.trend || null,
			impact: (defaults && defaults?.impact) || values?.impact || null,
			icon: (defaults && defaults?.icon) || values?.icon || null,
			x: (defaults && defaults?.x) + 5 || values?.x || 0,
			y: (defaults && defaults?.y) + 5 || values?.y || 0
		};

		await createStakeholder({
			variables,
			update(cache, { data: { createStakeholder } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						stakeholders(stakeholders = []) {
							const newStakeholder = cache.writeFragment({
								id: 'Stakeholder:' + createStakeholder.id,
								data: createStakeholder,
								fragment: gql`
									fragment StakeholderFragment on Stakeholder {
										name
										description
										impact
										trend
										links
										icon
										x
										y
										impactedIndicators
										impactedConstraints
										impactedRisks
										links
										taggings
										comments
										createdAt
										scenarios
									}
								`
							});

							// Reselect after clone
							if (defaults?.id) {
								if (props.selection)
									props.select(createStakeholder);
							}

							return [...stakeholders, newStakeholder];
						}
					}
				});
			},
			optimisticResponse: {
				createStakeholder: {
					id: 'temp-id',
					...variables,
					__typename: 'Stakeholder',
					links: [],
					taggings: [],
					impactedIndicators: [],
					impactedConstraints: [],
					impactedRisks: [],
					comments: [],
					scenarios: [],
					createdAt: new Date().toISOString()
				}
			}
		});
	};

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.item.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDeleteStakeholder(id)}
				message={t(
					'models.item.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	// Delete
	const handleDeleteStakeholder = async (id) => {
		await deleteStakeholder({
			variables: {
				id
			},

			update(cache, { data: { deleteStakeholder } }) {
				cache.modify({
					id: `Project:${deleteStakeholder.id}`,
					fields: {
						stakeholders(existingRefs, { readField }) {
							return existingRefs.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteStakeholder: true
			}
		});

		props.deselect();
		props.refetch();
	};

	// Create
	const handleCreateStakeholderLink = async (origin, target) => {
		let result = await createStakeholderLink({
			variables: {
				projectId: params.id,
				originId: origin.id,
				targetId: target.id
			},
			update(cache, { data: { createStakeholderLink } }) {
				cache.modify({
					id: `Stakeholder:${origin.id}`,
					fields: {
						links(existingStakeholdersLinksRef, { readField }) {
							const newStakeholderLink = cache.writeFragment({
								id:
									'StakeholderLink:' +
									createStakeholderLink.id,
								data: createStakeholderLink,
								fragment: gql`
									fragment StakeholderFragment on Stakeholder {
										originId
										targetId
									}
								`
							});
							return [
								...existingStakeholdersLinksRef,
								newStakeholderLink
							];
						}
					}
				});
			},

			optimisticResponse: {
				createStakeholderLink: {
					__typename: 'StakeholderLink',
					id: origin.id + '-' + target.id,
					originId: origin.id,
					targetId: target.id
				}
			}
		});
	};

	// Delete
	const handleDeleteStakeholderLink = async (
		stakeholderId: string,
		id: string
	) => {
		console.log('handleDeleteStakeholderLink', id);

		await deleteStakeholderLink({
			variables: {
				projectId: params.id,
				id
			},

			update(cache) {
				cache.modify({
					id: `Stakeholder:${stakeholderId}`,
					fields: {
						links(existingLinksRef, { readField }) {
							return existingLinksRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteStakeholderLink: true
			}
		});
	};

	const handleChangeStakeholderLinkStrength = (
		link: any,
		strength: number
	) => {
		console.log('handleChangeStakeholderLinkStrength', strength);

		updateStakeholderLinkStrength({
			variables: { id: link.id, strength },
			update(cache, { data: { updateStakeholderLinkStrength } }) {
				cache.modify({
					id: `StakeholderLink:${updateStakeholderLinkStrength.id}`,
					fields: {
						strength() {
							return strength;
						}
					}
				});
			},

			optimisticResponse: {
				updateStakeholderLinkStrength: {
					__typename: 'StakeholderLink',
					id: link.id,
					strength: strength,
					originId: link.originId,
					targetId: link.targetId
				}
			}
		});
	};

	// Edit
	const handleEditStakeholder = async (id) => {
		let index = findIndex(stakeholders, (s: any) => s.id === id);

		setEditedStakeholder(stakeholders[index]);
	};

	const handleChangeItemPosition = async (index: number, x: any, y: any) => {
		//console.log('handleChangeItemPosition', x, y);

		if (props.scenario) {
			let scenarioId = props.scenario.id;
			let stakeholder = stakeholders[index]
			let scenarioValues = stakeholder.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				x,
				y
			};

			await optimisticlyUpdateScenario(
				stakeholders[index],
				'Stakeholder',
				scenarioId,
				scenarioValues,
				updateStakeholderScenario
			);

			if (props.selection) props.select({ ...props.selection, x, y });
		} else {
			updateStakeholderPosition({
				variables: { id: stakeholders[index].id, x, y },
				update(cache) {
					cache.modify({
						id: `Stakeholder:${stakeholders[index].id}`,
						fields: {
							x() {
								return x;
							},
							y() {
								return y;
							}
						}
					});
				},

				optimisticResponse: {
					updateStakeholderPosition: {
						__typename: 'StakeholderPositionFields',
						id: stakeholders[index].id,
						x,
						y
					}
				}
			});

			if (props.selection) props.select({ ...props.selection, x, y });
		}
	};

	const handleChangeItemIcon = (id: string, icon: any) => {
		//console.log('handleChangeItemIcon', icon);

		updateStakeholderIcon({
			variables: { id: id, icon },
			update(cache) {
				cache.modify({
					id: `Stakeholder:${id}`,
					fields: {
						icon() {
							return icon;
						}
					}
				});
			},

			optimisticResponse: {
				updateStakeholderIcon: {
					__typename: 'StakeholderIconFields',
					id,
					icon: icon
				}
			}
		});

		if (props.selection) props.select({ ...props.selection, icon });
	};

	const handleCreateTag = async (name: string) => {
		await props.onCreateTag(name, async (tagId) => {
			//if (props.selection && props.selection.id)
			//	console.log('assigning tagId', tagId);

			let tagging = await createTagging({
				variables: {
					objectId: props.selection.id,
					tagId,
					type: 'stakeholder'
				},

				optimisticResponse: {
					createTagging: {
						__typename: 'Tagging',
						id: props.selection.id + '-' + tagId,
						tagId,
						type: 'stakeholder',
						objectId: props.selection.id
					}
				},

				update(cache) {
					cache.modify({
						id: `Stakeholder:${props.selection.id}`,
						fields: {
							taggings() {
								return props.selection.taggings.concat({
									__typename: 'Tagging',
									id: props.selection.id + '-' + tagId,
									tagId,
									type: 'stakeholder',
									objectId: props.selection.id
								});
							}
						}
					});
				}
			});

			props.refetch();
			setTimeout(() => {
				const stakefactor = indexedStakeholders[props.selection.id];

				props.select({
					...stakefactor,
					taggings: [
						...(stakefactor.taggings || []),
						{
							__typename: 'Tagging',
							id: props.selection.id + '-' + tagId,
							tagId,
							objectId: props.selection.id
						}
					]
				});
			}, 200);
		});
	};

	const handleChangeItemTags = async (item: StakeholderModel, tags: any[]) => {
		console.log('handleChangeItemTags', item, tags);

		// Old tags ids
		let oldTags = (item.taggings || []).map((t) => t.tagId);
		const indexedOldTags = keyBy(item.taggings, "tagId");

		// New tags ids
		let newTags = tags.map((t) => t.tagId || t.id);

		const tagsToAdd = newTags.filter((t) => !oldTags.includes(t));
		const tagsToRemove = oldTags.filter((t) => !newTags.includes(t));

		//console.log('tagsToAdd', tagsToAdd)
		console.log('tagsToRemove', tagsToRemove)

		await Promise.all([
			...tagsToAdd.map((t) =>
				createTagging({
					variables: {
						objectId: item.id,
						type: "stakeholder",
						tagId: t
					}
				})
			),
			...tagsToRemove.map((t) =>
				deleteTagging({
					variables: {
						id: indexedOldTags[t].id
					}
				})
			)
		]);

		// Remove
		const allTags = _.uniqBy(
			(item.taggings || [])
				.concat(tags)
				.filter((t) => t && !tagsToRemove.includes(t.tagId || t.id)),
			(t) => t.id
		);

		const formattedTags = allTags.map((t) => ({
			id: item.id + '-' + (t.tagId || t.id),
			tagId: t.tagId || t.id,
			objectId: item.id,
			type: 'stakeholder',
		}));

		if (props.selection)
			props.select({ ...props.selection, taggings: formattedTags });
		await props.refetch();
	};

	const handleChangeItemImpact = async (id: string, impact: any) => {
		//console.log('handleChangeItemImpact', impact)

		if (props.scenario) {
			let index = findIndex(stakeholders, (s: any) => s.id === id);
			let scenarioId = props.scenario.id;
			let stakeholder = stakeholders[index]
			let scenarioValues = stakeholder.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				impact
			};

			await optimisticlyUpdateScenario(
				stakeholders[index],
				'Stakeholder',
				scenarioId,
				scenarioValues,
				updateStakeholderScenario
			);

			if (props.selection) props.select({ ...props.selection, scenarios: props.selection.scenarios.map(s => (s.id === scenarioId ? { ...s, impact } : s)) });
		} else {
			updateStakeholderImpact({
				variables: { id: id, impact },
				update(cache) {
					cache.modify({
						id: `Stakeholder:${id}`,
						fields: {
							impact() {
								return impact;
							}
						}
					});
				},

				optimisticResponse: {
					updateStakeholderImpact: {
						__typename: 'StakeholderImpactFields',
						id,
						impact
					}
				}
			});

			if (props.selection) props.select({ ...props.selection, impact });
		}
	};

	const handleChangeItemTrend = async (id: string, trend: any) => {
		//console.log('handleChangeItemTrend', trend)

		if (props.scenario) {
			let index = findIndex(stakeholders, (s: any) => s.id === id);
			let scenarioId = props.scenario.id;
			let stakeholder = stakeholders[index]
			let scenarioValues = stakeholder.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				trend
			};

			await optimisticlyUpdateScenario(
				stakeholder,
				"Stakeholder",
				scenarioId,
				scenarioValues,
				updateStakeholderScenario
			);

			if (props.selection) props.select({ ...props.selection, scenarios: props.selection.scenarios.map(s => (s.id === scenarioId ? { ...s, trend } : s)) });
		} else {
			updateStakeholderTrend({
				variables: { id: id, trend },
				update(cache) {
					cache.modify({
						id: `Stakeholder:${id}`,
						fields: {
							trend() {
								return trend;
							}
						}
					});
				},

				optimisticResponse: {
					updateStakeholderTrend: {
						__typename: 'StakeholderTrendFields',
						id,
						trend
					}
				}
			});

			if (props.selection) props.select({ ...props.selection, trend });
		}
	};

	const handleChangeItem = (id: string, values: any) => {
		let index = findIndex(stakeholders, (s: any) => s.id === id);

		updateStakeholder({
			variables: { id: stakeholders[index].id, ...values },
			update(cache, { data: { updateStakeholder } }) {
				cache.modify({
					id: `Stakeholder:${id}`,
					fields: {
						name() {
							return updateStakeholder.name;
						},
						description() {
							return updateStakeholder.description;
						}
					}
				});
			},

			optimisticResponse: {
				updateStakeholder: {
					__typename: 'StakeholderBaseFields',
					id,
					...values
				}
			}
		});

		if (props.selection) props.select({ ...props.selection, ...values });
	};

	const handleChange = (action: string, value: string) => {
		if (!props.selection) return;

		if (action == 'trend') {
			handleChangeItemTrend(props.selection?.id, value);
		} else if (action == 'impact') {
			handleChangeItemImpact(props.selection?.id, value);
		} else if (action == 'edit') {
			setEditedStakeholder(props.selection);
		} else if (action == 'link') {
			setMode({ name: 'linking', origin: props.selection });
		}
	};

	const select = async (item, callback) => {
		if (mode.name == 'initial') {
			props.select(item, callback);
		} else if (mode.name == 'linking') {
			if (
				item &&
				item.id &&
				mode.origin &&
				mode.origin.id &&
				item.id !== mode.origin.id
			) {
				await handleCreateStakeholderLink(mode.origin, item);
				await props.select(item);
			}

			setMode({ name: 'initial' });
		}
	};

	return (
		<div
			className={
				'Navigator ' +
				mode.name +
				' ' +
				(props.expanded ? 'expanded' : '')
			}
		>
			<HeaderPortal>
				{props.portalContent}
				<FontAwesomeIcon
					style={{
						marginLeft: '0.5rem',
						marginRight: '0.5rem',
						cursor: 'pointer'
					}}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			<SummaryColumn
				isFiltered={hasFilters}
				onIndicatorClick={(i: any) => {
					indicatorFilter == i.id
						? setIndicatorFilter(null)
						: setIndicatorFilter(i.id);
					props.select(null);
				}}
				onConstraintClick={(i: any) => {
					constraintFilter == i.id
						? setConstraintFilter(null)
						: setConstraintFilter(i.id);
					props.select(null);
				}}
				indicatorFilter={indicatorFilter}
				constraintFilter={constraintFilter}

				{...props}

				stakeholders={filteredStakeholders}
				scenario={props.scenario}
				scenarios={props.scenarios}
				pushModal={props.pushModal}
				advanced={props.displayConstraints || props.displayIndicators}
				popModal={props.popModal}

				likelihoods={props.likelihoods}
				impacts={props.impacts}

				areConstraintsVisible={areConstraintsVisible}
				areIndicatorsVisible={areIndicatorsVisible}
				areRisksVisible={areRisksVisible}
				areOpportunitiesVisible={areOpportunitiesVisible}
				setPreferences={props.setPreferences}
			/>

			<div
				style={{
					position: 'absolute',
					top: remainingSpaceForCenter.y1 + 'px',
					left: offsetX + 'px',
					width: maxWidth + 'px',
					height: maxWidth + 'px',
					zIndex: 0
				}}
			></div>
			<div
				className={`Body fw${remainingSpaceForCenter.width} x1_${remainingSpaceForCenter.x1} w${maxWidth} h${maxWidth} oX${offsetX} oY${offsetY}`}
				style={{
					position: 'absolute',
					width: `${maxWidth}px`,
					height: `${maxWidth}px`,
					top: remainingSpaceForCenter.y1 + 'px',
					left: offsetX + 'px'
				}}
			>
				{maxWidth && (
					<MainRadar
						offsetX={offsetX}
						offsetY={offsetY}
						width={maxWidth}
						height={maxWidth}
						selection={props.selection}
						select={select}
						mode={mode}
						scenario={props.scenario}
						deselect={props.deselect}
						onChangeItemPosition={handleChangeItemPosition}
						onClickLink={handleChangeStakeholderLinkStrength}
						children={filteredStakeholders}
						onChange={handleChange}
						displayAllLinks={areAllLinksVisible}
						isTransitioning={isTransitioning}
						isLegendVisible={hasRadarLegend}
					></MainRadar>
				)}
			</div>
			<TutorialPopover
				onClose={closeHelp}
				placement={'left'}
				style={{ maxWidth: '400px' }}
				visible={visibleHelpStep == 3}
				body={
					<>
						The gauge is regulated by an algorithm that takes into
						consideration all parameters that will be associated,
						individually and collectively, to the stakeholders. The
						gauge defines whether your stakeholders context is
						favorable or hostile to the achievement of your goal. By
						working on the stakeholders, you can directly influence
						the gauge
					</>
				}
				onNext={onNext}
				hasNext={hasNext}
			>
				<div
					className="GaugeWrapper"
					style={{
						width: isFullMode ? maxWidth / 6 : maxWidth / 4 + 'px',
						marginTop: maxWidth / 4 / 2 + 'px'
					}}
				></div>

				{!props.scenario && (
					<AddButton
						id="AddItem"
						tooltip={t('models.item.actions.create_new')}
						onClick={() => {
							setEditedStakeholder({});
						}}
						className={'opened'}
						popover={
							<TutorialPopover
								onClose={closeHelp}
								placement={'left'}
								visible={visibleHelpStep == 1}
								body="Click here to add a stakeholder"
								onNext={onNext}
								hasNext={hasNext}
							></TutorialPopover>
						}
					></AddButton>
				)}
			</TutorialPopover>

			<TutorialPopover
				onClose={closeHelp}
				placement={'left'}
				visible={visibleHelpStep == 2}
				body={
					<>
						This part allows you to filter the stakeholders
						<br />
						according to several criteria
					</>
				}
				onNext={onNext}
				hasNext={hasNext}
			>
				<aside
					className={
						'SideMenu noselection opened'
					}
				>
					<ul className="VerticalTabs">

						{props.selection ? (
							<>
								<li
									onClick={() => {
										if (!props.selection) return;
										setSelectedTab('general');
									}}
									className={[
										selectedTab == 'general'
											? 'selected'
											: '',
										!props.selection ? 'disabled' : ''
									].join(' ')}
								>
									{t('pages.navigator.tabs.general.title')}
								</li>



								{!props.scenario && (
									<li
										onClick={() => {
											if (!props.selection) return;
											setSelectedTab('links');

										}}
										className={[
											selectedTab == 'links'
												? 'selected'
												: '',
											!props.selection ? 'disabled' : ''
										].join(' ')}
									>
										{t('pages.navigator.tabs.links.title')}
									</li>
								)}

								{!props.scenario && (
									<li
										onClick={() => {
											if (!props.selection) return;
											setSelectedTab('comments');

										}}
										className={[
											selectedTab == 'comments'
												? 'selected'
												: '',
											!props.selection ? 'disabled' : ''
										].join(' ')}
									>
										{t(
											'pages.navigator.tabs.comments.title'
										)}
									</li>
								)}
							</>
						) : (
							<>
								<li
									onClick={() => {
										setSelectedGlobalTab('filters');

									}}
									className={[
										selectedGlobalTab == 'filters'
											? 'selected'
											: ''
									].join(' ')}
								>
									{t('pages.navigator.tabs.filters.title')}
								</li>

								{<li
									onClick={() => {
										setSelectedGlobalTab('bot');

									}}
									className={[
										selectedGlobalTab == 'bot'
											? 'selected'
											: ''
									].join(' ')}
								>
									{t('pages.navigator.tabs.bot.title')}
								</li>
								}
							</>
						)}
					</ul>
					<div className="content h-100 pb-4 ">
						{props.selection ? (
							<>
								<div className="d-flex flex-row align-items-center">
									<div
										className="IconPicker"
										style={{ marginRight: '1rem' }}
									>
										<IconPicker
											value={
												props.selection?.icon ||
												'faUser'
											}
											favorites={allIcons}
											disabled={!!props.scenario}
											onChange={
												props.scenario
													? () => { }
													: (v) =>
														handleChangeItemIcon(
															props.selection
																.id,
															v
														)
											}
										/>
									</div>

									<div>{props.selection?.name}</div>
								</div>

								{selectedTab == 'general' && (
									<GeneralTab
										selection={
											indexedStakeholders[
											props.selection?.id
											]
										}
										indicators={indicators}
										constraints={constraints}
										onEdit={handleEditStakeholder}
										onClone={(defaults) =>
											handleCreateStakeholder(
												{},
												defaults
											)
										}
										onDelete={askDeleteConfirmation}
										scenario={props.scenario}
										displayConstraints={
											props.displayConstraints
										}
										displayIndicators={
											props.displayIndicators
										}
										stakeholders={stakeholders}
										indexedStakeholders={indexedStakeholders}
										onDeleteStakeholderLink={
											handleDeleteStakeholderLink
										}
										onChangeStakeholderLinkStrength={
											handleChangeStakeholderLinkStrength
										}
										onCreateLink={() =>
											handleChange('link')
										}
										select={props.select}
										risks={props.risks}

										tags={allTags}
										onChangeItemTags={handleChangeItemTags}
										onCreateTag={handleCreateTag}

										areTagsVisible={areTagsVisible}
										areConstraintsVisible={areConstraintsVisible}
										areIndicatorsVisible={areIndicatorsVisible}
										areRisksVisible={areRisksVisible}
										areOpportunitiesVisible={areOpportunitiesVisible}
										setPreferences={props.setPreferences}
									></GeneralTab>
								)}

								{selectedTab == 'links' && (
									<LinksTab
										indexedStakeholders={
											indexedStakeholders
										}
										stakeholders={stakeholders}
										onDeleteStakeholderLink={
											handleDeleteStakeholderLink
										}
										onChangeStakeholderLinkStrength={
											handleChangeStakeholderLinkStrength
										}
										onCreateLink={() =>
											handleChange('link')
										}
										selection={props.selection}
										scenario={props.scenario}
									></LinksTab>
								)}

								{selectedTab == 'comments' && (
									<CommentsTab
										indexedStakeholders={
											indexedStakeholders
										}
										selection={props.selection}
										pushModal={props.pushModal}
										popModal={props.popModal}
										scenario={props.scenario}
									></CommentsTab>
								)}
							</>
						) : selectedGlobalTab == "bot" ? <>
							<BotTab
								consent={props.aiConsent}
								projectId={params.id}
								messages={props.botThread?.messages}
								onResetThread={props.resetBotThread}
								onSendMessage={(message, callback) => props.addBotMessage(props.aiConsent === "FULL" ? params.id : null, props.aiConsent === "FULL" ? props.scenario?.id : null, "stakeholders", message, callback)}
							>

							</BotTab>
						</> : (
							<>
								<Input
									onChange={(e) => {
										props.setSearchTerm(e.target.value);
									}}
									value={props.searchTerm}
									style={{
										marginLeft: 'auto',
										marginRight: '1em'
									}}
									placeholder="Search"
									className="SearchInput mt-2"
								></Input>

								<ul>
									<li className="EditableListItem nomargin mt-2">
										<div style={{ textAlign: 'left' }}>
											{t('pages.navigator.links')}
										</div>
										<Weight
											count={1}
											onClick={() => {
												props.setPreferences(
													'pages',
													'navigator.links',
													!areAllLinksVisible
												);
											}}
										>
											{areAllLinksVisible ? 1 : 0}
										</Weight>
									</li>
								</ul>




								<div
									className="text-left mt-4"
									style={{ marginBottom: '0.5rem' }}
								>
									{t('pages.navigator.all_icons')}
								</div>

								{(allIcons || []).length == 0 ? (
									<div
										style={{
											color: 'rgba(46, 160, 223, 0.3)'
										}}
									>
										{t('pages.navigator.no_icon')}
									</div>
								) : (
									<div className="text-left">
										{allIcons.map((i, index) => (
											<Tag
												key={'i' + index}
												onClick={() => {
													iconFilter == i
														? setIconFilter(null)
														: setIconFilter(i);
												}}
												className={[
													'tag',
													iconFilter == i
														? ''
														: 'unselected'
												].join(' ')}
												style={{
													verticalAlign: 'middle',
													paddingBottom: '0.34rem',
													paddingTop: '0.1rem'

												}}
											>
												<FontAwesomeIcon
													style={{ fontSize: '0.9rem' }}
													icon={fal[i]}
												></FontAwesomeIcon>
											</Tag>
										))}
									</div>
								)}

								<div
									className="text-left mt-4 d-flex align-items-center"
									style={{ marginBottom: '0.5rem' }}
								>
									<span
										className="text-nowrap"
										style={{ marginRight: '1rem' }}
									>
										{t(
											'pages.navigator.all_tags'
										)}
									</span>

									{allTags && allTags.length > 0 && <div className="ml-auto">
										<Button shape="circle" className="mini discreet" onClick={() => setShowAllTags(!showAllTags)}>
											<FontAwesomeIcon
												icon={!showAllTags ? faPlus : faMinus}
											></FontAwesomeIcon>
										</Button>
									</div>}
								</div>


								<div className="text-left">
									{
										((allTags && allTags.length > 0 && showAllTags) || values(usedTags).length > 0) ?
											allTags.map((t, index) => {
												return (showAllTags || usedTags[t.id]) && <Tag
													key={'t' + index}
													onClick={() => {
														// Add filter if not present, remove it if already present
														tagFilter.includes(t.id)
															? setTagFilter(
																tagFilter.filter(
																	(tag) =>
																		tag !==
																		t.id
																)
															)
															: setTagFilter([
																...tagFilter,
																t.id
															]);
														//tagFilter == t ? setTagFilter(null) : setTagFilter(t)
													}}
													className={[
														'tag',
														usedTags[t.id] ? "used" : "unused",
														tagFilter.includes(t.id)
															? ''
															: 'unselected'
													].join(' ')}
												>
													{t.name}
												</Tag>

											})
											:
											<div
												className="text-center"
												style={{
													color: 'rgba(46, 160, 223, 0.3)'
												}}
											>
												{t('pages.navigator.no_tag')}
											</div>
									}
								</div>

								{props.displayConstraints && (
									<>
										<div
											className="text-left mt-4 d-flex align-items-center"
											style={{ marginBottom: '0.5rem' }}
										>
											<span
												className="text-nowrap"
												style={{ marginRight: '1rem' }}
											>
												{t(
													'pages.navigator.all_constraints'
												)}
											</span>

											{constraints && constraints.length > 0 && (values(usedConstraints).length != constraints.length) && < div className="ml-auto">
												<Button shape="circle" className="mini discreet" onClick={() => setShowAllConstraints(!showAllConstraints)}>
													<FontAwesomeIcon
														icon={!showAllConstraints ? faPlus : faMinus}
													></FontAwesomeIcon>
												</Button>
											</div>}
										</div>
										<div className="text-left">
											{((constraints && constraints.length > 0 && showAllConstraints) || values(usedConstraints).length > 0) ? (
												sortBy(
													constraints,
													'name'
												).map(
													(i: any, index: Number) => {
														return (
															(showAllConstraints || usedConstraints[i.id]) &&
															<Tag
																key={
																	'c' + index
																}
																onClick={() => {
																	constraintFilter ==
																		i.id
																		? setConstraintFilter(
																			null
																		)
																		: setConstraintFilter(
																			i.id
																		);
																	props.select(
																		null
																	);
																}}
																className={[
																	'tag ImpactedConstraint',
																	usedConstraints[i.id] ? "used" : "unused",
																	constraintFilter ==
																		i.id
																		? ''
																		: 'unselected'
																].join(' ')}
															>
																{i.name}
															</Tag>
														);
													}
												)
											) : (
												<div
													className="text-center"
													style={{
														color: 'rgba(46, 160, 223, 0.3)'
													}}
												>
													{t(
														'models.constraint.no_x'
													)}
												</div>
											)}
										</div>
									</>
								)}

								{props.displayIndicators && (
									<>
										<div
											className="text-left mt-4 d-flex align-items-center"
											style={{ marginBottom: '0.5rem' }}
										>
											<span
												className="text-nowrap"
												style={{ marginRight: '1rem' }}
											>
												{t(
													'pages.navigator.all_indicators'
												)}
											</span>

											{indicators && indicators.length > 0 && (values(usedIndicators).length != indicators.length) && <div className="ml-auto">
												<Button shape="circle" className="mini discreet" onClick={() => setShowAllIndicators(!showAllIndicators)}>
													<FontAwesomeIcon
														icon={!showAllIndicators ? faPlus : faMinus}
													></FontAwesomeIcon>
												</Button>
											</div>}
										</div>
										<div className="text-left">
											{(
												(indicators && indicators.length > 0 && showAllIndicators) || values(usedIndicators).length > 0) ? (
												sortBy(
													indicators,
													'name'
												).map(
													(i: any, index: Number) => {
														return (
															(showAllIndicators || usedIndicators[i.id]) &&
															<Tag
																key={
																	'i' + index
																}
																onClick={() => {
																	indicatorFilter ==
																		i.id
																		? setIndicatorFilter(
																			null
																		)
																		: setIndicatorFilter(
																			i.id
																		);
																	props.select(
																		null
																	);
																}}
																className={[
																	'tag ImpactedIndicator',
																	usedIndicators[i.id] ? "used" : "unused",
																	indicatorFilter ==
																		i.id
																		? ''
																		: 'unselected'
																].join(' ')}
															>
																{i.name}
															</Tag>
														);
													}
												)
											) : (
												<div
													className="text-center"
													style={{
														color: 'rgba(46, 160, 223, 0.3)'
													}}
												>
													{t('models.indicator.no_x')}
												</div>
											)}
										</div>
									</>
								)}

								{props.displayRisks && (
									<>
										<div
											className="text-left mt-4 d-flex align-items-center"
											style={{ marginBottom: '0.5rem' }}
										>
											<span
												className="text-nowrap"
												style={{ marginRight: '1rem' }}
											>
												{t('pages.navigator.all_risks')}
											</span>


											{allRisks.length > 0 && (values(usedRisks).length != allRisks.length) && <div className="ml-auto">
												<Button shape="circle" className="mini discreet" onClick={() => setShowAllRisks(!showAllRisks)}>
													<FontAwesomeIcon
														icon={!showAllRisks ? faPlus : faMinus}
													></FontAwesomeIcon>
												</Button>
											</div>}
										</div>
										<div className="text-left">
											{((allRisks.length > 0 && showAllRisks) || values(usedRisks).length > 0) ? (
												allRisks.map(
													(i: any, index: Number) => {
														return (
															(showAllRisks || usedRisks[i.id]) &&
															<Tag
																key={
																	'c' + index
																}
																onClick={() => {
																	riskFilter ==
																		i.id
																		? setRiskFilter(
																			null
																		)
																		: setRiskFilter(
																			i.id
																		);
																	props.select(
																		null
																	);
																}}
																className={[
																	'tag ImpactedRisk',
																	usedRisks[i.id] ? "used" : "unused",
																	riskFilter ==
																		i.id
																		? ''
																		: 'unselected'
																].join(' ')}
															>
																{i.name}
															</Tag>
														);
													}
												)
											) : (
												<div
													className="text-center"
													style={{
														color: 'rgba(46, 160, 223, 0.3)'
													}}
												>
													{t(
														'models.risk.messages.no_x'
													)}
												</div>
											)}
										</div>

										<div
											className="text-left mt-4 d-flex align-items-center"
											style={{ marginBottom: '0.5rem' }}
										>
											<span
												className="text-nowrap"
												style={{ marginRight: '1rem' }}
											>
												{t('pages.navigator.all_opportunities')}
											</span>


											{allOpportunities.length > 0 && (values(usedOpportunities).length != allOpportunities.length) && <div className="ml-auto">
												<Button shape="circle" className="mini discreet" onClick={() => setShowAllOpportunities(!showAllOpportunities)}>
													<FontAwesomeIcon
														icon={!showAllOpportunities ? faPlus : faMinus}
													></FontAwesomeIcon>
												</Button>
											</div>}
										</div>
										<div className="text-left">
											{((allOpportunities.length > 0 && showAllOpportunities) || values(usedOpportunities).length > 0) ? (
												allOpportunities.map(
													(i: any, index: Number) => {
														return (
															(showAllOpportunities || usedOpportunities[i.id]) &&
															<Tag
																key={
																	'c' + index
																}
																onClick={() => {
																	riskFilter ==
																		i.id
																		? setRiskFilter(
																			null
																		)
																		: setRiskFilter(
																			i.id
																		);
																	props.select(
																		null
																	);
																}}
																className={[
																	'tag ImpactedRisk',
																	usedOpportunities[i.id] ? "used" : "unused",
																	riskFilter ==
																		i.id
																		? ''
																		: 'unselected'
																].join(' ')}
															>
																{i.name}
															</Tag>
														);
													}
												)
											) : (
												<div
													className="text-center"
													style={{
														color: 'rgba(46, 160, 223, 0.3)'
													}}
												>
													{t(
														'models.risk.messages.no_x_positive'
													)}
												</div>
											)}
										</div>
									</>
								)}
							</>
						)}
					</div>
				</aside>
			</TutorialPopover>

			{
				editedStakeholder && (
					<StakeholderModal
						onHide={() => {
							setEditedStakeholder(null);
						}}
						onSave={(values: Object) => {
							editedStakeholder.id
								? handleChangeItem(editedStakeholder.id, {
									...pick(editedStakeholder, [
										'name',
										'description',
										'impact',
										'trend',
										'icon'
									]),
									...values
								})
								: handleCreateStakeholder(
									{
										...values
									},
									{}
								);
							setEditedStakeholder(null);
						}}
						onDelete={() => {
							askDeleteConfirmation(editedStakeholder.id);
							setEditedStakeholder(null);
						}}
						id={editedStakeholder?.id}
						values={{
							name: editedStakeholder?.name,
							description: editedStakeholder?.description,
							impact: editedStakeholder?.impact
						}}
					></StakeholderModal>
				)
			}
		</div >
	);
};

export default Navigator;
