import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faTimesCircle,
	faTrash
} from '@fortawesome/pro-light-svg-icons';
import { Button, Popover } from 'antd';

type ITutorialPopover = {
	title: String;
	visible: boolean;
	hasPrevious: boolean;
	hasNext: boolean;
	onNext: Function;
	body: String | Object;
	placement: any;
	children: String | Object;
	onClose: Function;
	style: any;
};

function TutorialPopover(props: ITutorialPopover) {
	return (
		<Popover
			trigger={[]}
			open={props.visible}
			placement={props.placement}
			title={props.title}
			style={props.style}
			content={
				<>
					<div style={{ maxWidth: '300px' }}>{props.body}</div>

					<div
						style={{
							borderTop: '1px solid #EEEEEE',
							margin: '0.8rem -1rem -1rem -1rem',
							padding: '0.5rem 0.5rem 0.7rem'
						}}
					>
						<Button
							onClick={() => props.onClose()}
							size="small"
							style={{ marginRight: '0.5rem' }}
						>
							Close
						</Button>
						{props.hasNext && (
							<Button
								onClick={() => props.onNext()}
								type="primary"
								size="small"
							>
								Next
							</Button>
						)}
					</div>
				</>
			}
		>
			{props.children}
		</Popover>
	);
}

export default TutorialPopover;
