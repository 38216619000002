// useScreenSize.js
import { useState, useEffect } from 'react';

const useScreenSize = () => {
	const [screenSize, setScreenSize] = useState({
		width: document.body.clientWidth,
		height: document.body.clientHeight
	});

	useEffect(() => {
		const handleResize = () => {
			setScreenSize({
				width: document.body.clientWidth,
				height: document.body.clientHeight
			});
		};

		window.addEventListener('resize', handleResize);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenSize;
};

export default useScreenSize;
