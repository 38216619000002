import React, { Component, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, Button, Form, Input, Slider, Tooltip } from 'antd';

import { useGetAssetUrlMutation } from 'graph/dist/generated';
import Loader from 'components/Loading/Loader';

import './IntroductionModal.sass';

export const IntroductionModal = (props: any) => {
	const { t } = useTranslation();
	const [urls, setUrls] = useState({});

	const [getAssetUrl] = useGetAssetUrlMutation();

	useEffect(() => {
		getAssetUrl({
			variables: { id: 'introduction.mp4' }
		}).then((data) => {
			console.log('IntroductionModal', data);
			if (data?.data?.getAssetUrl) {
				const mp4Url = data?.data?.getAssetUrl;

				getAssetUrl({
					variables: { id: 'introduction.mov' }
				}).then((data) => {
					console.log('IntroductionModal', data);
					if (data?.data?.getAssetUrl)
						setUrls({
							mov: data?.data?.getAssetUrl,
							mp4: mp4Url
						});
				});
			}
		});
	}, []);

	// Use effect when opening modal to getAssetUrl from GraphQL

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				style={{ marginRight: 'auto' }}
				onClick={() => {
					props.onHide();
				}}
			>
				Dismiss
			</Button>
		</div>
	);

	console.log('url', urls);

	return (
		<Modal
			open={true}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			width={'98vw'}
			style={{ height: '90vh', top: '3vh' }}
			maskClosable={false}
		>
			{!urls.mov ? (
				<Loader></Loader>
			) : (
				<div
					id="IntroductionModal"
					style={{
						maxWidth: 'calc( 80vh / 1080 * 1920 )',
						maxHeight: 'calc( 98vw / 1920 * 1080 )',
						height: '82vh',
						overflow: 'hidden',
						paddingTop: '2vh',
						margin: 'auto'
					}}
				>
					<video width="100%" height="100%" controls autoPlay={true}>
						<source src={urls.mov}></source>
						<source src={urls.mp4}></source>
					</video>
				</div>
			)}
		</Modal>
	);
};

export default IntroductionModal;
