import React, { Component } from 'react';
import { useTranslation, Trans } from 'react-i18next';


import { Modal } from 'antd';

export const ConsentModal = (props) => {
    const { t } = useTranslation();

    return <Modal
        onOk={() => {
            props.onConfirm();
        }}

        open={true}
        closable={false}
        closeIcon={false}
        maskClosable={false}
        width={350}
        cancelButtonProps={{
            style: {
                display: "none",
            },
        }}
        title={t("messages.legal.agreement_title")}
        okText={t('messages.legal.i_consent')}
        cancelText={null}
    >
        <Trans i18nKey="messages.legal.agreement_content" terms={t("messages.legal.agreement_terms_conditions")} policy={t("messages.legal.privacy_policy")}>
            {t("messages.legal.agreement_content", { terms: t("messages.legal.agreement_terms_conditions") })}
            <a target='_blank' href='https://www.gerositus.com/terms-conditions'></a>
            <a target='_blank' href='https://www.gerositus.com/privacy-policy'></a>
        </Trans>

        <br />
        <br />
        <br />
    </Modal>


}

export default ConsentModal;

