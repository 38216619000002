import gql from 'graphql-tag';
import { findIndex, pick } from 'lodash';
import {
	useCreateScenarioMutation,
	useDeleteScenarioMutation,
	useUpdateScenarioMutation,
	useUpdateConstraintScenarioMutation,
	useUpdateIndicatorScenarioMutation,
	useUpdateStakeholderScenarioMutation,
	useUpdateRiskScenarioMutation
} from 'graph/dist/generated';
import {
	cloneScenarioElements,
	cloneScenarioElementsToBaseline
} from 'utils/graphql';
import { ScenarioModel } from 'model/ScenarioModel';
import { useIndicatorController } from './useIndicatorController';
import { useConstraintController } from './useConstraintController';
import { useRiskController } from './useRiskController';
import { useStakeholderController } from './useStakeholderController';

export const useScenarioController = () => {
	const [createScenario] = useCreateScenarioMutation();
	const [updateScenario] = useUpdateScenarioMutation();
	const [deleteScenario] = useDeleteScenarioMutation();
	const [updateIndicatorScenario] = useUpdateIndicatorScenarioMutation();
	const [updateConstraintScenario] = useUpdateConstraintScenarioMutation();
	const [updateStakeholderScenario] = useUpdateStakeholderScenarioMutation();
	const [updateRiskScenario] = useUpdateRiskScenarioMutation();

	const { updateIndicator } = useIndicatorController();
	const { updateConstraint } = useConstraintController();
	const { updateStakeholder } = useStakeholderController();
	const { updateRisk } = useRiskController();

	const handleCreateScenario = async (
		projectId: string,
		context,
		referenceScenario: any,
		values: any,
		callback: Function
	) => {
		let variables = {
			projectId: projectId,
			name: values.name,
			description: values.description || '',
			impacts: values.impacts || ''
		};

		let result = await createScenario({
			variables,
			update(cache, { data: { createScenario } }) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						scenarios(scenarios = []) {
							const newScenario = cache.writeFragment({
								id: 'Scenario:' + createScenario.id,
								data: createScenario,
								fragment: gql`
									fragment ScenarioFragment on Scenario {
										name
										description
										impacts
										createdAt
									}
								`
							});

							return [...scenarios, newScenario];
						}
					}
				});
			},
			optimisticResponse: {
				createScenario: {
					id: 'temp-id',
					...variables,
					createdAt: new Date().toISOString()
				}
			}
		});

		cloneScenarioElements(
			context,
			result.data.createScenario,
			referenceScenario,
			{
				updateIndicatorScenario,
				updateConstraintScenario,
				updateStakeholderScenario,
				updateRiskScenario
			}
		);
		if (callback) callback();
	};

	const handleUpdateScenario = async (
		id: string,
		scenarios: any[],
		values: Object
	) => {
		let index = findIndex(scenarios, (s: any) => s.id === id);

		let newScenario = {
			...pick(scenarios[index], ['description', 'name', 'impacts']),
			...pick(values, ['description', 'name', 'impacts'])
		};

		const result = await updateScenario({
			variables: {
				id: id,
				...newScenario
			},
			update(cache, { data: { updateScenario } }) {
				cache.modify({
					id: `Scenario:${id}`,
					fields: {
						name() {
							return updateScenario.name || newScenario.name;
						},
						description() {
							return (
								updateScenario.description ||
								newScenario.description
							);
						},
						impacts() {
							return (
								updateScenario.impacts || newScenario.impacts
							);
						}
					}
				});
			},
			optimisticResponse: {
				updateScenario: {
					__typename: 'Scenario',
					id,
					...newScenario
				}
			}
		});
	};

	const handlePromoteScenarioAsBaseline = async (
		project,
		scenario: ScenarioModel,
		keepBaselineAsScenario: boolean,
		baselineNewName: string,
		keepScenario: boolean
	) => {
		console.log(
			'handlePromoteScenarioAsBaseline',
			project,
			scenario,
			keepBaselineAsScenario,
			baselineNewName,
			keepScenario
		);

		if (!scenario) return;

		// Copy scenario description to baseline
		if (keepBaselineAsScenario) {
			const scenarioDetails = {
				description:
					scenario.description || project.baselineScenarioDescription,
				impacts: scenario.impacts || project.baselineScenarioImpacts
			};

			// Create a new scenario from baseline
			await handleCreateScenario(
				project.id,
				project,
				null,
				{
					name: baselineNewName,
					description: scenarioDetails.description,
					impacts: scenarioDetails.impacts
				},
				() => {}
			);
		}

		// Copy scenario elements to baseline
		await cloneScenarioElementsToBaseline(
			pick(project, [
				'indicators',
				'constraints',
				'stakeholders',
				'risks'
			]),
			scenario,
			{
				updateIndicator,
				updateConstraint,
				updateStakeholder,
				updateRisk
			}
		);

		if (!keepScenario) {
			await handleDelete(project.id, scenario.id);
		}
	};

	const handleDelete = async (projectId: string, id: string | undefined) => {
		console.log('deleteScenario', 'projectId', projectId, 'id', id);

		if (id == null) {
			return;
		}

		await deleteScenario({
			variables: { id: id },
			update(cache) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						scenarios(existingScenariosRef, { readField }) {
							return existingScenariosRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteScenario: true
			}
		});
	};

	return {
		createScenario: handleCreateScenario,
		updateScenario: handleUpdateScenario,
		deleteScenario: handleDelete,
		promoteScenarioAsBaseline: handlePromoteScenarioAsBaseline
	};
};
