import React, { Component } from 'react';

import ModalContext from './ModalContext';

export default class ModalProvider extends Component {
	pushModal = (component, props = {}) => {
		this.setState({
			modals: [...this.state.modals, component]
		});
	};

	popModal = (number = 1) => {
		this.setState({
			modals: [
				...this.state.modals.slice(0, this.state.modals.length - number)
			]
		});
	};

	replaceModal = (component) => {
		this.setState({
			modals: [
				...this.state.modals.slice(0, this.state.modals.length - 1),
				component
			]
		});
	};

	state = {
		modals: [],
		pushModal: this.pushModal,
		popModal: this.popModal,
		replaceModal: this.replaceModal
	};

	render() {
		return (
			<ModalContext.Provider value={this.state}>
				{this.props.children}
			</ModalContext.Provider>
		);
	}
}
