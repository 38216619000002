import React from 'react';

import Weight from "components/controls/Weight";
import { useTranslation } from "react-i18next";

import './OptionsTab.sass';

export const OptionsTab = (props: any) => {

    const { t } = useTranslation();

    const isLastEvaluationVisible = props.preferences?.evaluatedAt !== undefined ? props.preferences?.evaluatedAt : true
    const isRiskCategoryVisible = props.preferences?.riskCategory !== undefined ? props.preferences?.riskCategory : true
    const isStakeholdersVisible = props.preferences?.stakeholders || false
    const isMatchingROVisible = props.preferences?.matchingRO || false

    const areLinksVisible = props.preferences?.allLinks !== undefined ? props.preferences?.allLinks : false
    const showStakes = props.preferences?.stakesChart !== undefined ? props.preferences?.stakesChart : true
    const showStakeholders = props.preferences?.stakeholdersChart !== undefined ? props.preferences?.stakeholdersChart : true
    const showRisks = props.preferences?.risksChart !== undefined ? props.preferences?.risksChart : true
    const showOpportunities = props.preferences?.opportunitiesChart !== undefined ? props.preferences?.opportunitiesChart : true

    return (
        <div className="OptionsTab">

            <>
                <div
                    className="text-left mt-2"
                    style={{ marginBottom: '0.5rem' }}
                >
                    {t('pages.risks.tabs.filters.displayed_columns')}
                </div>
                <div className="columnsList">
                    <div className="d-flex mb-05 text-left">
                        <div style={{ width: "95%" }}>
                            {t('models.risk.fields.evaluated_at')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'evaluatedAt',
                                    !isLastEvaluationVisible
                                );
                            }}
                        >
                            {isLastEvaluationVisible ? 1 : 0}
                        </Weight>
                    </div>
                    <div className="d-flex mb-05 text-left">
                        <div style={{ width: "95%" }}>
                            {t('models.risk.fields.risk_category')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'riskCategory',
                                    !isRiskCategoryVisible
                                );
                            }}
                        >
                            {isRiskCategoryVisible ? 1 : 0}
                        </Weight>
                    </div>
                    <div className="d-flex mb-05 text-left">
                        <div style={{ width: "95%" }}>
                            {t('models.item.name_plural')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'stakeholders',
                                    !isStakeholdersVisible
                                );
                            }}
                        >
                            {isStakeholdersVisible ? 1 : 0}
                        </Weight>
                    </div>
                    <div className="d-flex mb-05 text-left">
                        <div style={{ width: "95%" }}>
                            {t('models.risk.fields.matching_risks')}
                            /
                            {t('models.risk.fields.matching_opportunity')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'matchingRO',
                                    !isMatchingROVisible
                                );
                            }}
                        >
                            {isMatchingROVisible ? 1 : 0}
                        </Weight>
                    </div>

                </div>
            </>

            <br />
            <>
                <div
                    className="text-left mt-2"
                    style={{ marginBottom: '0.5rem' }}
                >
                    {t('pages.risks.tabs.filters.visualization_options')}
                </div>
                <div className="columnsList">
                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }} className="text-left">
                            {t('pages.risks.tabs.options.are_links_visible')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'allLinks',
                                    !areLinksVisible
                                );
                            }}
                        >
                            {areLinksVisible ? 1 : 0}
                        </Weight>
                    </div>

                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }} className="text-left">
                            {t('pages.risks.tabs.options.chart_visible_stakes')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'stakesChart',
                                    !showStakes
                                );
                            }}
                        >
                            {showStakes ? 1 : 0}
                        </Weight>
                    </div>

                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }} className="text-left">
                            {t('pages.risks.tabs.options.chart_visible_risks')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'risksChart',
                                    !showRisks
                                );
                            }}
                        >
                            {showRisks ? 1 : 0}
                        </Weight>
                    </div>

                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }} className="text-left">
                            {t('pages.risks.tabs.options.chart_visible_opportunities')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'opportunitiesChart',
                                    !showOpportunities
                                );
                            }}
                        >
                            {showOpportunities ? 1 : 0}
                        </Weight>
                    </div>


                    <div className="d-flex mb-05">
                        <div style={{ width: "95%" }} className="text-left">
                            {t('pages.risks.tabs.options.chart_visible_stakeholders')}
                        </div>
                        <Weight
                            count={1}
                            onClick={() => {
                                props.setPreferences(
                                    'stakeholdersChart',
                                    !showStakeholders
                                );
                            }}
                        >
                            {showStakeholders ? 1 : 0}
                        </Weight>
                    </div>

                </div>
            </>

        </div>
    );
}