import React from 'react';

import { faClone, faClose, faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton from "components/action/ActionButton";

import { useTranslation } from "react-i18next";

import "./RiskGeneralTab.sass";
import { Button, Tag, Tooltip } from 'antd';
import { find, keyBy, sortBy } from 'lodash';
import { getScoreColor } from 'utils/ranking';
import Weight from 'components/controls/Weight';
import { isRiskOrOpportunityBetter, isRiskOrOpportunityImpactBetter, isRiskOrOpportunityLikelihoodBetter, isRiskOrOpportunityLikelihoodWorse, isRiskOrOpportunityWorse } from 'utils/rules';

interface RiskGeneralTabProps {
    selection: any;
    onEdit: Function;
    onClone: Function;
    onClose: Function;
    onDelete: Function;
    scenario: any;

    categories: any[];
    likelihoods: object;
    impacts: object;

    indicators: any[];
    opportunities: any[];
    createIndicatorImpact: Function;
    deleteIndicatorImpact: Function;
    updateImpactStrength: Function;

    onSetRiskOpportunity: Function;
}

const getImpactingIndicatorDetails = (impactedIndicators, indicatorId: string) => {
    return find(
        impactedIndicators || [],
        (ii: any) => ii.indicatorId == indicatorId
    );
};

const isImpactingIndicator = (indexedIndicators, impactedIndicators, indicatorId: string) => {
    if (
        indexedIndicators[indicatorId] &&
        impactedIndicators
            ?.map((ii: any) => ii.indicatorId)
            .includes(indicatorId)
    )
        return true;
    return false;
};

export const RiskGeneralTab = (props: RiskGeneralTabProps) => {

    const { categories = [], indicators = [], opportunities = [] } = props;
    const { t } = useTranslation();

    const indexedIndicators = keyBy(indicators, 'id');

    const isImpactBetter = isRiskOrOpportunityImpactBetter(props.selection);
    const isImpactWorse = isRiskOrOpportunityWorse(props.selection);
    const isLikehoodBetter = isRiskOrOpportunityLikelihoodBetter(props.selection);
    const isLikehoodWorse = isRiskOrOpportunityLikelihoodWorse(props.selection);


    const category = categories.find(
        (c: any) => c.id === props.selection?.riskCategoryId
    );

    return (
        <div className="RiskGeneralTab">

            <div className="d-flex flex-column flex-grow">

                <div className="d-flex flex-row align-items-start mb-1">
                    <div className="w-100 text-left">
                        <div className="title">{props.selection?.name}</div>
                        <small>{t(props.selection?.isOpportunity ? "models.risk.name_positive" : "models.risk.name")}</small>
                    </div>

                    <Tooltip title={t('actions.close')} placement='left'>
                        <Button
                            shape="circle"
                            className="mini ml-auto"
                            tooltip={t('models.item.actions.edit')}
                            style={{ marginRight: '-0.9rem' }}
                            onClick={props.onClose}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </Button>
                    </Tooltip>
                </div>

                <ul style={{ fontSize: "0.8rem" }} className="mb-1">
                    <li className="EditableListItem">
                        <div style={{ width: '40%', textAlign: 'left' }}>
                            {t('models.risk.fields.risk_category')}
                        </div>
                        <div>
                            {category ? category.name : t("models.risk.messages.no_category")}
                        </div>
                    </li>
                    <li className="EditableListItem">
                        <div style={{ width: '40%', textAlign: 'left' }}>
                            {t('models.risk.fields.impact')}
                        </div>
                        <div>
                            <div className={[isImpactBetter ? "positive" : "", isImpactWorse ? "negative" : ""].join(" ")}>
                                {props.impacts[parseInt(props.selection?.impact)] ? props.selection?.impact + " - " + props.impacts[parseInt(props.selection?.impact)].name : props.selection?.impact}
                            </div>
                        </div>
                    </li>
                    <li className="EditableListItem">
                        <div style={{ width: '40%', textAlign: 'left' }}>
                            {t('models.risk.fields.likelihood')}
                        </div>
                        <div>
                            <div className={[isLikehoodBetter ? "positive" : "", isLikehoodWorse ? "negative" : ""].join(" ")}>
                                {props.likelihoods[parseInt(props.selection?.likelihood)] ? props.selection?.likelihood + " - " + props.likelihoods[parseInt(props.selection?.likelihood)].name : props.selection?.likelihood}
                            </div>
                        </div>
                    </li>
                    {props.selection?.description && (
                        <li className="EditableListItem">
                            <div>{props.selection?.description}</div>
                        </li>
                    )}
                </ul>

                {!props.scenario && (
                    <div className="Actions mt-2 mb-2 pl-1 text-center ml-auto mr-auto">
                        <ActionButton
                            className="small ml-05"
                            tooltip={t('models.risk.actions.edit')}
                            onClick={() => props.onEdit(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>

                        {false && <ActionButton
                            className="small"
                            tooltip={t('models.risk.actions.clone')}
                            onClick={() => props.onClone(props.selection)}
                        >
                            <FontAwesomeIcon icon={faClone} />
                        </ActionButton>}

                        <ActionButton
                            className="small"
                            tooltip={t('models.risk.actions.delete')}
                            onClick={() => props.onDelete(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </ActionButton>
                    </div>
                )}

                {!props.selection?.isOpportunity && (
                    <div className="d-flex flex-column flex-grow mb-1 mt-3">
                        <div className="text-left mb-05">
                            {t('models.risk.fields.opportunity.name')}
                        </div>
                        <div className="text-left">
                            {opportunities && opportunities.length ? (
                                sortBy(opportunities, 'name').map(
                                    (o: any, index) => {

                                        return (
                                            <Tag
                                                key={index}
                                                onClick={async () => {
                                                    props.onSetRiskOpportunity(props.selection?.id, props.selection?.matchingOpportunityId !== o.id ? o.id : null);
                                                }}
                                                className={[
                                                    'ImpactedIndicator tag',
                                                    props.selection?.matchingOpportunityId === o.id
                                                        ? 'selected'
                                                        : 'unselected'
                                                ].join(' ')}
                                            >
                                                {o.matchingOpportunityId}
                                                {o.name}
                                            </Tag>
                                        );
                                    }
                                )
                            ) : (
                                <div
                                    className="text-center"
                                    style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                                >
                                    {t('models.indicator.no_x')}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {(
                    <div className="d-flex flex-column flex-grow mt-2 mb-4">
                        <div className="text-left mb-05">
                            {t('models.item.fields.indicators.name')}
                        </div>
                        <div className="text-left">
                            {indicators && indicators.length ? (
                                sortBy(indicators, 'name').map(
                                    (i: any, index) => {
                                        const impactedIndicator =
                                            getImpactingIndicatorDetails(props.selection?.impactedIndicators || [], i.id);

                                        return (
                                            <Tag
                                                key={index}
                                                onClick={async () => {

                                                    if (
                                                        !impactedIndicator ||
                                                        !impactedIndicator.id
                                                    ) {
                                                        props.createIndicatorImpact(
                                                            props.selection?.id,
                                                            i.id
                                                        );
                                                    } else if (
                                                        impactedIndicator.strength <
                                                        3
                                                    ) {
                                                        props.updateImpactStrength(
                                                            impactedIndicator,
                                                            impactedIndicator.strength +
                                                            1
                                                        );
                                                    } else {
                                                        props.deleteIndicatorImpact(
                                                            impactedIndicator.id,
                                                            props.selection?.id
                                                        );
                                                    }
                                                }}
                                                className={[
                                                    'ImpactedIndicator tag',
                                                    isImpactingIndicator(indexedIndicators, props.selection?.impactedIndicators || [], i.id)
                                                        ? 'selected'
                                                        : 'unselected'
                                                ].join(' ')}
                                            >
                                                <div
                                                    className={
                                                        'Trend v' + i.trend
                                                    }
                                                    style={
                                                        i.trend == '50'
                                                            ? {}
                                                            : {
                                                                background:
                                                                    getScoreColor(
                                                                        i.trend
                                                                    )
                                                            }
                                                    }
                                                ></div>
                                                {i.name}

                                                {impactedIndicator ? (
                                                    <Weight onClick={() => { }}>
                                                        {
                                                            impactedIndicator.strength
                                                        }
                                                    </Weight>
                                                ) : null}
                                            </Tag>
                                        );
                                    }
                                )
                            ) : (
                                <div
                                    className="text-center"
                                    style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                                >
                                    {t('models.indicator.no_x')}
                                </div>
                            )}
                        </div>
                    </div>
                )}


            </div>
        </div >
    );
}