import React, { useRef, useEffect } from 'react';

import { keyBy } from 'lodash';
import { isItemInactive } from 'utils/rules';
import { indicatorsMargin as baseMargin, circleDx, circleDy, circlePlacementDx, circlePlacementDy, circleSpacer, textDx, textDy } from '../RiskScatterFullChart.functions';
import { getStakeholderScoreForScenarioDetailsColor } from 'utils/ranking';

const isSelected = (pointsList: string[], pointId) => {
    if (!pointsList) return false;
    return pointsList.find(p => p.id == pointId);
}

const sizes = {
    "0": 1,
    "low": 3,
    "medium": 5,
    "high": 7
}

const adaptScore = (stakeholder) => {
    const score = getStakeholderScoreForScenarioDetailsColor(stakeholder);

    if (score === null) return null

    return parseInt(score.toFixed(0))
}

const getAllInitials = (name: string) => {
    const clearedName = name.replace(/[^a-zA-Z0-9]/g, ' ').replace(/(\s)+/, ' ')
    return clearedName.split(' ').slice(0, 2).map((n) => (n[0] ? n[0].toUpperCase() : '') + (n[1] ? n[1] : '') + (n[2] ? n[2] : '')).join('')
}

export const StakeholdersPointsCloud = (props: any) => {

    const hasSomeElementsSelected = props.isSelectedMode || props.selectedPoints.length > 0

    const { data } = props;

    // Count the number of risks that have the same likelihood and impact
    const pointsCount = data.reduce((acc, stakeholder) => {
        const score = adaptScore(stakeholder);
        const key = score
        acc[key] = (acc[key] || 0) + 1;
        return acc;
    }, {});

    const similarStakeholders = keyBy((data || []).map(r => ({
        ...r,
        similarCount: pointsCount[adaptScore(r)] - 1,
        index: data.filter(fr => adaptScore(fr) == adaptScore(r))
            .findIndex(d => d.id === r.id)
    })), "id");

    const margin = { top: 30, right: 30, bottom: 40, left: 40 },
        width = props.width - margin.left - margin.right

    return (

        <g className={`wrapper ${hasSomeElementsSelected ? "selectionMode" : ""}`} >

            {
                data.map((d, index) => {

                    const score = adaptScore(d);
                    const originalScore = adaptScore(d.o || d);
                    const isWorse = score < originalScore && score !== null
                    const isBetter = score > originalScore && score !== null

                    const selected = isSelected(props.selectedPoints, d.id)

                    return <g key={d.id}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (props.onClick) props.onClick(d)
                        }}
                        onMouseOver={() => {
                            if (props.onHover) props.onHover(d)
                        }}
                        onMouseOut={() => {
                            if (props.onHover) props.onHover(null)
                        }}
                        style={{
                            transform: `translate(${props.x(score)}px, 0)`,
                            transition: "all 2.5s"
                        }}
                        className={`stakeholder ${selected ? "selected" : ""}`}
                    >
                        <circle
                            className="risk-circle"
                            cx={circleSpacer(similarStakeholders[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDx(d, similarStakeholders)}
                            cy={circleSpacer(similarStakeholders[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDy(d, similarStakeholders)}
                            r={sizes[(d.impact || d.o?.impact)]}
                            fill={selected ? "orange" : isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" :
                                    "#000000"
                            }
                            strokeWidth={selected ? "4" : isWorse ? "6" :
                                isBetter ? "6" :
                                    "6"
                            }
                            stroke={selected ? "orange" : isWorse && d.isOpportunity ? "#00000044" : isWorse && !d.isOpportunity ? "#FFFFFF55" :
                                isBetter && d.isOpportunity ? "#FFFFFF55" : isBetter && !d.isOpportunity ? "#00000044" :
                                    "#00000001"
                            }

                            style={{
                                transition: selected ? "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s" : "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                        >

                        </circle>

                        <circle
                            className="risk-circle"
                            cx={circleSpacer(similarStakeholders[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDx(d, similarStakeholders)}
                            cy={circleSpacer(similarStakeholders[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDy(d, similarStakeholders)}
                            r={sizes[(d.impact || d.o?.impact)]}
                            fill={isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" :
                                    "#000000"
                            }
                            strokeWidth={isWorse ? "1" :
                                isBetter ? "1" :
                                    "6"
                            }
                            stroke={isWorse ? "#FFFFFF" :
                                isBetter ? "#FFFFFF" :
                                    "#00000001"
                            }
                            dx={circleDx(d, similarStakeholders, props.width, baseMargin)}
                            dy={circleDy(d, similarStakeholders, props.width, baseMargin)}
                            style={{
                                transition: selected ? "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, stroke-width 2.5s, stroke 2.5s" : "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"

                            }}
                        >

                        </circle>


                        <text
                            className="risk-label"
                            x={0}
                            y={0}
                            dx={textDx(d, similarStakeholders, props.width, baseMargin)}
                            dy={textDy(d, similarStakeholders, props.width, baseMargin)}
                            fill={isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" : "#00000077"}
                            strokeWidth={isWorse ? "#FFFFFF" :
                                isBetter ? "#FFFFFF" :
                                    "6"
                            }
                            stroke={isWorse ? "#FFFFFF55" :
                                isBetter ? "#FFFFFF55" :
                                    "#00000001"
                            }
                            style={{
                                fontSize: "12px",
                                textAnchor: Math.abs(circlePlacementDx(d, similarStakeholders) * 10) < 0.1 ? "middle" :
                                    circlePlacementDx(d, similarStakeholders) < 0 ? "end" :
                                        circlePlacementDx(d, similarStakeholders) > 0 ? "start" : "middle",
                                alignmentBaseline: "middle",
                                transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                            transform='translate(0, -10)'

                        >
                            {getAllInitials(d.name)}
                            <circle
                                r={2.5}
                                fill="#000000"
                            ></circle>
                        </text>

                        <text
                            className="risk-label"
                            x={0}
                            y={0}
                            dx={textDx(d, similarStakeholders, props.width, baseMargin)}
                            dy={textDy(d, similarStakeholders, props.width, baseMargin)}
                            fill={"#000000"}
                            style={{
                                fontSize: "12px",
                                textAnchor: Math.abs(circlePlacementDx(d, similarStakeholders) * 10) < 0.1 ? "middle" :
                                    circlePlacementDx(d, similarStakeholders) < 0 ? "end" :
                                        circlePlacementDx(d, similarStakeholders) > 0 ? "start" : "middle",
                                alignmentBaseline: "middle",
                                transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                            transform='translate(0, -10)'

                        >
                            {getAllInitials(d.name)}
                            <circle
                                r={2.5}
                                fill="#000000"
                            ></circle>
                        </text>



                    </g>

                })
            }
        </g >





    );
};
