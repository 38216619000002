import projects from './projects.js';
import navigator from './navigator.js';
import indicators from './indicators.js';
import constraints from './constraints.js';
import scenarios from './scenarios.js';
import fishbone from './fishbone.js';
import risks from './risks.js';

export default {
	projects,
	navigator,
	indicators,
	constraints,
	scenarios,
	fishbone,
	risks
};
