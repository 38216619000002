import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';


import { keyBy } from 'lodash';
import { isRiskOrOpportunityBetter, isRiskOrOpportunityWorse } from 'utils/rules';

import { baseMargin, circleDx, circleDy, circlePlacementDx, circlePlacementDy, circleSpacer, textDx, textDy } from '../RiskScatterFullChart.functions';

const isSelected = (pointsList: string[], pointId) => {
    if (!pointsList) return false;
    return pointsList.find(p => p.id == pointId);
}

const getAllInitials = (name: string) => {
    const clearedName = name.replace(/[^a-zA-Z0-9]/g, ' ').replace(/(\s)+/, ' ')
    return clearedName.split(' ').slice(0, 2).map((n) => (n[0] ? n[0].toUpperCase() : '') + (n[1] ? n[1] : '')).join('')
}

export const PointsCloud = (props: any) => {

    const hasSomeElementsSelected = props.isSelectedMode || props.selectedPoints.length > 0

    const { data, isRiskView } = props;

    // Count the number of risks that have the same likelihood and impact
    const riskCount = data.reduce((acc, risk) => {
        const key = "" + risk.impact + "-" + risk.likelihood;
        acc[key] = (acc[key] || 0) + 1;
        return acc;
    }, {});

    const similarRisks = keyBy((data || []).map(r => ({
        ...r,
        similarCount: riskCount["" + r.impact + "-" + r.likelihood] - 1,
        index: data.filter(fr => fr.impact === r.impact && fr.likelihood === r.likelihood)
            .findIndex(d => d.id === r.id)
    })), "id");

    const margin = { top: 30, right: 30, bottom: 40, left: 40 },
        width = props.width - margin.left - margin.right

    return (

        <g className={`wrapper ${hasSomeElementsSelected ? "selectionMode" : ""}`} >

            {
                props.data.map((d, index) => {

                    const isWorse = isRiskOrOpportunityWorse(d);
                    const isBetter = isRiskOrOpportunityBetter(d);
                    const selected = isSelected(props.selectedPoints, d.id)

                    return <g key={d.id}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (props.onClick) props.onClick(d)
                        }}
                        onMouseOver={() => {
                            if (props.onHover) props.onHover(d)
                        }}
                        onMouseOut={() => {
                            if (props.onHover) props.onHover(null)
                        }}
                        style={{
                            transform: `translate(${props.x(d.impact)}px, ${props.y(d.likelihood)}px)`,
                            transition: "all 2.5s"
                        }}
                        className={`stakeholder ${selected ? "selected" : ""}`}
                    >
                        <circle
                            className="risk-circle"
                            cx={circleSpacer(similarRisks[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDx(d, similarRisks)}
                            cy={circleSpacer(similarRisks[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDy(d, similarRisks)}
                            r={4}
                            fill={isSelected(props.selectedPoints, d.id) ? "orange" : isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" :
                                    "#000000"
                            }
                            strokeWidth={isSelected(props.selectedPoints, d.id) ? "4" : isWorse ? "6" :
                                isBetter ? "6" :
                                    "6"
                            }
                            stroke={isSelected(props.selectedPoints, d.id) ? "orange" : isWorse && d.isOpportunity ? "#00000044" : isWorse && !d.isOpportunity ? "#FFFFFF55" :
                                isBetter && d.isOpportunity ? "#FFFFFF55" : isBetter && !d.isOpportunity ? "#00000044" :
                                    "#00000001"
                            }
                            dx={circleDx(d, similarRisks, props.width, baseMargin)}
                            dy={circleDy(d, similarRisks, props.width, baseMargin)}
                            style={{
                                transition: isSelected(props.selectedPoints, d.id) ? "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, stroke-width 2.5s, stroke 2.5s" : "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"

                            }}
                        >

                        </circle>

                        <circle
                            className="risk-circle"
                            cx={circleSpacer(similarRisks[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDx(d, similarRisks)}
                            cy={circleSpacer(similarRisks[d.id].similarCount) * (props.width * baseMargin) * circlePlacementDy(d, similarRisks)}
                            r={4}
                            fill={props.selectedPoint && props.selectedPoint.id == d.id ? "orange" : isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" :
                                    "#000000"
                            }
                            strokeWidth={isWorse ? "1" :
                                isBetter ? "1" :
                                    "6"
                            }
                            stroke={isWorse ? "#FFFFFF" :
                                isBetter ? "#FFFFFF" :
                                    "#00000001"
                            }
                            dx={circleDx(d, similarRisks, props.width, baseMargin)}
                            dy={circleDy(d, similarRisks, props.width, baseMargin)}
                            style={{
                                transition: isSelected(props.selectedPoints, d.id) ? "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, stroke-width 2.5s, stroke 2.5s" : "dx 2.5s, dy 2.5s, cx 2.5s, cy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"

                            }}
                        >

                        </circle>


                        <text
                            className="risk-label"
                            x={0}
                            y={0}
                            dx={textDx(d, similarRisks, props.width, baseMargin)}
                            dy={textDy(d, similarRisks, props.width, baseMargin)}
                            fill={isWorse ? "#E70549" :
                                isBetter ? "#24CCB8" : "#00000077"}
                            strokeWidth={isWorse ? "#FFFFFF" :
                                isBetter ? "#FFFFFF" :
                                    "6"
                            }
                            stroke={isWorse ? "#FFFFFF55" :
                                isBetter ? "#FFFFFF55" :
                                    "#00000001"
                            }
                            style={{
                                fontSize: "12px",
                                textAnchor: Math.abs(circlePlacementDx(d, similarRisks) * 10) < 0.1 ? "middle" :
                                    circlePlacementDx(d, similarRisks) < 0 ? "end" :
                                        circlePlacementDx(d, similarRisks) > 0 ? "start" : "middle",
                                alignmentBaseline: "middle",
                                transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                            transform='translate(0, -10)'

                        >
                            {d.reference || getAllInitials(d.name)}
                            <circle
                                r={2.5}
                                fill="#000000"
                            ></circle>
                        </text>

                        <text
                            className="risk-label"
                            x={0}
                            y={0}
                            dx={textDx(d, similarRisks, props.width, baseMargin)}
                            dy={textDy(d, similarRisks, props.width, baseMargin)}
                            fill={"#000000"}
                            style={{
                                fontSize: "12px",
                                textAnchor: Math.abs(circlePlacementDx(d, similarRisks) * 10) < 0.1 ? "middle" :
                                    circlePlacementDx(d, similarRisks) < 0 ? "end" :
                                        circlePlacementDx(d, similarRisks) > 0 ? "start" : "middle",
                                alignmentBaseline: "middle",
                                transition: "dx 2.5s, dy 2.5s, fill 2.5s, stroke-width 2.5s, stroke 2.5s"
                            }}
                            transform='translate(0, -10)'

                        >
                            {d.reference || getAllInitials(d.name)}
                            <circle
                                r={2.5}
                                fill="#000000"
                            ></circle>
                        </text>



                    </g>

                })
            }
        </g >





    );
};
