import { keyBy, sortBy, find, isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Button, Tag, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faClone, faClose, faEdit, faLink, faTrash } from '@fortawesome/pro-light-svg-icons';

import ActionButton from 'components/action/ActionButton';

import { itemActiveness } from 'utils/ranking';

import Weight from 'components/controls/Weight';
import { getScoreColor } from 'utils/ranking';
import { isItemInactive } from 'utils/rules';
import RisksTab from './RisksTab';
import TagsTab from './TagsTab';
import { useStakeholderController } from 'hooks/useStakeholderController';

import './GeneralTab.sass';

// Props
interface GeneralTabProps {
	selection: any;
	indicators: any;
	constraints: any;
	onEdit: any;
	onClone: any;
	onDelete: any;
	scenario: any;
	displayConstraints: boolean;
	displayIndicators: boolean;
	onChangeItemTags: Function;
	onCreateTag: Function;
	tags: any;

	setPreferences: Function;
	areConstraintsVisible: boolean;
	areIndicatorsVisible: boolean;
	areRisksVisible: boolean;
	areOpportunitiesVisible: boolean;

}

export const GeneralTab = (props: GeneralTabProps) => {
	const { t } = useTranslation();

	const { displayTabs = true, displayTitle = false } = props;

	const indexedIndicators = keyBy(props.indicators, 'id');

	const isImpactingIndicator = (indicatorId: string) => {
		if (
			indexedIndicators[indicatorId] &&
			props.selection?.impactedIndicators
				?.map((ii: any) => ii.indicatorId)
				.includes(indicatorId)
		)
			return true;
		return false;
	};

	const getImpactingIndicatorDetails = (indicatorId: string) => {
		return find(
			props.selection?.impactedIndicators || [],
			(ii: any) => ii.indicatorId == indicatorId
		);
	};

	// Stakeholder impacts
	const { createIndicatorImpact, updateIndicatorImpactStrength, deleteIndicatorImpact, createConstraintImpact, updateConstraintImpactStrength, deleteConstraintImpact } = useStakeholderController();

	const indexedConstraints = keyBy(props.constraints, 'id');

	const isImpactingConstraint = (constraintId: string) => {
		if (
			indexedConstraints[constraintId] &&
			props.selection?.impactedConstraints
				?.map((ii: any) => ii.constraintId)
				.includes(constraintId)
		)
			return true;
		return false;
	};

	const getImpactingConstraintDetails = (constraintId: string) => {
		return find(
			props.selection?.impactedConstraints || [],
			(ii: any) => ii.constraintId == constraintId
		);
	};

	return (
		<>
			<div className="GeneralTab d-flex flex-column flex-grow">

				{displayTitle && <>
					<div className="d-flex flex-row align-items-start mb-1">
						<div className="w-100 text-left">
							<div className="title">{props.selection?.name}</div>
							<small>{t("models.item.name")}</small>
						</div>
						<Tooltip title={t('actions.close')} placement='left'>
							<Button
								shape="circle"
								className="mini ml-auto"
								tooltip={t('models.item.actions.edit')}
								style={{ marginRight: '-0.9rem' }}
								onClick={props.onClose}
							>
								<FontAwesomeIcon icon={faClose} />
							</Button></Tooltip>
					</div>

					<ul>
						{props.selection?.description && (
							<li className="EditableListItem">
								<div>{props.selection?.description}</div>
							</li>
						)}
					</ul>
				</>}


				<div className="text-left mt-2">
					{(props.selection?.tags || []).map((t, index) => (
						<Tag
							color={'#363D4D'}
							style={{ color: '#A6B9C8' }}
							className="tag"
						>
							{t}
						</Tag>
					))}
				</div>

				<ul>
					<li className="EditableListItem">
						<div style={{ width: '35%', textAlign: 'left' }}>
							Engagement
						</div>
						<div>
							{props.selection?.impact
								? {
									low: 'Low',
									medium: 'Medium',
									high: 'High',
									undefined: 'Inactive / Neutral'
								}[
								itemActiveness(
									props.selection?.x,
									props.selection?.y
								)
								]
								: null}{' '}
							{!props.selection || isItemInactive(props.selection)
								? ''
								: props.selection?.y > 0
									? 'positive'
									: 'negative'}
						</div>
					</li>

					<li className="EditableListItem">
						<div style={{ width: '35%', textAlign: 'left' }}>
							Importance
						</div>
						<div>
							{props.selection?.impact
								? {
									low: 'Low',
									medium: 'Medium',
									high: 'High'
								}[props.selection.impact]
								: 'Low'}
						</div>
					</li>

					<li className="EditableListItem">
						<div style={{ width: '35%', textAlign: 'left' }}>
							Trust
						</div>
						<div>
							{props.selection?.trend
								? {
									green: 'Positive',
									orange: 'At risk',
									neutral: 'Neutral',
									red: 'Negative'
								}[props.selection?.trend]
								: 'Neutral'}
						</div>
					</li>
					{props.selection?.description && (
						<li className="EditableListItem">
							<div>{props.selection?.description}</div>
						</li>
					)}
				</ul>

				{!props.scenario && (
					<div className="Actions mt-2 mb-1 text-center ml-auto mr-auto">
						{isFunction(props.onEdit) && <ActionButton
							className="small ml-05"
							tooltip={t('models.item.actions.edit')}
							onClick={() => props.onEdit(props.selection.id)}
						>
							<FontAwesomeIcon icon={faEdit} />
						</ActionButton>}

						{isFunction(props.onCreateLink) && <ActionButton
							className="small"
							tooltip={t('models.item_link.actions.create_new')}
							onClick={props.onCreateLink}
						>
							<FontAwesomeIcon icon={faLink} />
						</ActionButton>}

						{isFunction(props.onClone) &&
							<ActionButton
								className="small"
								tooltip={t('models.item.actions.clone')}
								onClick={() => props.onClone(props.selection)}
							>
								<FontAwesomeIcon icon={faClone} />
							</ActionButton>}

						{isFunction(props.onDelete) && <ActionButton
							className="small"
							tooltip={t('models.item.actions.delete')}
							onClick={() => props.onDelete(props.selection.id)}
						>
							<FontAwesomeIcon icon={faTrash} />
						</ActionButton>
						}
					</div>
				)}

				{displayTabs && <TagsTab
					key={
						props.selection?.id
					}
					{...props}
					onChangeItemTags={props.onChangeItemTags}
					selection={props.selection}
					allTags={props.tags}
					onCreateTag={props.onCreateTag}
					scenario={props.scenario}
				/>}

				{props.displayConstraints && (
					<div className="d-flex flex-column flex-grow mt-4">
						<div className="text-left mb-05 d-flex align-items-center">
							{t('models.item.fields.constraints.name')}

							<Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}} onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								props.setPreferences(
									'pages',
									'navigator.constraints',
									!props.areConstraintsVisible
								);
							}}>
								<FontAwesomeIcon
									icon={!props.areConstraintsVisible ? faChevronDown : faChevronUp}
								></FontAwesomeIcon>
							</Button>

						</div>
						{props.areConstraintsVisible && <div className="text-left">
							{props.constraints && props.constraints.length ? (
								sortBy(props.constraints || [], 'name').map(
									(i: any) => {
										const impactedConstraint =
											getImpactingConstraintDetails(i.id);

										return (
											<Tag
												key={i.id}
												onClick={() => {
													console.log(
														'impactedConstraint',
														impactedConstraint
													);

													if (props.scenario)
														return true;

													if (
														!impactedConstraint ||
														!impactedConstraint.id
													) {
														createConstraintImpact(
															props.selection?.id,
															i.id
														);
													} else if (
														impactedConstraint.strength <
														3
													) {
														updateConstraintImpactStrength(
															impactedConstraint,
															impactedConstraint.strength +
															1
														);
													} else {
														deleteConstraintImpact(
															impactedConstraint.id,
															props.selection?.id
														);
													}
												}}
												className={[
													'ImpactedConstraint tag',
													isImpactingConstraint(i.id)
														? 'selected'
														: 'unselected'
												].join(" ")}
											>
												<div
													className={
														'Trend v' + i.trend
													}
													style={
														i.trend == '50'
															? {}
															: {
																background:
																	getScoreColor(
																		i.trend
																	)
															}
													}
												></div>
												{i.name}

												{impactedConstraint ? (
													<Weight onClick={() => { }}>
														{
															impactedConstraint.strength
														}
													</Weight>
												) : null}
											</Tag>
										);
									}
								)
							) : (
								<div
									className="text-center"
									style={{ color: 'rgba(46, 160, 223, 0.3)' }}
								>
									{t('models.constraint.no_x')}
								</div>
							)}
						</div>}
					</div>
				)}

				{props.displayIndicators && (
					<div className="d-flex flex-column flex-grow mt-4">
						<div className="text-left mb-05 d-flex align-items-center">
							{t('models.item.fields.indicators.name')}

							{isFunction(props.setPreferences) && <Button shape="circle" className="mini discreet ml-auto" onDoubleClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}} onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								props.setPreferences(
									'pages',
									'navigator.indicators',
									!props.areIndicatorsVisible
								);
							}}>
								<FontAwesomeIcon
									icon={!props.areIndicatorsVisible ? faChevronDown : faChevronUp}
								></FontAwesomeIcon>
							</Button>}

						</div>
						{(!isFunction(props.setPreferences) || props.areIndicatorsVisible) && <div className="text-left">
							{props.indicators && props.indicators.length ? (
								sortBy(props.indicators || [], 'name').map(
									(i: any, index) => {
										const impactedIndicator =
											getImpactingIndicatorDetails(i.id);

										return (
											<Tag
												key={index}
												onClick={() => {
													console.log(
														'impactedIndicator',
														impactedIndicator
													);

													if (props.scenario)
														return true;

													if (
														!impactedIndicator ||
														!impactedIndicator.id
													) {
														createIndicatorImpact(
															props.selection?.id,
															i.id
														);
													} else if (
														impactedIndicator.strength <
														3
													) {
														updateIndicatorImpactStrength(
															impactedIndicator,
															impactedIndicator.strength +
															1
														);
													} else {
														deleteIndicatorImpact(
															impactedIndicator.id,
															props.selection?.id
														);
													}
												}}
												className={[
													'ImpactedIndicator tag',
													isImpactingIndicator(i.id)
														? 'selected'
														: 'unselected'
												].join(' ')}
											>
												<div
													className={
														'Trend v' + i.trend
													}
													style={
														i.trend == '50'
															? {}
															: {
																background:
																	getScoreColor(
																		i.trend
																	)
															}
													}
												></div>
												{i.name}

												{impactedIndicator ? (
													<Weight onClick={() => { }}>
														{
															impactedIndicator.strength
														}
													</Weight>
												) : null}
											</Tag>
										);
									}
								)
							) : (
								<div
									className="text-center"
									style={{ color: 'rgba(46, 160, 223, 0.3)' }}
								>
									{t('models.indicator.no_x')}
								</div>
							)}
						</div>}
					</div>
				)}

				<RisksTab
					key={
						props.selection?.id +
						'-' +
						props.selection?.impactedRisks
							.length
					}
					{...props}
				></RisksTab>
			</div>
		</>
	);
};

export default GeneralTab;
