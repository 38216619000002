import React, { Component } from 'react';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartNetwork, faChess, faPlus, faShieldExclamation, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Modal, Button, Form, Input, Tabs, Empty, Card } from 'antd';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

import "./ProjectModal.sass";

interface ProjectModalValues {
	name: string;
	reference: string;
	description: string;
	descriptionLong: string;
	tags: any[];
	riskCategories: any[];
	likelihoods: string;
	impacts: string;
	type: string | null;
}
interface ProjectModalProps {
	id?: string;
	name?: string;
	values?: ProjectModalValues;
	onSave: (values: any) => void;
	onChange: (id: string, values: any) => void;
	onHide: () => void;
	authorizedTypes: {
		pro: boolean;
		stakeholders: boolean;
		risks: boolean;
	};
}

export const ProjectModal = (props: ProjectModalProps) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const [type, setType] = React.useState<any | null>(props.values?.type);

	console.log('ProjectModal', props);

	const handleFormSubmit = () => {
		console.log('handleFormSubmit');
		form.validateFields()
			.then((values) => {
				console.log('validated', values);
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				style={{ marginRight: 'auto' }}
				onClick={() => {
					props.onHide();
				}}
			>
				Cancel
			</Button>
			{type && <Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>}
		</div>
	);

	const likelihoods = props.values?.likelihoods && props.values?.likelihoods.length > 0 ? props.values?.likelihoods : [
		{ id: '1', name: 'Highly Unlikely' },
		{ id: '2', name: 'Unlikely' },
		{ id: '3', name: 'Possible' },
		{ id: '4', name: 'Likely' },
		{ id: '5', name: 'Almost Certain' }
	]
	const impacts = props.values?.impacts && props.values?.impacts.length > 0 ? props.values?.impacts : [
		{ id: '1', name: 'Negligible' },
		{ id: '2', name: 'Minor' },
		{ id: '3', name: 'Moderate' },
		{ id: '4', name: 'Major' },
		{ id: '5', name: 'Extreme' }
	]
	const riskCategories = props.values?.riskCategories && props.values?.riskCategories.length > 0 ? props.values?.riskCategories : [
		{ id: '1', name: 'Existential' },
		{ id: '2', name: 'Strategic' },
		{ id: '3', name: 'Operational' }
	]

	const content = (<Form
		{...formLayout}
		form={form}
		onFinish={(values) => {
			props.onChange(props.id, values);
			props.onHide();
		}}
		initialValues={{
			...props.values,
			likelihoods,
			impacts,
			riskCategories,
			type
		}}
	>
		<Tabs>
			<Tabs.TabPane tab="General" key="general">

				<div className='d-flex w-100'>
					<Form.Item name="type">
						<Input type="hidden" />
					</Form.Item>
					<Form.Item
						name="reference"
						label="Reference"
						className="mr-1"
						style={{ width: '20%' }}
						rules={[
							{
								max: 5,
								message:
									t("messages.form.max_length_exceeded_error", { length: 5 })
							}
						]}
					>
						<Input
							autoComplete='off'
							count={{
								show: true,
								max: 5,
							}}
						></Input>
					</Form.Item>

					{props.name === undefined && (
						<Form.Item
							name="name"
							required={true}
							label={t('models.project.fields.name')}
							style={{ width: '80%' }}
							rules={[
								{
									max: 50,
									message:
										t("messages.form.max_length_exceeded_error", { length: 50 })
								},
								{
									required: true,
									message: t("messages.form.required_error")
								}
							]}
						>
							<Input
								autoComplete='off'
								placeholder={t(
									'models.project.fields.name_explanation'
								)}
								count={{
									show: true,
									max: 50,
								}}
							></Input>
						</Form.Item>
					)}
				</div>
				<Form.Item
					name="description"
					label={t('models.project.fields.description')}
					rules={[
						{
							max: 300,
							message:
								t("messages.form.max_length_exceeded_error", { length: 300 })
						}
					]}
				>
					<Input.TextArea
						rows={7}
						placeholder={t(
							'models.project.fields.description_explanation'
						)}
						count={{
							show: true,
							max: 300,
						}}
					></Input.TextArea>
				</Form.Item>

				<Form.Item
					name="descriptionLong"
					label={t('models.project.fields.description_long')}
					rules={[
						{
							max: 600,
							message:
								t("messages.form.max_length_exceeded_error", { length: 600 })
						}
					]}
				>
					<Input.TextArea
						rows={12}
						placeholder={t(
							'models.project.fields.description_long_explanation'
						)}
						count={{
							show: true,
							max: 600,
						}}
					></Input.TextArea>
				</Form.Item>

			</Tabs.TabPane>

			{type !== "stakeholders" && <Tabs.TabPane tab="Risk management" key="risks">
				<div className="ant-col ant-col-24 ant-form-item-label mb-05">
					<label>
						{t('models.risk_category.name_plural')}
					</label>
				</div>
				<Form.List name="riskCategories">
					{(fields, { add, remove }) => {
						return (
							<div>

								{fields.map((field, index) => {
									return (
										<>
											<div
												key={index}
												className="d-flex mb-05"
											>
												<Button
													className="mr-1 mb-0"
													onClick={() =>
														remove(index)
													}
												>
													<FontAwesomeIcon
														icon={faTrash}
													/>
												</Button>

												<Form.Item
													name={[
														field.name,
														'id'
													]}
													noStyle
												>
													<Input type="hidden" />
												</Form.Item>

												<Form.Item
													name={[
														field.name,
														'name'
													]}
													className="mb-0 w-50"
													required={true}
													rules={[
														{
															max: 20,
															message:
																t("messages.form.max_length_exceeded_error", { length: 20 })
														},
														{
															required: true,
															message: t("messages.form.required_error")
														}
													]}
												>
													<Input placeholder={t('models.risk_category.fields.name')} count={{
														show: true,
														max: 20,
													}} />
												</Form.Item>
											</div>
											{false && (
												<div>
													<Form.List name="risk_sub_categories">
														{(
															fields2,
															{
																add,
																remove
															}
														) => {
															return (
																<div>
																	{fields2.map(
																		(
																			field,
																			index
																		) => {
																			return (
																				<div
																					key={
																						index
																					}
																					className="d-flex mb-05 ml-4"
																				>
																					<Button
																						className="mr-1 mb-0"
																						onClick={() =>
																							remove(
																								index
																							)
																						}
																					>
																						<FontAwesomeIcon
																							icon={
																								faTrash
																							}
																						/>
																					</Button>

																					<Form.Item
																						name={[
																							field.name,
																							'id'
																						]}
																						noStyle
																					>
																						<Input type="hidden" />
																					</Form.Item>

																					<Form.Item
																						name={[
																							field.name,
																							'name'
																						]}
																						className="mb-0"
																					>
																						<Input />
																					</Form.Item>
																				</div>
																			);
																		}
																	)}

																	<div className="d-flex mb-05 align-items-center ml-4">
																		<Button
																			onClick={() =>
																				add()
																			}
																			className="mb-0 mr-4"
																		>
																			<FontAwesomeIcon
																				icon={
																					faPlus
																				}
																				className="mr-1"
																			/>
																			{t(
																				'models.risk_category.actions.add_x'
																			)}
																		</Button>
																	</div>

																	{fields2.length ==
																		0
																		? t(
																			'models.risk_category.messages.no_x'
																		)
																		: null}
																</div>
															);
														}}
													</Form.List>
												</div>
											)}
										</>
									);
								})}

								{fields.length > 0 && <div className="d-flex mb-2 align-items-center">
									<Button
										onClick={() => add()}
										className="mb-0 mr-4"
									>
										<FontAwesomeIcon
											icon={faPlus}
										/>
									</Button>
								</div>}

								{fields.length == 0 && <Empty description={false}>
									{t('models.risk_category.messages.no_x')}.
									Click <Button onClick={() => add()} size='small'><FontAwesomeIcon icon={faPlus} /></Button> to create one
								</Empty>}

							</div>
						);
					}}
				</Form.List>

				<div className="ant-col ant-col-24 ant-form-item-label mt-4 mb-05">
					<label>
						{t('models.project.fields.impacts')}
					</label>
				</div>
				<Form.List name="impacts">
					{(fields, { add, remove }) => {
						return (
							<div>

								{(fields).map((field, index) => {
									return (
										<>
											<div
												key={index}
												className="d-flex mb-05"
											>
												<Button
													className="mr-1 mb-0"
													disabled={fields.length > index + 1 || index < 3}
													onClick={() =>
														remove(index)
													}
												>
													<FontAwesomeIcon
														icon={faTrash}
													/>
												</Button>

												<Form.Item
													name={[
														field.name,
														'id'
													]}
													noStyle
												>
													<Input defaultValue={index + 1} type="hidden" />
												</Form.Item>

												<div style={{ width: "20px" }} className="mr-1 mt-1">{index + 1}</div>

												<Form.Item
													name={[
														field.name,
														'name'
													]}
													className="mb-0 w-50"
												>
													<Input placeholder={t('models.risk.fields.impact_level')} />
												</Form.Item>
											</div>

										</>
									);
								})}

								<div className="d-flex mb-2 align-items-center">
									<Button
										onClick={() => add()}
										className="mb-0 mr-4"
									>
										<FontAwesomeIcon
											icon={faPlus}
										/>
									</Button>
								</div>


							</div>
						);
					}}
				</Form.List>

				<div className="ant-col ant-col-24 ant-form-item-label mt-4 mb-05">
					<label>
						{t('models.project.fields.likelihoods')}
					</label>
				</div>
				<Form.List name="likelihoods">
					{(fields, { add, remove }) => {
						return (
							<div>

								{(fields).map((field, index) => {
									return (
										<>
											<div
												key={index}
												className="d-flex mb-05"
											>
												<Button
													className="mr-1 mb-0"
													disabled={fields.length > index + 1 || index < 3}
													onClick={() =>
														remove(index)
													}
												>
													<FontAwesomeIcon
														icon={faTrash}
													/>
												</Button>

												<Form.Item
													name={[
														field.name,
														'id'
													]}
													noStyle
												>
													<Input defaultValue={index + 1} type="hidden" />
												</Form.Item>

												<div style={{ width: "20px" }} className="mr-1 mt-1">{index + 1}</div>

												<Form.Item
													name={[
														field.name,
														'name'
													]}
													className="mb-0 w-50"
												>
													<Input placeholder={t('models.risk.fields.likelihood_level')} />
												</Form.Item>
											</div>

										</>
									);
								})}

								<div className="d-flex mb-2 align-items-center">
									<Button
										onClick={() => add()}
										className="mb-0 mr-4"
									>
										<FontAwesomeIcon
											icon={faPlus}
										/>
									</Button>
								</div>


							</div>
						);
					}}
				</Form.List>


			</Tabs.TabPane>}
			<Tabs.TabPane tab={t('models.tag.name_plural')} key="tags">
				<Form.List name="tags">
					{(fields, { add, remove }) => {
						return (
							<div>
								{fields.map((field, index) => {
									return (
										<div
											key={index}
											className="d-flex mb-05"
										>
											<Button
												className="mr-1 mb-0"
												onClick={() =>
													remove(index)
												}
											>
												<FontAwesomeIcon
													icon={faTrash}
												/>
											</Button>

											<Form.Item
												name={[
													field.name,
													'id'
												]}
												noStyle
											>
												<Input type="hidden" />
											</Form.Item>

											<Form.Item
												name={[
													field.name,
													'name'
												]}
												className="mb-0"
											>
												<Input />
											</Form.Item>
										</div>
									);
								})}

								{fields.length > 0 && <div className="d-flex mb-2 align-items-center">
									<Button
										onClick={() => add()}
										className="mb-0 mr-4"
									>
										<FontAwesomeIcon
											icon={faPlus}
										/>
									</Button>
								</div>}

								{fields.length == 0 && <Empty description={false}>
									{t('models.tag.messages.no_x')}.
									Click <Button onClick={() => add()} size='small'><FontAwesomeIcon icon={faPlus} /></Button> to create one
								</Empty>}
							</div>
						);
					}}


				</Form.List>
			</Tabs.TabPane>
		</Tabs>
	</Form>)

	const contentWhenNotTyped = (<>
		<h3>What would you like to achieve?</h3>
		<div className='d-flex mt-4 mb-4'>
			<Card className={`ProjectType w-100 mb-2 mr-1 text-center ${props.authorizedTypes?.pro ? '' : 'disabled'}`} onClick={() => { if (!!props.authorizedTypes["pro"]) setType("pro") }}>
				<FontAwesomeIcon size={"4x"} color="#BBBBBB" icon={faChess} />
				<h4 className="mt-4">Strategy</h4>
				<div>
					Manage all aspects of your strategy
				</div>
				<div className="upgradeRequired">Upgrade required</div>
			</Card>
			<Card className={`ProjectType w-100 mb-2 mr-1 text-center ${props.authorizedTypes?.stakeholders ? '' : 'disabled'}`} onClick={() => { if (!!props.authorizedTypes["stakeholders"]) setType("stakeholders") }}>
				<FontAwesomeIcon size={"4x"} color="#BBBBBB" icon={faChartNetwork} />

				<h4 className="mt-4">Stakeholders</h4>
				<div>
					Align all the forces behind your goal
				</div>
				<div className="upgradeRequired">Upgrade required</div>
			</Card>
			<Card className={`ProjectType w-100 mb-2 mr-1 text-center ${props.authorizedTypes?.risks ? '' : 'disabled'}`} onClick={() => { if (!!props.authorizedTypes["risks"]) setType("risks") }}>
				<FontAwesomeIcon size={"4x"} color="#BBBBBB" icon={faShieldExclamation} />
				<h4 className="mt-4">Risks</h4>
				<div>
					Prepare for the unexpected and manage it
				</div>
				<div className="upgradeRequired">Upgrade required</div>
			</Card>

		</div>
	</>)

	/* Organize with tabs */
	return (
		<Modal
			open={true}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			width={'60%'}
			maskClosable={false}
			className='ProjectModal'
		>
			{type !== null ? content : contentWhenNotTyped}
		</Modal>
	);
};

export default ProjectModal;
