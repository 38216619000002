import React, { useCallback } from 'react';

import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';

import { sortBy, findIndex, pick, isObject, find } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import HeaderPortal from 'layout/header/HeaderPortal';
import { ConfirmModal } from 'components/Modal/ConfirmModal';

import {
	useCreateFishboneBranchMutation,
	useDeleteFishboneBranchMutation,
	useUpdateFishboneBranchMutation
} from 'graph/dist/generated';

import { FishboneBranch } from './FishboneBranch';
import { FishboneBody } from './FishboneBody';
import { FishboneTail } from './FishboneTail';
import { FishboneHead } from './FishboneHead';
import FishboneBranchEditionModal from 'components/Modal/FishboneBranchEditionModal';
import FishboneItemEditionModal from 'components/Modal/FishboneItemEditionModal';
import FishboneHeadEditionModal from 'components/Modal/FishboneHeadEditionModal';
import { useFishboneController } from 'hooks/useFishboneController';

import './Fishbone.sass';
import dayjs from 'dayjs';
import { getItemAlteredByScenario } from 'utils/rules';


export const Fishbone = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	let history = useHistory();
	const params: any = useParams();

	const [createFishboneBranch] = useCreateFishboneBranchMutation();
	const [updateFishboneBranch] = useUpdateFishboneBranchMutation();
	const [deleteFishboneBranch] = useDeleteFishboneBranchMutation();

	const { createFishboneItem, updateFishboneItem, deleteFishboneItem } = useFishboneController();

	const { fishboneBranchs = [] } = props;

	const { t } = useTranslation();

	// Help
	/*const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(null)
	const closeHelp = () => setVisibleHelpStep(null)
	const initialStep = getInitialStep(props.indicators?.length)
	const nextStep = getNextStep(visibleHelpStep, props.indicators?.length)
	const onNext = () => setVisibleHelpStep(nextStep, props.indicators?.length)
	const hasNext = !!nextStep*/

	const availableWidth = window.innerWidth * 0.9;
	const availableHeight = window.innerHeight * 0.75 - 100;

	const fishboneTailWidth = 60;
	const fishboneTailHeight = 100;

	// Create
	const handleCreateBranch = async () => {
		props.pushModal(
			<FishboneBranchEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					console.log('values', values);
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
						responsible: values.responsible,
						startOn:
							values.startOn && isObject(values.startOn)
								? values.startOn.format('YYYY-MM-DD')
								: values.startOn,
						endOn:
							values.endOn && isObject(values.endOn)
								? values.endOn.format('YYYY-MM-DD')
								: values.endOn
					};

					let result = await createFishboneBranch({
						variables,
						update(cache, { data: { createFishboneBranch } }) {
							cache.modify({
								id: `Project:${params.id}`,
								fields: {
									fishboneBranchs(fishboneBranchs = []) {
										const newFishboneBranch =
											cache.writeFragment({
												id:
													'FishboneBranch:' +
													createFishboneBranch.id,
												data: createFishboneBranch,
												fragment: gql`
													fragment FishboneBranchFragment on FishboneBranch {
														name
														projectId
														description
														responsible
														weight
													}
												`
											});

										return [
											...fishboneBranchs,
											newFishboneBranch
										];
									}
								}
							});
						},
						optimisticResponse: {
							createFishboneBranch: {
								id: 'temp-id',
								...variables
							}
						}
					});

					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					description: '',
					startOn: null,
					endOn: null,
					responsible: ''
				}}
			></FishboneBranchEditionModal>
		);
	};

	// Create
	const handleCreateItem = async (fishboneBranchId, event) => {

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		props.pushModal(
			<FishboneItemEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						fishboneBranchId: fishboneBranchId,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						completion: 0,
						responsible: values.responsible,
						description: values.description || '',
						startOn:
							values.startOn && isObject(values.startOn)
								? values.startOn.format('YYYY-MM-DD')
								: values.startOn,
						endOn:
							values.endOn && isObject(values.endOn)
								? values.endOn.format('YYYY-MM-DD')
								: values.endOn
					};

					let result = await createFishboneItem(fishboneBranchId, variables);

					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					trend: '',
					description: '',
					responsible: '',
					completion: 0,
					startOn: null,
					endOn: null
				}}
			></FishboneItemEditionModal>
		);
	};

	const handleUpdateBranch = async (id: string, values: Object) => {
		let index = findIndex(fishboneBranchs, (s: any) => s.id === id);

		console.log('handleUpdateBranch index', index, values);
		let newBranch = {
			...pick(fishboneBranchs[index], [
				'description',
				'name',
				'weight',
				'startOn',
				'endOn',
				'responsible'
			]),
			...pick(values, [
				'description',
				'name',
				'weight',
				'startOn',
				'endOn',
				'responsible'
			])
		};
		if (!newBranch.weight) newBranch.weight = 1;
		if (newBranch.startOn && isObject(newBranch.startOn))
			try {
				newBranch.startOn = newBranch.startOn.format('YYYY-MM-DD');
			} catch (e) { }
		if (newBranch.endOn && isObject(newBranch.endOn))
			try {
				newBranch.endOn = newBranch.endOn.format('YYYY-MM-DD');
			} catch (e) { }

		console.log('handleUpdateBranch', newBranch);

		const result = await updateFishboneBranch({
			variables: {
				id: id,
				...newBranch
			},
			update(cache, { data: { updateFishboneBranch } }) {
				cache.modify({
					id: `FishboneBranch:${id}`,
					fields: {
						name() {
							return updateFishboneBranch.name || newBranch.name;
						},
						description() {
							return (
								updateFishboneBranch.description ||
								newBranch.description
							);
						},
						weight() {
							return (
								updateFishboneBranch.weight || newBranch.weight
							);
						},
						responsible() {
							return (
								updateFishboneBranch.responsible ||
								newBranch.responsible
							);
						},
						completion() {
							return (
								updateFishboneBranch.completion ||
								newBranch.completion
							);
						},
						trend() {
							return (
								updateFishboneBranch.trend || newBranch.trend
							);
						},
						startOn() {
							return (
								updateFishboneBranch.startOn ||
								newBranch.startOn
							);
						},
						endOn() {
							return (
								updateFishboneBranch.endOn || newBranch.endOn
							);
						}
					}
				});
			},
			optimisticResponse: {
				updateFishboneBranch: {
					__typename: 'FishboneBranch',
					id,
					...newBranch
				}
			}
		});
	};

	const handleUpdateItem = async (
		fishboneBranchId: string,
		id: string,
		values: Object
	) => {
		console.log(
			'handleUpdateItem',
			fishboneBranchId,
			id,
			values,
			fishboneBranchs
		);

		let fishboneIndex = findIndex(
			fishboneBranchs,
			(s: any) => s.id === fishboneBranchId
		);
		let index = findIndex(
			fishboneBranchs[fishboneIndex].items,
			(s: any) => s.id === id
		);

		console.log('handleUpdateItem', "fishboneBranchId", fishboneBranchId, "id", id, "values", values);

		const result = await updateFishboneItem(id, fishboneBranchs[fishboneIndex].items, props.scenario, values);
	};

	const askDeleteConfirmation = async (
		id?: string,
		type: string,
		fishboneBranchId: string | undefined
	) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.' + type + '.messages.delete_confirmation')}
				t={t}
				onConfirm={() =>
					type == 'fishbone_branch'
						? handleDeleteBranch(id)
						: deleteFishboneItem(fishboneBranchId, id)
				}
				message={t(
					'models.' +
					type +
					'.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	// Delete
	const handleDeleteBranch = async (id: string | undefined) => {
		if (id == null) {
			return;
		}

		await deleteFishboneBranch({
			variables: { id: id },
			update(cache, { data: { deleteFishboneBranch } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						fishboneBranchs(
							existingFishboneBranchRef,
							{ readField }
						) {
							return existingFishboneBranchRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteFishboneBranch: true
			}
		});
	};

	const handleEditHead = () => {
		props.pushModal(
			<FishboneHeadEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={(values: Object) => {
					props.onUpdateProject(params.id, { ...props, ...values });
					props.popModal();
				}}
				id={editedItem?.id}
				values={{
					description: props?.description,
					fishboneDescription: props?.fishboneDescription,
					responsible: props?.responsible,
					fishboneStartOn: props?.fishboneStartOn
						? dayjs(props?.fishboneStartOn)
						: null,
					fishboneEndOn: props?.fishboneEndOn
						? dayjs(props?.fishboneEndOn)
						: null
				}}
			></FishboneHeadEditionModal>
		);
	};

	return (
		<div className="Fishbone pb-3">
			<HeaderPortal>
				{props.portalContent}{' '}
				<FontAwesomeIcon
					style={{
						marginLeft: 'auto',
						marginRight: '0.5rem',
						cursor: 'pointer'
					}}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			<h2>{t('pages.fishbone.title')}</h2>

			<h3 className="mb-4">{t('pages.fishbone.subtitle')}</h3>

			<svg
				width={availableWidth + 'px'}
				height={availableHeight + 'px'}
				viewBox={'0 0 ' + availableWidth + ' ' + availableHeight}
				style={{ overflow: 'visible' }}
			>
				<defs>
					<linearGradient id="redToGreen">
						<stop offset="0%" stopColor="#E70549" />
						<stop offset="100%" stopColor="#24CCB8" />
					</linearGradient>
				</defs>

				<FishboneBody
					leftOffset={fishboneTailWidth}
					width={availableWidth - fishboneTailWidth * 2}
					height={availableHeight}
					onCreateBranch={handleCreateBranch}
				>
					{sortBy(fishboneBranchs || [], [
						'startOn',
						'name'
					]).map((fb, index) => (
						<FishboneBranch
							key={fb.id}
							{...fb}
							points={fb.items.map((i) => props.scenario?.id ? getItemAlteredByScenario(i, props.scenario) : i)}
							onCreateItem={(e) => handleCreateItem(fb.id, e)}
							onUpdateItem={(id, values) =>
								handleUpdateItem(fb.id, id, values)
							}
							onEditBranch={(o) => setEditedItem(o)}
							onUpdateBranch={(id, values) =>
								handleUpdateBranch(id, values)
							}
							onEditItem={setEditedItem}
							scenario={props.scenario}
						></FishboneBranch>
					))}
				</FishboneBody>
				<g
					transform={
						'translate(0 ' +
						(availableHeight / 2 - fishboneTailHeight / 2) +
						')'
					}
				>
					<FishboneTail
						width={fishboneTailWidth}
						height={fishboneTailHeight}

					></FishboneTail>
				</g>
				<g
					transform={
						'translate(' +
						(availableWidth - fishboneTailWidth) +
						' ' +
						(availableHeight / 2 - (fishboneTailHeight * 0.8) / 2) +
						')'
					}
				>
					<FishboneHead
						branchs={fishboneBranchs.map(b => ({
							...b,
							items: b.items.map(i => props.scenario?.id ? getItemAlteredByScenario(i, props.scenario) : i)
						}))}

						{...props}
						width={fishboneTailWidth}
						t={t}
						height={fishboneTailHeight * 0.8}
						onClick={handleEditHead}
					></FishboneHead>
				</g>
			</svg>

			{
				editedItem && editedItem.__typename === 'FishboneBranch' && (
					<FishboneBranchEditionModal
						onHide={() => {
							setEditedItem(null);
						}}
						onDelete={() => {
							askDeleteConfirmation(editedItem.id, 'fishbone_branch');
							setEditedItem(null);
						}}
						onSave={(values: Object) => {
							console.log(
								'on save FishboneBranchEditionModal',
								values
							);
							handleUpdateBranch(editedItem.id, values);
							setEditedItem(null);
						}}
						minDate={props.fishboneStartOn}
						maxDate={props.fishboneEndOn}
						id={editedItem?.id}
						values={{
							name: editedItem?.name,
							weight: editedItem?.weight || 1,
							description: editedItem?.description || '',
							responsible: editedItem?.responsible,
							startOn: editedItem?.startOn
								? dayjs(editedItem?.startOn)
								: null,
							endOn: editedItem?.endOn
								? dayjs(editedItem?.endOn)
								: null
						}}
					></FishboneBranchEditionModal>
				)
			}

			{
				editedItem && editedItem.__typename === 'FishboneItem' && (
					<FishboneItemEditionModal
						onHide={() => {
							setEditedItem(null);
						}}
						onDelete={() => {
							askDeleteConfirmation(
								editedItem.id,
								'fishbone_item',
								editedItem.fishboneBranchId
							);
							setEditedItem(null);
						}}
						onSave={function (values: Object) {
							console.log('on save FishboneItemEditionModal', values);
							handleUpdateItem(
								editedItem.fishboneBranchId,
								editedItem.id,
								values
							);
							setEditedItem(null);
						}}
						id={editedItem?.id}
						minDate={
							find(
								fishboneBranchs,
								(b) => b.id === editedItem.fishboneBranchId
							)?.startOn
						}
						maxDate={
							find(
								fishboneBranchs,
								(b) => b.id === editedItem.fishboneBranchId
							)?.endOn
						}
						values={{
							name: editedItem?.name,
							weight: editedItem?.weight || 1,
							completion: editedItem?.completion,
							trend: editedItem?.trend,
							responsible: editedItem?.responsible,
							description: editedItem?.description || '',
							startOn: editedItem?.startOn
								? dayjs(editedItem?.startOn)
								: null,
							endOn: editedItem?.endOn
								? dayjs(editedItem?.endOn)
								: null
						}}
					></FishboneItemEditionModal>
				)
			}
		</div >
	);
};
