import dayjs from 'dayjs';

import { Modal, Button, Form, Input, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const StakeholderCommentModal = (props: any) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const handleFormSubmit = () => {
		console.log('handleFormSubmit');
		form.validateFields()
			.then((values) => {
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				onClick={() => {
					props.onDelete(props);
					props.onHide();
				}}
				danger={true}
				style={{ marginRight: 'auto' }}
			>
				Delete
			</Button>
			<Button
				onClick={() => {
					props.onHide();
				}}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	return (
		<Modal
			open={true}
			title="History"
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			maskClosable={false}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values);
					props.onHide();
				}}
				initialValues={{
					...props.values,
					commentedAt:
						props.values && props.values.commentedAt
							? dayjs(props.values.commentedAt)
							: dayjs()
				}}
			>
				<Form.Item name="description" label={t("models.item.fields.description")} rules={[
					{
						max: 300,
						message:
							t("messages.form.max_length_exceeded_error", { length: 300 })
					}
				]}>
					<Input.TextArea
						rows={5}
						count={{
							show: true,
							max: 300,
						}}></Input.TextArea>
				</Form.Item>
				<Form.Item name="commentedAt" label="Date">
					<DatePicker />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default StakeholderCommentModal;
