import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faTrash
} from '@fortawesome/pro-light-svg-icons';

import Weight from '../controls/Weight';

import './EditableListItem.sass';
import { Tooltip } from 'antd';

type IEditableListItemProps = {
	id: String;
	name: String;
	children: any;
	weight: Number;
	onDelete: Function;
	onEdit: Function;
	onCycleWeight: Function;
	disabled: Boolean;
	actions: Array<any>;

	editable: boolean;
	deletable: boolean;
};

function EditableListItem(props: IEditableListItemProps) {
	let editable = props.editable;

	if (editable === undefined || editable === null) editable = true;

	return (
		<div className="EditableListItem">
			{props.actions}

			{editable && (
				<Tooltip title={'Edit'}>
					<FontAwesomeIcon
						icon={faEdit}
						onClick={
							props.disabled
								? () => { }
								: () => {
									props.onEdit(props);
								}
						}
						style={{
							marginRight: '0.5rem',
							cursor: props.disabled ? 'not-allowed' : 'initial',
							opacity: props.disabled ? 0.5 : 1
						}}
					/>
				</Tooltip>
			)}

			{props.deletable && props.onDelete && (
				<FontAwesomeIcon
					icon={faTrash}
					onClick={() => {
						console.log('onClick delete');
						props.onDelete(props.id);
					}}
					style={{ marginRight: '0.5rem' }}
				/>
			)}

			{props.name}
			{props.children}
			{props.onCycleWeight ? (
				<Weight
					onClick={() => {
						props.onCycleWeight(props);
					}}
					disabled={props.disabled}
				>
					{props.weight}
				</Weight>
			) : null}
		</div>
	);
}

export default EditableListItem;
