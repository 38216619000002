import React from 'react';

import { useTranslation, withTranslation } from 'react-i18next';

import { Tooltip } from 'antd';
import { getRiskScore } from 'utils/ranking';
import { getItemAlteredByScenario } from 'utils/rules';
import { ScenarioModel } from 'model/ScenarioModel';
import { RiskModel } from 'model/RiskModel';

import('./MiniSingleVulnerabilityScale.sass');


type IMiniSingleVulnerabilityScale = {
	positive: boolean;
	maxLikelihood: number;
	maxImpact: number;
	scenarios: ScenarioModel[];
	elements: RiskModel[];
}

const MiniSingleVulnerabilityScale = (props: IMiniSingleVulnerabilityScale) => {

	const { positive, maxLikelihood, maxImpact } = props;
	const { t } = useTranslation();

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: getRiskScore(
				props.elements.map((s) =>
					getItemAlteredByScenario(s, sc)
				),
				maxLikelihood,
				maxImpact
			)
		};
	});

	vulnerabilities.push({
		id: null,
		vulnerability: getRiskScore(
			props.elements.map((s) => (s && s.o ? s.o : s)),
			maxLikelihood,
			maxImpact
		)
	});

	if (!props.elements || props.elements.length == 0)
		vulnerabilities = [];

	return (
		<div
			className={`MiniSingleVulnerabilityScale ${positive ? 'positive' : ''}`}
			style={{ width: '250px' }}
		>
			{vulnerabilities.map((v) => (
				<Tooltip
					placement="top"
					key={v.id}
					title={<div>{v.name || t("models.scenario.baseline")}</div>}
				>
					<div
						id={
							v.id == props.scenario?.id
								? 'bar_marker'
								: undefined
						}
						className={
							v.id == props.scenario?.id
								? undefined
								: 'initial_bar_marker'
						}
						style={{ left: (positive ? 100 - v.vulnerability : v.vulnerability) + '%' }}
					></div>
				</Tooltip>
			))}
		</div>
	);

}

export default withTranslation()(MiniSingleVulnerabilityScale);
