import { getScoreColor } from 'utils/ranking';

import _ from 'lodash';

import './FishbonePin.sass';

const sizes = { 1: 5, 2: 7, 3: 9 };

// Types
type FishbonePointProps = {
    trend: string;
    completion: number;
    weight: number;
    color: string;
};

export const FishbonePin = (props: FishbonePointProps) => {
    const color = getScoreColor(props.trend);

    let numberOfRaysMax = 39;
    let numberOfRays = Math.floor(
        ((props.completion || 0) / 100) * numberOfRaysMax
    );

    return (
        <g className="FishbonePin">
            <clipPath id="cut-off">
                <rect x="0" y="-9" width="18" height="18" />
            </clipPath>

            <circle
                r={sizes[props.weight || 1]}
                fill={props.trend == '50' ? '#E70549' : color}
            />
            <circle
                r={sizes[props.weight || 1]}
                fill={props.trend == '50' ? '#24CCB8' : color}
                clipPath="url(#cut-off)"
            />

            <circle r={sizes[props.weight || 1] + 1} stroke="#1C2333" strokeWidth={5} fill="none"></circle>

            {
                _.range(0, numberOfRays).map((r, index) => {
                    return (
                        <g
                            key={index}
                            className={'completion '}
                            transform={
                                'rotate(' +
                                ((index / numberOfRaysMax) * 360 - 90) +
                                ') '
                            }
                        >
                            <line
                                stroke="#FFFFFF66"
                                x1={12}
                                x2={15}
                                y1={0}
                                y2={0}
                            ></line>
                        </g>
                    );
                })
            } {' '}
        </g >
    );
};
