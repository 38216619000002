import { useEffect, useState } from 'react';

import { Modal, Button, Form, Input, Slider, DatePicker, Checkbox, Alert, Radio } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: {
        span: 24
    }
};

export const PromoteAsBaselineModal = (props: any) => {
    const [form] = Form.useForm();
    const keepCurrentBaselineValue = Form.useWatch('keepCurrentBaseline', form);

    const [areTooltipVisibles, setAreTooltipVisibles] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            setAreTooltipVisibles(true);
        }, 400)
    }, []);


    const handleFormSubmit = () => {
        console.log('handleFormSubmit');
        form.validateFields()
            .then((values) => {
                console.log('validated', values);
                props.onSave(values);
                props.onHide();
            })
            .catch((errorInfo) => {
                console.log('error', errorInfo);
            });
    };

    const footer = (
        <div style={{ display: 'flex' }}>
            <Button
                onClick={() => {
                    props.onHide();
                }}
                style={{ marginRight: 'auto' }}
            >
                Cancel
            </Button>
            <Button onClick={handleFormSubmit} type="primary">
                Confirm
            </Button>
        </div>
    );

    console.log('PromoteAsBaselineModal props', props);

    return (
        <Modal
            open={true}
            width={props.width || "70%"}
            title={t("models.scenario.messages.promote_as_baseline.title")}
            okButtonProps={{ style: { display: 'none' } }}
            onCancel={props.onHide}
            cancelText="Close"
            footer={footer}
            maskClosable={false}
        >
            <Alert type='warning' message={
                <Trans
                    i18nKey={"models.scenario.messages.promote_as_baseline.explanation"}
                    values={{ name: props.scenario?.name }}>
                    <span style={{ fontWeight: "900", fontFamily: "sans-serif" }}>{props.scenario?.name}</span>
                </Trans>
            } className="mt-4 mb-2"></Alert>
            <Form
                {...formLayout}
                form={form}
                onFinish={(values) => {
                    props.onChange(props.id, values);
                    props.onHide();
                }}
                initialValues={props.values}
            >
                <Form.Item
                    name={"keepCurrentBaseline"}
                    className='mb-0'
                    label={t("models.scenario.messages.promote_as_baseline.keep_current_baseline_as_scenario_question")}
                >
                    <Radio.Group>
                        <Radio value={false}>{t("models.scenario.messages.promote_as_baseline.keep_current_baseline_as_scenario_question_no")}</Radio>
                        <Radio value={true}>{t("models.scenario.messages.promote_as_baseline.keep_current_baseline_as_scenario_question_yes")}</Radio>
                    </Radio.Group>
                </Form.Item>
                <div className='d-flex w-100 ml-4'>

                    {keepCurrentBaselineValue && <Form.Item
                        name="name"
                        visible={keepCurrentBaselineValue}
                        label={t("models.scenario.fields.name_long")}
                        required={true}
                        className='mr-1'
                        style={{ width: '60%' }}
                        rules={[
                            {
                                max: 35,
                                message:
                                    t("messages.form.max_length_exceeded_error", { length: 35 })
                            },
                            {
                                required: keepCurrentBaselineValue ? true : false,
                                message: t("messages.form.required_error")
                            }
                        ]}
                    >
                        <Input
                            autoComplete='off'
                            count={{
                                show: true,
                                max: 35,
                            }}></Input>
                    </Form.Item>}

                </div>

                <Form.Item
                    name={"keepScenario"}
                    label={t("models.scenario.messages.promote_as_baseline.keep_scenario_question")}
                >
                    <Radio.Group>
                        <Radio value={false}>{t("models.scenario.messages.promote_as_baseline.keep_scenario_question_no")}</Radio>
                        <Radio value={true}>{t("models.scenario.messages.promote_as_baseline.keep_scenario_question_yes")}</Radio>
                    </Radio.Group>
                </Form.Item>

            </Form>
        </Modal >
    );
};

export default PromoteAsBaselineModal;
