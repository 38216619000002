import React from 'react';
import { useAuth0 } from '../../react-auth0-spa';

import { useHistory } from 'react-router';
import { Button } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { useGetCustomerPortalUrlMutation } from 'graph/dist/generated';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import HeaderPortal from '../../layout/header/HeaderPortal';

import Loader from 'components/Loading/Loader';
import PlansList from 'components/Subscription/PlansList';

import './Profile.sass';

interface Props {
	returnTo: string;
	paymentPublicKey: string;
	isSubscriber: boolean;
	subscriptionDetails: any;
}

dayjs.extend(relativeTime)

export const Profile = (props: Props) => {
	const { loading, user } = useAuth0();
	const { logout } = useAuth0();
	const [getCustomerPortalUrlMutation] = useGetCustomerPortalUrlMutation();
	let history = useHistory();

	const redirectToCustomerPortal = async () => {
		const result = await getCustomerPortalUrlMutation();
		console.log('result', result);
		document.location.href = result.data.getCustomerPortalUrl;
	};

	return (
		<div className="Profile">
			<HeaderPortal>My profile</HeaderPortal>

			{loading ? (
				<Loader></Loader>
			) : (
				<>
					<div className="infos">
						{user && (
							<>
								<img src={user.picture} alt="Profile" />
								<div>
									<h2>{user.name}</h2>
									<p>{user.email}</p>
								</div>
							</>
						)}
					</div>

					{!props.isSubscriber &&
					import.meta.env.VITE_APP_MODE !== 'corporate' ? (
						<>
							<div className="title">Choose a plan</div>
							<div className="subtitle">
								Unlock immediately all functionalities
							</div>
							<PlansList />
						</>
					) : (
						props.subscriptionDetails && (
							<div className="subscriptionDetails text-center m-auto mb-4">
								<small>Your current subscription</small>
								{props.subscriptionDetails.startAt && (
									<>
										<div className="mt-2">
											From{' '}
											{dayjs
												.unix(
													props.subscriptionDetails
														.startAt
												)
												.format('DD/MM/YYYY')}
											{props.subscriptionDetails
												.endAt && (
												<>
													{' '}
													to{' '}
													{dayjs
														.unix(
															props
																.subscriptionDetails
																.endAt
														)
														.format('DD/MM/YYYY')}{' '}
													(
													{dayjs
														.unix(
															props
																.subscriptionDetails
																.endAt
														)
														.fromNow()}
													)
												</>
											)}
										</div>
										<div className="mt-4 mb-4">
											<Button
												onClick={
													redirectToCustomerPortal
												}
											>
												Manage my subscription
											</Button>
										</div>
									</>
								)}
							</div>
						)
					)}
					<div
						className="mt-4"
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<Button
							onClick={(e) => {
								e.preventDefault();
								logout({ returnTo: props.returnTo });
							}}
						>
							<FontAwesomeIcon icon={faSignOut} /> &nbsp;Sign out
						</Button>
					</div>
				</>
			)}
		</div>
	);
};
