export default {
	form: {
		required_error: 'This field is required',
		max_length_exceeded_error:
			'This field is too long ({{length}} characters max)'
	},
	legal: {
		i_consent: 'I consent',
		agreement_title: 'Agreement',
		agreement_content:
			'By using Gerositus, you agree to our <1>Terms & conditions</1> and <2>Privacy Policy</2>.',
		agreement_terms_conditions: 'Terms & conditions',
		agreement_privacy_policy: 'Privacy Policy',
		ai_disclaimer:
			"Hi! I'm Maestro, the Gerositus® AI. I'm here to assist you with questions about the Gerositus® frameworks, models, and app functionalities. Please note that while I strive to provide accurate information, I am an AI, and my responses may occasionally contain errors. Always use your own judgment when interpreting my answers.",

		ai_disclaimer_limited:
			"Hi! I'm Maestro, the Gerositus® AI. I'm here to assist you with questions about the Gerositus® frameworks, models, and app functionalities. Please note that while I strive to provide accurate information, I am an AI, and my responses may occasionally contain errors. Always use your own judgment when interpreting my answers." +
			'\n\n' +
			'Rest assured, your project data remains strictly confidential and inaccessible to me. However, if you share relevant details in your question, I can offer more tailored and contextual responses.' +
			'\n\n' +
			'Looking forward to helping you!',

		ai_disclaimer_consent: 'I consent to use Maestro AI',
		ai_project_disclaimer:
			'By default, I cannot access any information about your project. If you would like me to use your project data to provide more specific and tailored responses, you must explicitly grant permission.',

		ai_project_consent:
			'I consent to Maestro accessing my project data to provide tailored responses.'
	},
	yes: 'Yes',
	no: 'No',
	not_defined: 'Not defined',
	please_wait: 'Please wait'
};
