import { useTranslation } from 'react-i18next';

import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Tooltip } from 'antd';
import { getScoreColor } from 'utils/ranking';
import { FishbonePointPopover } from './FishbonePointPopover';
import { FishbonePin } from './FishbonePin';

import './FishbonePoint.sass';
import _ from 'lodash';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)

export const FishbonePoint = (props: any) => {
	const color = getScoreColor(props.trend);
	const { t } = useTranslation();

	let size = Math.min(8, props.size);

	let numberOfRaysMax = 39;
	let numberOfRays = Math.floor(
		((props.completion || 0) / 100) * numberOfRaysMax
	);

	const sizes = { 1: 5, 2: 7, 3: 9 };
	return (
		<Popover
			title={
				<div>
					{props.name}{' '}
					<FontAwesomeIcon
						style={{
							marginLeft: '0.5rem',
							fontSize: '1.2rem',
							cursor: 'pointer'
						}}
						icon={faEdit}
						onClick={() => props.onEdit(props)}
					/>
				</div>
			}
			trigger={'click'}
			content={<FishbonePointPopover {...props}></FishbonePointPopover>}
		>
			<g
				className="FishbonePoint"
				transform={'translate(' + props.x + ', ' + props.y + ')'}
			>
				<Tooltip
					overlayStyle={{ minWidth: '400px' }}
					title={
						<div>
							<div className="d-flex pb-3 ">
								<b>
									{props.name}{' '}
									<small
										style={{
											fontSize: '10px',
											marginLeft: '5px'
										}}
									>
										(click to edit)
									</small>
								</b>
							</div>

							<div className="d-flex mb-2">
								<div style={{ width: '110px', flexShrink: 0 }}>
									Completion
								</div>
								<div>
									<b>{(props.completion || 0).toFixed(0)}%</b>
								</div>
							</div>

							<div className="d-flex mb-2">
								<div style={{ width: '110px', flexShrink: 0 }}>
									Status
								</div>
								<div
									className="MiniIndicatorVulnerabilityScale"
									style={{
										width: '200px',
										marginTop: '10px'
									}}
								>
									<div
										id={'bar_marker'}
										style={{ left: props.trend + '%' }}
									></div>
								</div>
							</div>

							<div className="d-flex mb-2">
								<div style={{ width: '110px', flexShrink: 0 }}>
									Description
								</div>
								<div>
									<small>
										{props.description || 'No description'}
									</small>
								</div>
							</div>


							<div className="d-flex mb-2">
								<div
									style={{
										width: '110px',
										flexShrink: 0
									}}
								>
									Dates
								</div>
								<div>
									{
										<small>
											{props.startOn ? dayjs(props.startOn).format(
												'DD/MM/YYYY'
											) : t("messages.not_defined")}
										</small>
									}
								</div>
								{props.startOn && props.endOn && (
									<div className="mr-1 ml-1">-</div>
								)}
								<div>
									{props.endOn &&
										!props.startOn &&
										'Until '}
									{props.endOn && (
										<small>
											{dayjs(props.endOn).format(
												'DD/MM/YYYY'
											)}
										</small>
									)}
								</div>
							</div>

							<div className="d-flex mb-2">
								<div
									style={{
										width: '110px',
										flexShrink: 0
									}}
								>
									Responsible
								</div>
								<div>
									{props.responsible && props.responsible || t("messages.not_defined")}
								</div>
							</div>

							{props.updatedAt && (
								<div className="d-flex">
									<div
										style={{
											width: '110px',
											flexShrink: 0
										}}
									>
										Last update
									</div>
									<div>
										{props.updatedAt &&
											dayjs(props.updatedAt).format(
												'DD/MM/YYYY HH:mm'
											)}
										<br />
										{props.updatedAt && (
											<small>
												(
												{dayjs(
													props.updatedAt
												).fromNow()}
												)
											</small>
										)}
									</div>
								</div>
							)}
						</div>
					}
				>
					<g>
						<FishbonePin weight={props.weight} color={color} trend={props.trend} completion={props.completion} />

						<text
							fontSize={12}
							fill="white"
							dy={size * 0.6}
							dx={21}
						>
							{props.name}
						</text>

					</g>
				</Tooltip>
			</g>
		</Popover>
	);
};
