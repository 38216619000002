import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal } from 'antd';
import Loader from 'components/Loading/Loader';

export class WaitModal extends Component {
    render() {
        return (
            <Modal
                open={true}
                maskClosable={true}
                closable={false}
                okButtonProps={{ style: { display: 'none' } }}
                footer={null}
                title={
                    this.props.t("messages.please_wait")
                }
            >
                <Loader size={100} style={{ minHeight: "70px" }} />
                <div className="text-center mb-2">
                    {this.props.t("models.project.messages.clone_in_progress")}
                </div>

            </Modal>
        );
    }
}

WaitModal.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation()(WaitModal);
