import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';

import UIContext from 'components/Utils/UIContext';
import UIProvider from 'components/Utils/UIProvider';
import ModalContext from 'components/Utils/ModalContext';
import ModalProvider from 'components/Utils/ModalProvider';
import SubscriptionProvider from 'components/Utils/SubscriptionProvider';
import SubscriptionContext from 'components/Utils/SubscriptionContext';

import { Layout } from 'antd';
import { Profile } from './pages/profile/Profile';
import { Projects } from './pages/Projects/Projects';
import { Project } from './pages/Project/Project';

import { history } from './utils/history';
import Header from './layout/header/Header';

import { PrivateRoute as PrivateRouteWithAuth0 } from './components/navigation/PrivateRoute';
import { PrivateRoute as PrivateRouteWithoutAuth0 } from './components/navigation/PrivateRouteWithoutAuth0';

import 'bootstrap/scss/bootstrap.scss';

import 'antd/dist/reset.css';

import './App.sass';
import { ThankYou } from 'pages/thankyou/ThankYou';
import { Route } from 'react-router';
import { Agreement } from 'pages/Legal/Agreement';

const SelectionConsumer = UIContext.Consumer;
const ModalConsumer = ModalContext.Consumer;
const SubscriptionConsumer = SubscriptionContext.Consumer;

const PrivateRoute = import.meta.env.VITE_APP_FEATURE_TOKEN_LOGIN
	? PrivateRouteWithoutAuth0
	: PrivateRouteWithAuth0;

interface Props {
	returnTo: string;
	paymentPublicKey: string;
	auth0Domain: string;
}
interface State { }

export default class App extends Component<Props, State> {
	state = {
		selection: null,
		isExpanded: false
	};

	render() {
		const forceHeader =
			!history.location.pathname.match(/\/projects\/[a-f0-9\-]+.*/) ||
			history.location.pathname == '' ||
			history.location.pathname == '/';

		return (
			<div
				id="App"
				className={[
					history.location.pathname.match(/^\/projects\/$/)
						? 'light'
						: 'dark',
					this.state.isExpanded && !forceHeader ? 'expanded' : ''
				].join(' ')}
			>
				<Router history={history}>
					<Switch>
						<Route
							key="agreement"
							path="/agreement"
							exact={
								true
							}
						>
							<Agreement
								auth0Domain={this.props.auth0Domain}
								expanded={
									this
										.state
										.isExpanded
								}
								setExpanded={(
									value
								) => {
									this.setState(
										{
											isExpanded:
												value
										}
									);
								}}></Agreement>
						</Route>
						<Route
							path="*">

							<SubscriptionProvider>
								<UIProvider>
									<ModalProvider>
										<SubscriptionConsumer>
											{(subscriptionProps) => (
												<SelectionConsumer>
													{(selectionProps) => (
														<Layout
															className={'Layout'}
															style={{
																paddingTop: this.state
																	.isExpanded
																	? '0px'
																	: '88px'
															}}
														>
															<Header
																visible={
																	!this.state
																		.isExpanded ||
																	forceHeader
																}
															></Header>
															<Layout.Content>
																<ModalConsumer>
																	{(modalProps) => (
																		<Switch>

																			<PrivateRoute
																				key="profile"
																				path="/profile"
																			>
																				<Profile
																					paymentPublicKey={
																						this
																							.props
																							.paymentPublicKey
																					}
																					returnTo={
																						this
																							.props
																							.returnTo
																					}
																					{...subscriptionProps}
																				></Profile>
																			</PrivateRoute>
																			<PrivateRoute
																				key="project"
																				path="/projects/:id"
																			>
																				<Project
																					{...modalProps}
																					{...selectionProps}
																					{...subscriptionProps}
																					expanded={
																						this
																							.state
																							.isExpanded
																					}
																					setExpanded={(
																						value
																					) => {
																						this.setState(
																							{
																								isExpanded:
																									value
																							}
																						);
																					}}
																				></Project>
																			</PrivateRoute>
																			<PrivateRoute
																				key="thankyou"
																				path="/thankyou"
																			>
																				<ThankYou
																					{...modalProps}
																					{...subscriptionProps}
																				></ThankYou>
																			</PrivateRoute>

																			<PrivateRoute
																				key="projectsRoot"
																				exact={
																					true
																				}
																				path=""
																			>
																				<Projects
																					{...modalProps}
																					{...subscriptionProps}
																					{...selectionProps}
																					isFeatureEnabledProfile={
																						!import.meta
																							.env
																							.VITE_APP_FEATURE_PROFILE ||
																						import.meta
																							.env
																							.VITE_APP_FEATURE_PROFILE ===
																						'enabled'
																					}
																				></Projects>
																			</PrivateRoute>
																		</Switch>
																	)}
																</ModalConsumer>

																<ModalConsumer>
																	{({
																		modals,
																		popModal
																	}) => {
																		return modals.map(
																			(
																				m,
																				index
																			) => {
																				return React.cloneElement(
																					m,
																					{
																						key:
																							m.key ||
																							index,
																						show: true,
																						visible:
																							true,
																						onHide: popModal
																					}
																				);
																			}
																		);
																	}}
																</ModalConsumer>
															</Layout.Content>
														</Layout>
													)}
												</SelectionConsumer>
											)}
										</SubscriptionConsumer>
									</ModalProvider>
								</UIProvider>
							</SubscriptionProvider>

						</Route>

					</Switch>
				</Router>
				<div id="copyright" className="noselection">
					Gerositus © {new Date().getFullYear()} Visto&Vista Ltd
					<small>
						{import.meta.env.VITE_APP_MODE} -{' '}
						{import.meta.env.VITE_APP_ENV}
					</small>
				</div>
			</div>
		);
	}
}
