export const iconList = [
	'faAbacus',
	'faAd',
	'faAddressBook',
	'faAddressCard',
	'faAdjust',
	'faAirConditioner',
	'faAirFreshener',
	'faAlarmClock',
	'faAlarmExclamation',
	'faAlarmPlus',
	'faAlarmSnooze',
	'faAlbum',
	'faAlbumCollection',
	'faAlicorn',
	'faAlien',
	'faAlienMonster',
	'faAllergies',
	'faAmbulance',
	'faAmericanSignLanguageInterpreting',
	'faAmpGuitar',
	'faAnalytics',
	'faAnchor',
	'faAngel',
	'faAngleDoubleDown',
	'faAngleDoubleLeft',
	'faAngleDoubleRight',
	'faAngleDoubleUp',
	'faAngleDown',
	'faAngleLeft',
	'faAngleRight',
	'faAngleUp',
	'faAngry',
	'faAnkh',
	'faAppleAlt',
	'faAppleCrate',
	'faArchive',
	'faArchway',
	'faArrowAltCircleDown',
	'faArrowAltCircleLeft',
	'faArrowAltCircleRight',
	'faArrowAltCircleUp',
	'faArrowAltDown',
	'faArrowAltFromBottom',
	'faArrowAltFromLeft',
	'faArrowAltFromRight',
	'faArrowAltFromTop',
	'faArrowAltLeft',
	'faArrowAltRight',
	'faArrowAltSquareDown',
	'faArrowAltSquareLeft',
	'faArrowAltSquareRight',
	'faArrowAltSquareUp',
	'faArrowAltToBottom',
	'faArrowAltToLeft',
	'faArrowAltToRight',
	'faArrowAltToTop',
	'faArrowAltUp',
	'faArrowCircleDown',
	'faArrowCircleLeft',
	'faArrowCircleRight',
	'faArrowCircleUp',
	'faArrowDown',
	'faArrowFromBottom',
	'faArrowFromLeft',
	'faArrowFromRight',
	'faArrowFromTop',
	'faArrowLeft',
	'faArrowRight',
	'faArrowSquareDown',
	'faArrowSquareLeft',
	'faArrowSquareRight',
	'faArrowSquareUp',
	'faArrowToBottom',
	'faArrowToLeft',
	'faArrowToRight',
	'faArrowToTop',
	'faArrowUp',
	'faArrows',
	'faArrowsAlt',
	'faArrowsAltH',
	'faArrowsAltV',
	'faArrowsH',
	'faArrowsV',
	'faAssistiveListeningSystems',
	'faAsterisk',
	'faAt',
	'faAtlas',
	'faAtom',
	'faAtomAlt',
	'faAudioDescription',
	'faAward',
	'faAxe',
	'faAxeBattle',
	'faBaby',
	'faBabyCarriage',
	'faBackpack',
	'faBackspace',
	'faBackward',
	'faBacon',
	'faBacteria',
	'faBacterium',
	'faBadge',
	'faBadgeCheck',
	'faBadgeDollar',
	'faBadgePercent',
	'faBadgeSheriff',
	'faBadgerHoney',
	'faBagsShopping',
	'faBahai',
	'faBalanceScale',
	'faBalanceScaleLeft',
	'faBalanceScaleRight',
	'faBallPile',
	'faBallot',
	'faBallotCheck',
	'faBan',
	'faBandAid',
	'faBanjo',
	'faBarcode',
	'faBarcodeAlt',
	'faBarcodeRead',
	'faBarcodeScan',
	'faBars',
	'faBaseball',
	'faBasketballBall',
	'faBasketballHoop',
	'faBat',
	'faBath',
	'faBatteryBolt',
	'faBatteryEmpty',
	'faBatteryFull',
	'faBatteryHalf',
	'faBatteryQuarter',
	'faBatterySlash',
	'faBatteryThreeQuarters',
	'faBed',
	'faBedAlt',
	'faBedBunk',
	'faBedEmpty',
	'faBeer',
	'faBell',
	'faBellExclamation',
	'faBellOn',
	'faBellPlus',
	'faBellSchool',
	'faBellSchoolSlash',
	'faBellSlash',
	'faBells',
	'faBetamax',
	'faBezierCurve',
	'faBible',
	'faBicycle',
	'faBiking',
	'faBikingMountain',
	'faBinoculars',
	'faBiohazard',
	'faBirthdayCake',
	'faBlanket',
	'faBlender',
	'faBlenderPhone',
	'faBlog',
	'faBold',
	'faBolt',
	'faBomb',
	'faBone',
	'faBoneBreak',
	'faBong',
	'faBook',
	'faBookAlt',
	'faBookDead',
	'faBookHeart',
	'faBookMedical',
	'faBookOpen',
	'faBookReader',
	'faBookSpells',
	'faBookUser',
	'faBookmark',
	'faBooks',
	'faBooksMedical',
	'faBoombox',
	'faBoot',
	'faBoothCurtain',
	'faBorderAll',
	'faBorderBottom',
	'faBorderCenterH',
	'faBorderCenterV',
	'faBorderInner',
	'faBorderLeft',
	'faBorderNone',
	'faBorderOuter',
	'faBorderRight',
	'faBorderStyle',
	'faBorderStyleAlt',
	'faBorderTop',
	'faBowArrow',
	'faBowlingBall',
	'faBowlingPins',
	'faBox',
	'faBoxAlt',
	'faBoxBallot',
	'faBoxCheck',
	'faBoxFragile',
	'faBoxFull',
	'faBoxHeart',
	'faBoxOpen',
	'faBoxTissue',
	'faBoxUp',
	'faBoxUsd',
	'faBoxes',
	'faBoxesAlt',
	'faBoxingGlove',
	'faBrackets',
	'faBracketsCurly',
	'faBraille',
	'faBrain',
	'faBreadLoaf',
	'faBreadSlice',
	'faBriefcase',
	'faBriefcaseMedical',
	'faBringForward',
	'faBringFront',
	'faBroadcastTower',
	'faBroom',
	'faBrowser',
	'faBrush',
	'faBug',
	'faBuilding',
	'faBullhorn',
	'faBullseye',
	'faBullseyeArrow',
	'faBullseyePointer',
	'faBurgerSoda',
	'faBurn',
	'faBurrito',
	'faBus',
	'faBusAlt',
	'faBusSchool',
	'faBusinessTime',
	'faCabinetFiling',
	'faCactus',
	'faCalculator',
	'faCalculatorAlt',
	'faCalendar',
	'faCalendarAlt',
	'faCalendarCheck',
	'faCalendarDay',
	'faCalendarEdit',
	'faCalendarExclamation',
	'faCalendarMinus',
	'faCalendarPlus',
	'faCalendarStar',
	'faCalendarTimes',
	'faCalendarWeek',
	'faCamcorder',
	'faCamera',
	'faCameraAlt',
	'faCameraHome',
	'faCameraMovie',
	'faCameraPolaroid',
	'faCameraRetro',
	'faCampfire',
	'faCampground',
	'faCandleHolder',
	'faCandyCane',
	'faCandyCorn',
	'faCannabis',
	'faCapsules',
	'faCar',
	'faCarAlt',
	'faCarBattery',
	'faCarBuilding',
	'faCarBump',
	'faCarBus',
	'faCarCrash',
	'faCarGarage',
	'faCarMechanic',
	'faCarSide',
	'faCarTilt',
	'faCarWash',
	'faCaravan',
	'faCaravanAlt',
	'faCaretCircleDown',
	'faCaretCircleLeft',
	'faCaretCircleRight',
	'faCaretCircleUp',
	'faCaretDown',
	'faCaretLeft',
	'faCaretRight',
	'faCaretSquareDown',
	'faCaretSquareLeft',
	'faCaretSquareRight',
	'faCaretSquareUp',
	'faCaretUp',
	'faCarrot',
	'faCars',
	'faCartArrowDown',
	'faCartPlus',
	'faCashRegister',
	'faCassetteTape',
	'faCat',
	'faCatSpace',
	'faCauldron',
	'faCctv',
	'faCertificate',
	'faChair',
	'faChairOffice',
	'faChalkboard',
	'faChalkboardTeacher',
	'faChargingStation',
	'faChartArea',
	'faChartBar',
	'faChartLine',
	'faChartLineDown',
	'faChartNetwork',
	'faChartPie',
	'faChartPieAlt',
	'faChartScatter',
	'faCheck',
	'faCheckCircle',
	'faCheckDouble',
	'faCheckSquare',
	'faCheese',
	'faCheeseSwiss',
	'faCheeseburger',
	'faChess',
	'faChessBishop',
	'faChessBishopAlt',
	'faChessBoard',
	'faChessClock',
	'faChessClockAlt',
	'faChessKing',
	'faChessKingAlt',
	'faChessKnight',
	'faChessKnightAlt',
	'faChessPawn',
	'faChessPawnAlt',
	'faChessQueen',
	'faChessQueenAlt',
	'faChessRook',
	'faChessRookAlt',
	'faChevronCircleDown',
	'faChevronCircleLeft',
	'faChevronCircleRight',
	'faChevronCircleUp',
	'faChevronDoubleDown',
	'faChevronDoubleLeft',
	'faChevronDoubleRight',
	'faChevronDoubleUp',
	'faChevronDown',
	'faChevronLeft',
	'faChevronRight',
	'faChevronSquareDown',
	'faChevronSquareLeft',
	'faChevronSquareRight',
	'faChevronSquareUp',
	'faChevronUp',
	'faChild',
	'faChimney',
	'faChurch',
	'faCircle',
	'faCircleNotch',
	'faCity',
	'faClarinet',
	'faClawMarks',
	'faClinicMedical',
	'faClipboard',
	'faClipboardCheck',
	'faClipboardList',
	'faClipboardListCheck',
	'faClipboardPrescription',
	'faClipboardUser',
	'faClock',
	'faClone',
	'faClosedCaptioning',
	'faCloud',
	'faCloudDownload',
	'faCloudDownloadAlt',
	'faCloudDrizzle',
	'faCloudHail',
	'faCloudHailMixed',
	'faCloudMeatball',
	'faCloudMoon',
	'faCloudMoonRain',
	'faCloudMusic',
	'faCloudRain',
	'faCloudRainbow',
	'faCloudShowers',
	'faCloudShowersHeavy',
	'faCloudSleet',
	'faCloudSnow',
	'faCloudSun',
	'faCloudSunRain',
	'faCloudUpload',
	'faCloudUploadAlt',
	'faClouds',
	'faCloudsMoon',
	'faCloudsSun',
	'faClub',
	'faCocktail',
	'faCode',
	'faCodeBranch',
	'faCodeCommit',
	'faCodeMerge',
	'faCoffee',
	'faCoffeePot',
	'faCoffeeTogo',
	'faCoffin',
	'faCoffinCross',
	'faCog',
	'faCogs',
	'faCoin',
	'faCoins',
	'faColumns',
	'faComet',
	'faComment',
	'faCommentAlt',
	'faCommentAltCheck',
	'faCommentAltDollar',
	'faCommentAltDots',
	'faCommentAltEdit',
	'faCommentAltExclamation',
	'faCommentAltLines',
	'faCommentAltMedical',
	'faCommentAltMinus',
	'faCommentAltMusic',
	'faCommentAltPlus',
	'faCommentAltSlash',
	'faCommentAltSmile',
	'faCommentAltTimes',
	'faCommentCheck',
	'faCommentDollar',
	'faCommentDots',
	'faCommentEdit',
	'faCommentExclamation',
	'faCommentLines',
	'faCommentMedical',
	'faCommentMinus',
	'faCommentMusic',
	'faCommentPlus',
	'faCommentSlash',
	'faCommentSmile',
	'faCommentTimes',
	'faComments',
	'faCommentsAlt',
	'faCommentsAltDollar',
	'faCommentsDollar',
	'faCompactDisc',
	'faCompass',
	'faCompassSlash',
	'faCompress',
	'faCompressAlt',
	'faCompressArrowsAlt',
	'faCompressWide',
	'faComputerClassic',
	'faComputerSpeaker',
	'faConciergeBell',
	'faConstruction',
	'faContainerStorage',
	'faConveyorBelt',
	'faConveyorBeltAlt',
	'faCookie',
	'faCookieBite',
	'faCopy',
	'faCopyright',
	'faCorn',
	'faCouch',
	'faCow',
	'faCowbell',
	'faCowbellMore',
	'faCreditCard',
	'faCreditCardBlank',
	'faCreditCardFront',
	'faCricket',
	'faCroissant',
	'faCrop',
	'faCropAlt',
	'faCross',
	'faCrosshairs',
	'faCrow',
	'faCrown',
	'faCrutch',
	'faCrutches',
	'faCube',
	'faCubes',
	'faCurling',
	'faCut',
	'faDagger',
	'faDatabase',
	'faDeaf',
	'faDebug',
	'faDeer',
	'faDeerRudolph',
	'faDemocrat',
	'faDesktop',
	'faDesktopAlt',
	'faDewpoint',
	'faDharmachakra',
	'faDiagnoses',
	'faDiamond',
	'faDice',
	'faDiceD10',
	'faDiceD12',
	'faDiceD20',
	'faDiceD4',
	'faDiceD6',
	'faDiceD8',
	'faDiceFive',
	'faDiceFour',
	'faDiceOne',
	'faDiceSix',
	'faDiceThree',
	'faDiceTwo',
	'faDigging',
	'faDigitalTachograph',
	'faDiploma',
	'faDirections',
	'faDiscDrive',
	'faDisease',
	'faDivide',
	'faDizzy',
	'faDna',
	'faDoNotEnter',
	'faDog',
	'faDogLeashed',
	'faDollarSign',
	'faDolly',
	'faDollyEmpty',
	'faDollyFlatbed',
	'faDollyFlatbedAlt',
	'faDollyFlatbedEmpty',
	'faDonate',
	'faDoorClosed',
	'faDoorOpen',
	'faDotCircle',
	'faDove',
	'faDownload',
	'faDraftingCompass',
	'faDragon',
	'faDrawCircle',
	'faDrawPolygon',
	'faDrawSquare',
	'faDreidel',
	'faDrone',
	'faDroneAlt',
	'faDrum',
	'faDrumSteelpan',
	'faDrumstick',
	'faDrumstickBite',
	'faDryer',
	'faDryerAlt',
	'faDuck',
	'faDumbbell',
	'faDumpster',
	'faDumpsterFire',
	'faDungeon',
	'faEar',
	'faEarMuffs',
	'faEclipse',
	'faEclipseAlt',
	'faEdit',
	'faEgg',
	'faEggFried',
	'faEject',
	'faElephant',
	'faEllipsisH',
	'faEllipsisHAlt',
	'faEllipsisV',
	'faEllipsisVAlt',
	'faEmptySet',
	'faEngineWarning',
	'faEnvelope',
	'faEnvelopeOpen',
	'faEnvelopeOpenDollar',
	'faEnvelopeOpenText',
	'faEnvelopeSquare',
	'faEquals',
	'faEraser',
	'faEthernet',
	'faEuroSign',
	'faExchange',
	'faExchangeAlt',
	'faExclamation',
	'faExclamationCircle',
	'faExclamationSquare',
	'faExclamationTriangle',
	'faExpand',
	'faExpandAlt',
	'faExpandArrows',
	'faExpandArrowsAlt',
	'faExpandWide',
	'faExternalLink',
	'faExternalLinkAlt',
	'faExternalLinkSquare',
	'faExternalLinkSquareAlt',
	'faEye',
	'faEyeDropper',
	'faEyeEvil',
	'faEyeSlash',
	'faFan',
	'faFanTable',
	'faFarm',
	'faFastBackward',
	'faFastForward',
	'faFaucet',
	'faFaucetDrip',
	'faFax',
	'faFeather',
	'faFeatherAlt',
	'faFemale',
	'faFieldHockey',
	'faFighterJet',
	'faFile',
	'faFileAlt',
	'faFileArchive',
	'faFileAudio',
	'faFileCertificate',
	'faFileChartLine',
	'faFileChartPie',
	'faFileCheck',
	'faFileCode',
	'faFileContract',
	'faFileCsv',
	'faFileDownload',
	'faFileEdit',
	'faFileExcel',
	'faFileExclamation',
	'faFileExport',
	'faFileImage',
	'faFileImport',
	'faFileInvoice',
	'faFileInvoiceDollar',
	'faFileMedical',
	'faFileMedicalAlt',
	'faFileMinus',
	'faFileMusic',
	'faFilePdf',
	'faFilePlus',
	'faFilePowerpoint',
	'faFilePrescription',
	'faFileSearch',
	'faFileSignature',
	'faFileSpreadsheet',
	'faFileTimes',
	'faFileUpload',
	'faFileUser',
	'faFileVideo',
	'faFileWord',
	'faFilesMedical',
	'faFill',
	'faFillDrip',
	'faFilm',
	'faFilmAlt',
	'faFilmCanister',
	'faFilter',
	'faFingerprint',
	'faFire',
	'faFireAlt',
	'faFireExtinguisher',
	'faFireSmoke',
	'faFireplace',
	'faFirstAid',
	'faFish',
	'faFishCooked',
	'faFistRaised',
	'faFlag',
	'faFlagAlt',
	'faFlagCheckered',
	'faFlagUsa',
	'faFlame',
	'faFlashlight',
	'faFlask',
	'faFlaskPoison',
	'faFlaskPotion',
	'faFlower',
	'faFlowerDaffodil',
	'faFlowerTulip',
	'faFlushed',
	'faFlute',
	'faFluxCapacitor',
	'faFog',
	'faFolder',
	'faFolderDownload',
	'faFolderMinus',
	'faFolderOpen',
	'faFolderPlus',
	'faFolderTimes',
	'faFolderTree',
	'faFolderUpload',
	'faFolders',
	'faFont',
	'faFontAwesomeLogoFull',
	'faFontCase',
	'faFootballBall',
	'faFootballHelmet',
	'faForklift',
	'faForward',
	'faFragile',
	'faFrenchFries',
	'faFrog',
	'faFrostyHead',
	'faFrown',
	'faFrownOpen',
	'faFunction',
	'faFunnelDollar',
	'faFutbol',
	'faGalaxy',
	'faGameBoard',
	'faGameBoardAlt',
	'faGameConsoleHandheld',
	'faGamepad',
	'faGamepadAlt',
	'faGarage',
	'faGarageCar',
	'faGarageOpen',
	'faGasPump',
	'faGasPumpSlash',
	'faGavel',
	'faGem',
	'faGenderless',
	'faGhost',
	'faGift',
	'faGiftCard',
	'faGifts',
	'faGingerbreadMan',
	'faGlass',
	'faGlassChampagne',
	'faGlassCheers',
	'faGlassCitrus',
	'faGlassMartini',
	'faGlassMartiniAlt',
	'faGlassWhiskey',
	'faGlassWhiskeyRocks',
	'faGlasses',
	'faGlassesAlt',
	'faGlobe',
	'faGlobeAfrica',
	'faGlobeAmericas',
	'faGlobeAsia',
	'faGlobeEurope',
	'faGlobeSnow',
	'faGlobeStand',
	'faGolfBall',
	'faGolfClub',
	'faGopuram',
	'faGraduationCap',
	'faGramophone',
	'faGreaterThan',
	'faGreaterThanEqual',
	'faGrimace',
	'faGrin',
	'faGrinAlt',
	'faGrinBeam',
	'faGrinBeamSweat',
	'faGrinHearts',
	'faGrinSquint',
	'faGrinSquintTears',
	'faGrinStars',
	'faGrinTears',
	'faGrinTongue',
	'faGrinTongueSquint',
	'faGrinTongueWink',
	'faGrinWink',
	'faGripHorizontal',
	'faGripLines',
	'faGripLinesVertical',
	'faGripVertical',
	'faGuitar',
	'faGuitarElectric',
	'faGuitars',
	'faHSquare',
	'faHamburger',
	'faHammer',
	'faHammerWar',
	'faHamsa',
	'faHandHeart',
	'faHandHolding',
	'faHandHoldingBox',
	'faHandHoldingHeart',
	'faHandHoldingMagic',
	'faHandHoldingMedical',
	'faHandHoldingSeedling',
	'faHandHoldingUsd',
	'faHandHoldingWater',
	'faHandLizard',
	'faHandMiddleFinger',
	'faHandPaper',
	'faHandPeace',
	'faHandPointDown',
	'faHandPointLeft',
	'faHandPointRight',
	'faHandPointUp',
	'faHandPointer',
	'faHandReceiving',
	'faHandRock',
	'faHandScissors',
	'faHandSparkles',
	'faHandSpock',
	'faHands',
	'faHandsHeart',
	'faHandsHelping',
	'faHandsUsd',
	'faHandsWash',
	'faHandshake',
	'faHandshakeAlt',
	'faHandshakeAltSlash',
	'faHandshakeSlash',
	'faHanukiah',
	'faHardHat',
	'faHashtag',
	'faHatChef',
	'faHatCowboy',
	'faHatCowboySide',
	'faHatSanta',
	'faHatWinter',
	'faHatWitch',
	'faHatWizard',
	'faHdd',
	'faHeadSide',
	'faHeadSideBrain',
	'faHeadSideCough',
	'faHeadSideCoughSlash',
	'faHeadSideHeadphones',
	'faHeadSideMask',
	'faHeadSideMedical',
	'faHeadSideVirus',
	'faHeadVr',
	'faHeading',
	'faHeadphones',
	'faHeadphonesAlt',
	'faHeadset',
	'faHeart',
	'faHeartBroken',
	'faHeartCircle',
	'faHeartRate',
	'faHeartSquare',
	'faHeartbeat',
	'faHeat',
	'faHelicopter',
	'faHelmetBattle',
	'faHexagon',
	'faHighlighter',
	'faHiking',
	'faHippo',
	'faHistory',
	'faHockeyMask',
	'faHockeyPuck',
	'faHockeySticks',
	'faHollyBerry',
	'faHome',
	'faHomeAlt',
	'faHomeHeart',
	'faHomeLg',
	'faHomeLgAlt',
	'faHoodCloak',
	'faHorizontalRule',
	'faHorse',
	'faHorseHead',
	'faHorseSaddle',
	'faHospital',
	'faHospitalAlt',
	'faHospitalSymbol',
	'faHospitalUser',
	'faHospitals',
	'faHotTub',
	'faHotdog',
	'faHotel',
	'faHourglass',
	'faHourglassEnd',
	'faHourglassHalf',
	'faHourglassStart',
	'faHouse',
	'faHouseDamage',
	'faHouseDay',
	'faHouseFlood',
	'faHouseLeave',
	'faHouseNight',
	'faHouseReturn',
	'faHouseSignal',
	'faHouseUser',
	'faHryvnia',
	'faHumidity',
	'faHurricane',
	'faICursor',
	'faIceCream',
	'faIceSkate',
	'faIcicles',
	'faIcons',
	'faIconsAlt',
	'faIdBadge',
	'faIdCard',
	'faIdCardAlt',
	'faIgloo',
	'faImage',
	'faImagePolaroid',
	'faImages',
	'faInbox',
	'faInboxIn',
	'faInboxOut',
	'faIndent',
	'faIndustry',
	'faIndustryAlt',
	'faInfinity',
	'faInfo',
	'faInfoCircle',
	'faInfoSquare',
	'faInhaler',
	'faIntegral',
	'faIntersection',
	'faInventory',
	'faIslandTropical',
	'faJackOLantern',
	'faJedi',
	'faJoint',
	'faJournalWhills',
	'faJoystick',
	'faJug',
	'faKaaba',
	'faKazoo',
	'faKerning',
	'faKey',
	'faKeySkeleton',
	'faKeyboard',
	'faKeynote',
	'faKhanda',
	'faKidneys',
	'faKiss',
	'faKissBeam',
	'faKissWinkHeart',
	'faKite',
	'faKiwiBird',
	'faKnifeKitchen',
	'faLambda',
	'faLamp',
	'faLampDesk',
	'faLampFloor',
	'faLandmark',
	'faLandmarkAlt',
	'faLanguage',
	'faLaptop',
	'faLaptopCode',
	'faLaptopHouse',
	'faLaptopMedical',
	'faLasso',
	'faLaugh',
	'faLaughBeam',
	'faLaughSquint',
	'faLaughWink',
	'faLayerGroup',
	'faLayerMinus',
	'faLayerPlus',
	'faLeaf',
	'faLeafHeart',
	'faLeafMaple',
	'faLeafOak',
	'faLemon',
	'faLessThan',
	'faLessThanEqual',
	'faLevelDown',
	'faLevelDownAlt',
	'faLevelUp',
	'faLevelUpAlt',
	'faLifeRing',
	'faLightCeiling',
	'faLightSwitch',
	'faLightSwitchOff',
	'faLightSwitchOn',
	'faLightbulb',
	'faLightbulbDollar',
	'faLightbulbExclamation',
	'faLightbulbOn',
	'faLightbulbSlash',
	'faLightsHoliday',
	'faLineColumns',
	'faLineHeight',
	'faLink',
	'faLips',
	'faLiraSign',
	'faList',
	'faListAlt',
	'faListMusic',
	'faListOl',
	'faListUl',
	'faLocation',
	'faLocationArrow',
	'faLocationCircle',
	'faLocationSlash',
	'faLock',
	'faLockAlt',
	'faLockOpen',
	'faLockOpenAlt',
	'faLongArrowAltDown',
	'faLongArrowAltLeft',
	'faLongArrowAltRight',
	'faLongArrowAltUp',
	'faLongArrowDown',
	'faLongArrowLeft',
	'faLongArrowRight',
	'faLongArrowUp',
	'faLoveseat',
	'faLowVision',
	'faLuchador',
	'faLuggageCart',
	'faLungs',
	'faLungsVirus',
	'faMace',
	'faMagic',
	'faMagnet',
	'faMailBulk',
	'faMailbox',
	'faMale',
	'faMandolin',
	'faMap',
	'faMapMarked',
	'faMapMarkedAlt',
	'faMapMarker',
	'faMapMarkerAlt',
	'faMapMarkerAltSlash',
	'faMapMarkerCheck',
	'faMapMarkerEdit',
	'faMapMarkerExclamation',
	'faMapMarkerMinus',
	'faMapMarkerPlus',
	'faMapMarkerQuestion',
	'faMapMarkerSlash',
	'faMapMarkerSmile',
	'faMapMarkerTimes',
	'faMapPin',
	'faMapSigns',
	'faMarker',
	'faMars',
	'faMarsDouble',
	'faMarsStroke',
	'faMarsStrokeH',
	'faMarsStrokeV',
	'faMask',
	'faMeat',
	'faMedal',
	'faMedkit',
	'faMegaphone',
	'faMeh',
	'faMehBlank',
	'faMehRollingEyes',
	'faMemory',
	'faMenorah',
	'faMercury',
	'faMeteor',
	'faMicrochip',
	'faMicrophone',
	'faMicrophoneAlt',
	'faMicrophoneAltSlash',
	'faMicrophoneSlash',
	'faMicrophoneStand',
	'faMicroscope',
	'faMicrowave',
	'faMindShare',
	'faMinus',
	'faMinusCircle',
	'faMinusHexagon',
	'faMinusOctagon',
	'faMinusSquare',
	'faMistletoe',
	'faMitten',
	'faMobile',
	'faMobileAlt',
	'faMobileAndroid',
	'faMobileAndroidAlt',
	'faMoneyBill',
	'faMoneyBillAlt',
	'faMoneyBillWave',
	'faMoneyBillWaveAlt',
	'faMoneyCheck',
	'faMoneyCheckAlt',
	'faMoneyCheckEdit',
	'faMoneyCheckEditAlt',
	'faMonitorHeartRate',
	'faMonkey',
	'faMonument',
	'faMoon',
	'faMoonCloud',
	'faMoonStars',
	'faMortarPestle',
	'faMosque',
	'faMotorcycle',
	'faMountain',
	'faMountains',
	'faMouse',
	'faMouseAlt',
	'faMousePointer',
	'faMp3Player',
	'faMug',
	'faMugHot',
	'faMugMarshmallows',
	'faMugTea',
	'faMusic',
	'faMusicAlt',
	'faMusicAltSlash',
	'faMusicSlash',
	'faNarwhal',
	'faNetworkWired',
	'faNeuter',
	'faNewspaper',
	'faNotEqual',
	'faNotesMedical',
	'faObjectGroup',
	'faObjectUngroup',
	'faOctagon',
	'faOilCan',
	'faOilTemp',
	'faOm',
	'faOmega',
	'faOrnament',
	'faOtter',
	'faOutdent',
	'faOutlet',
	'faOven',
	'faOverline',
	'faPageBreak',
	'faPager',
	'faPaintBrush',
	'faPaintBrushAlt',
	'faPaintRoller',
	'faPalette',
	'faPallet',
	'faPalletAlt',
	'faPaperPlane',
	'faPaperclip',
	'faParachuteBox',
	'faParagraph',
	'faParagraphRtl',
	'faParking',
	'faParkingCircle',
	'faParkingCircleSlash',
	'faParkingSlash',
	'faPassport',
	'faPastafarianism',
	'faPaste',
	'faPause',
	'faPauseCircle',
	'faPaw',
	'faPawAlt',
	'faPawClaws',
	'faPeace',
	'faPegasus',
	'faPen',
	'faPenAlt',
	'faPenFancy',
	'faPenNib',
	'faPenSquare',
	'faPencil',
	'faPencilAlt',
	'faPencilPaintbrush',
	'faPencilRuler',
	'faPennant',
	'faPeopleArrows',
	'faPeopleCarry',
	'faPepperHot',
	'faPercent',
	'faPercentage',
	'faPersonBooth',
	'faPersonCarry',
	'faPersonDolly',
	'faPersonDollyEmpty',
	'faPersonSign',
	'faPhone',
	'faPhoneAlt',
	'faPhoneLaptop',
	'faPhoneOffice',
	'faPhonePlus',
	'faPhoneRotary',
	'faPhoneSlash',
	'faPhoneSquare',
	'faPhoneSquareAlt',
	'faPhoneVolume',
	'faPhotoVideo',
	'faPi',
	'faPiano',
	'faPianoKeyboard',
	'faPie',
	'faPig',
	'faPiggyBank',
	'faPills',
	'faPizza',
	'faPizzaSlice',
	'faPlaceOfWorship',
	'faPlane',
	'faPlaneAlt',
	'faPlaneArrival',
	'faPlaneDeparture',
	'faPlaneSlash',
	'faPlanetMoon',
	'faPlanetRinged',
	'faPlay',
	'faPlayCircle',
	'faPlug',
	'faPlus',
	'faPlusCircle',
	'faPlusHexagon',
	'faPlusOctagon',
	'faPlusSquare',
	'faPodcast',
	'faPodium',
	'faPodiumStar',
	'faPoliceBox',
	'faPoll',
	'faPollH',
	'faPollPeople',
	'faPoo',
	'faPooStorm',
	'faPoop',
	'faPopcorn',
	'faPortalEnter',
	'faPortalExit',
	'faPortrait',
	'faPoundSign',
	'faPowerOff',
	'faPray',
	'faPrayingHands',
	'faPrescription',
	'faPrescriptionBottle',
	'faPrescriptionBottleAlt',
	'faPresentation',
	'faPrint',
	'faPrintSearch',
	'faPrintSlash',
	'faProcedures',
	'faProjectDiagram',
	'faProjector',
	'faPumpMedical',
	'faPumpSoap',
	'faPumpkin',
	'faPuzzlePiece',
	'faQrcode',
	'faQuestion',
	'faQuestionCircle',
	'faQuestionSquare',
	'faQuidditch',
	'faQuoteLeft',
	'faQuoteRight',
	'faQuran',
	'faRabbit',
	'faRabbitFast',
	'faRacquet',
	'faRadar',
	'faRadiation',
	'faRadiationAlt',
	'faRadio',
	'faRadioAlt',
	'faRainbow',
	'faRaindrops',
	'faRam',
	'faRampLoading',
	'faRandom',
	'faRaygun',
	'faReceipt',
	'faRecordVinyl',
	'faRectangleLandscape',
	'faRectanglePortrait',
	'faRectangleWide',
	'faRecycle',
	'faRedo',
	'faRedoAlt',
	'faRefrigerator',
	'faRegistered',
	'faRemoveFormat',
	'faRepeat',
	'faRepeat1',
	'faRepeat1Alt',
	'faRepeatAlt',
	'faReply',
	'faReplyAll',
	'faRepublican',
	'faRestroom',
	'faRetweet',
	'faRetweetAlt',
	'faRibbon',
	'faRing',
	'faRingsWedding',
	'faRoad',
	'faRobot',
	'faRocket',
	'faRocketLaunch',
	'faRoute',
	'faRouteHighway',
	'faRouteInterstate',
	'faRouter',
	'faRss',
	'faRssSquare',
	'faRubleSign',
	'faRuler',
	'faRulerCombined',
	'faRulerHorizontal',
	'faRulerTriangle',
	'faRulerVertical',
	'faRunning',
	'faRupeeSign',
	'faRv',
	'faSack',
	'faSackDollar',
	'faSadCry',
	'faSadTear',
	'faSalad',
	'faSandwich',
	'faSatellite',
	'faSatelliteDish',
	'faSausage',
	'faSave',
	'faSaxHot',
	'faSaxophone',
	'faScalpel',
	'faScalpelPath',
	'faScanner',
	'faScannerImage',
	'faScannerKeyboard',
	'faScannerTouchscreen',
	'faScarecrow',
	'faScarf',
	'faSchool',
	'faScrewdriver',
	'faScroll',
	'faScrollOld',
	'faScrubber',
	'faScythe',
	'faSdCard',
	'faSearch',
	'faSearchDollar',
	'faSearchLocation',
	'faSearchMinus',
	'faSearchPlus',
	'faSeedling',
	'faSendBack',
	'faSendBackward',
	'faSensor',
	'faSensorAlert',
	'faSensorFire',
	'faSensorOn',
	'faSensorSmoke',
	'faServer',
	'faShapes',
	'faShare',
	'faShareAll',
	'faShareAlt',
	'faShareAltSquare',
	'faShareSquare',
	'faSheep',
	'faShekelSign',
	'faShield',
	'faShieldAlt',
	'faShieldCheck',
	'faShieldCross',
	'faShieldVirus',
	'faShip',
	'faShippingFast',
	'faShippingTimed',
	'faShishKebab',
	'faShoePrints',
	'faShoppingBag',
	'faShoppingBasket',
	'faShoppingCart',
	'faShovel',
	'faShovelSnow',
	'faShower',
	'faShredder',
	'faShuttleVan',
	'faShuttlecock',
	'faSickle',
	'faSigma',
	'faSign',
	'faSignIn',
	'faSignInAlt',
	'faSignLanguage',
	'faSignOut',
	'faSignOutAlt',
	'faSignal',
	'faSignal1',
	'faSignal2',
	'faSignal3',
	'faSignal4',
	'faSignalAlt',
	'faSignalAlt1',
	'faSignalAlt2',
	'faSignalAlt3',
	'faSignalAltSlash',
	'faSignalSlash',
	'faSignalStream',
	'faSignature',
	'faSimCard',
	'faSink',
	'faSiren',
	'faSirenOn',
	'faSitemap',
	'faSkating',
	'faSkeleton',
	'faSkiJump',
	'faSkiLift',
	'faSkiing',
	'faSkiingNordic',
	'faSkull',
	'faSkullCow',
	'faSkullCrossbones',
	'faSlash',
	'faSledding',
	'faSleigh',
	'faSlidersH',
	'faSlidersHSquare',
	'faSlidersV',
	'faSlidersVSquare',
	'faSmile',
	'faSmileBeam',
	'faSmilePlus',
	'faSmileWink',
	'faSmog',
	'faSmoke',
	'faSmoking',
	'faSmokingBan',
	'faSms',
	'faSnake',
	'faSnooze',
	'faSnowBlowing',
	'faSnowboarding',
	'faSnowflake',
	'faSnowflakes',
	'faSnowman',
	'faSnowmobile',
	'faSnowplow',
	'faSoap',
	'faSocks',
	'faSolarPanel',
	'faSolarSystem',
	'faSoup',
	'faSpa',
	'faSpaceShuttle',
	'faSpaceStationMoon',
	'faSpaceStationMoonAlt',
	'faSpade',
	'faSparkles',
	'faSpeaker',
	'faSpeakers',
	'faSpellCheck',
	'faSpider',
	'faSpiderBlackWidow',
	'faSpiderWeb',
	'faSpinner',
	'faSpinnerThird',
	'faSplotch',
	'faSprayCan',
	'faSprinkler',
	'faSquare',
	'faSquareFull',
	'faSquareRoot',
	'faSquareRootAlt',
	'faSquirrel',
	'faStaff',
	'faStamp',
	'faStar',
	'faStarAndCrescent',
	'faStarChristmas',
	'faStarExclamation',
	'faStarHalf',
	'faStarHalfAlt',
	'faStarOfDavid',
	'faStarOfLife',
	'faStarShooting',
	'faStarfighter',
	'faStarfighterAlt',
	'faStars',
	'faStarship',
	'faStarshipFreighter',
	'faSteak',
	'faSteeringWheel',
	'faStepBackward',
	'faStepForward',
	'faStethoscope',
	'faStickyNote',
	'faStocking',
	'faStomach',
	'faStop',
	'faStopCircle',
	'faStopwatch',
	'faStopwatch20',
	'faStore',
	'faStoreAlt',
	'faStoreAltSlash',
	'faStoreSlash',
	'faStream',
	'faStreetView',
	'faStretcher',
	'faStrikethrough',
	'faStroopwafel',
	'faSubscript',
	'faSubway',
	'faSuitcase',
	'faSuitcaseRolling',
	'faSun',
	'faSunCloud',
	'faSunDust',
	'faSunHaze',
	'faSunglasses',
	'faSunrise',
	'faSunset',
	'faSuperscript',
	'faSurprise',
	'faSwatchbook',
	'faSwimmer',
	'faSwimmingPool',
	'faSword',
	'faSwordLaser',
	'faSwordLaserAlt',
	'faSwords',
	'faSwordsLaser',
	'faSynagogue',
	'faSync',
	'faSyncAlt',
	'faSyringe',
	'faTable',
	'faTableTennis',
	'faTablet',
	'faTabletAlt',
	'faTabletAndroid',
	'faTabletAndroidAlt',
	'faTabletRugged',
	'faTablets',
	'faTachometer',
	'faTachometerAlt',
	'faTachometerAltAverage',
	'faTachometerAltFast',
	'faTachometerAltFastest',
	'faTachometerAltSlow',
	'faTachometerAltSlowest',
	'faTachometerAverage',
	'faTachometerFast',
	'faTachometerFastest',
	'faTachometerSlow',
	'faTachometerSlowest',
	'faTaco',
	'faTag',
	'faTags',
	'faTally',
	'faTanakh',
	'faTape',
	'faTasks',
	'faTasksAlt',
	'faTaxi',
	'faTeeth',
	'faTeethOpen',
	'faTelescope',
	'faTemperatureDown',
	'faTemperatureFrigid',
	'faTemperatureHigh',
	'faTemperatureHot',
	'faTemperatureLow',
	'faTemperatureUp',
	'faTenge',
	'faTennisBall',
	'faTerminal',
	'faText',
	'faTextHeight',
	'faTextSize',
	'faTextWidth',
	'faTh',
	'faThLarge',
	'faThList',
	'faTheaterMasks',
	'faThermometer',
	'faThermometerEmpty',
	'faThermometerFull',
	'faThermometerHalf',
	'faThermometerQuarter',
	'faThermometerThreeQuarters',
	'faTheta',
	'faThumbsDown',
	'faThumbsUp',
	'faThumbtack',
	'faThunderstorm',
	'faThunderstormMoon',
	'faThunderstormSun',
	'faTicket',
	'faTicketAlt',
	'faTilde',
	'faTimes',
	'faTimesCircle',
	'faTimesHexagon',
	'faTimesOctagon',
	'faTimesSquare',
	'faTint',
	'faTintSlash',
	'faTire',
	'faTireFlat',
	'faTirePressureWarning',
	'faTireRugged',
	'faTired',
	'faToggleOff',
	'faToggleOn',
	'faToilet',
	'faToiletPaper',
	'faToiletPaperAlt',
	'faToiletPaperSlash',
	'faTombstone',
	'faTombstoneAlt',
	'faToolbox',
	'faTools',
	'faTooth',
	'faToothbrush',
	'faTorah',
	'faToriiGate',
	'faTornado',
	'faTractor',
	'faTrademark',
	'faTrafficCone',
	'faTrafficLight',
	'faTrafficLightGo',
	'faTrafficLightSlow',
	'faTrafficLightStop',
	'faTrailer',
	'faTrain',
	'faTram',
	'faTransgender',
	'faTransgenderAlt',
	'faTransporter',
	'faTransporter1',
	'faTransporter2',
	'faTransporter3',
	'faTransporterEmpty',
	'faTrash',
	'faTrashAlt',
	'faTrashRestore',
	'faTrashRestoreAlt',
	'faTrashUndo',
	'faTrashUndoAlt',
	'faTreasureChest',
	'faTree',
	'faTreeAlt',
	'faTreeChristmas',
	'faTreeDecorated',
	'faTreeLarge',
	'faTreePalm',
	'faTrees',
	'faTriangle',
	'faTriangleMusic',
	'faTrophy',
	'faTrophyAlt',
	'faTruck',
	'faTruckContainer',
	'faTruckCouch',
	'faTruckLoading',
	'faTruckMonster',
	'faTruckMoving',
	'faTruckPickup',
	'faTruckPlow',
	'faTruckRamp',
	'faTrumpet',
	'faTshirt',
	'faTty',
	'faTurkey',
	'faTurntable',
	'faTurtle',
	'faTv',
	'faTvAlt',
	'faTvMusic',
	'faTvRetro',
	'faTypewriter',
	'faUfo',
	'faUfoBeam',
	'faUmbrella',
	'faUmbrellaBeach',
	'faUnderline',
	'faUndo',
	'faUndoAlt',
	'faUnicorn',
	'faUnion',
	'faUniversalAccess',
	'faUniversity',
	'faUnlink',
	'faUnlock',
	'faUnlockAlt',
	'faUpload',
	'faUsbDrive',
	'faUsdCircle',
	'faUsdSquare',
	'faUser',
	'faUserAlien',
	'faUserAlt',
	'faUserAltSlash',
	'faUserAstronaut',
	'faUserChart',
	'faUserCheck',
	'faUserCircle',
	'faUserClock',
	'faUserCog',
	'faUserCowboy',
	'faUserCrown',
	'faUserEdit',
	'faUserFriends',
	'faUserGraduate',
	'faUserHardHat',
	'faUserHeadset',
	'faUserInjured',
	'faUserLock',
	'faUserMd',
	'faUserMdChat',
	'faUserMinus',
	'faUserMusic',
	'faUserNinja',
	'faUserNurse',
	'faUserPlus',
	'faUserRobot',
	'faUserSecret',
	'faUserShield',
	'faUserSlash',
	'faUserTag',
	'faUserTie',
	'faUserTimes',
	'faUserUnlock',
	'faUserVisor',
	'faUsers',
	'faUsersClass',
	'faUsersCog',
	'faUsersCrown',
	'faUsersMedical',
	'faUsersSlash',
	'faUtensilFork',
	'faUtensilKnife',
	'faUtensilSpoon',
	'faUtensils',
	'faUtensilsAlt',
	'faVacuum',
	'faVacuumRobot',
	'faValueAbsolute',
	'faVectorSquare',
	'faVenus',
	'faVenusDouble',
	'faVenusMars',
	'faVest',
	'faVestPatches',
	'faVhs',
	'faVial',
	'faVials',
	'faVideo',
	'faVideoPlus',
	'faVideoSlash',
	'faVihara',
	'faViolin',
	'faVirus',
	'faVirusSlash',
	'faViruses',
	'faVoicemail',
	'faVolcano',
	'faVolleyballBall',
	'faVolume',
	'faVolumeDown',
	'faVolumeMute',
	'faVolumeOff',
	'faVolumeSlash',
	'faVolumeUp',
	'faVoteNay',
	'faVoteYea',
	'faVrCardboard',
	'faWagonCovered',
	'faWalker',
	'faWalkieTalkie',
	'faWalking',
	'faWallet',
	'faWand',
	'faWandMagic',
	'faWarehouse',
	'faWarehouseAlt',
	'faWasher',
	'faWatch',
	'faWatchCalculator',
	'faWatchFitness',
	'faWater',
	'faWaterLower',
	'faWaterRise',
	'faWaveSine',
	'faWaveSquare',
	'faWaveTriangle',
	'faWaveform',
	'faWaveformPath',
	'faWebcam',
	'faWebcamSlash',
	'faWeight',
	'faWeightHanging',
	'faWhale',
	'faWheat',
	'faWheelchair',
	'faWhistle',
	'faWifi',
	'faWifi1',
	'faWifi2',
	'faWifiSlash',
	'faWind',
	'faWindTurbine',
	'faWindWarning',
	'faWindow',
	'faWindowAlt',
	'faWindowClose',
	'faWindowFrame',
	'faWindowFrameOpen',
	'faWindowMaximize',
	'faWindowMinimize',
	'faWindowRestore',
	'faWindsock',
	'faWineBottle',
	'faWineGlass',
	'faWineGlassAlt',
	'faWonSign',
	'faWreath',
	'faWrench',
	'faXRay',
	'faYenSign',
	'faYinYang'
];
