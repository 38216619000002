//@ts-ignore
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { IconPickerItem, iconList } from '.';

import * as CSS from 'csstype';

import { fal } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

import './IconPicker.sass';

interface IconPickerProps {
	value: any;
	onChange: (value: any) => void;
	hideSearch?: boolean;
	containerStyles?: CSS.Properties;
	buttonStyles?: CSS.Properties;
	buttonIconStyles?: CSS.Properties;
	pickerIconStyles?: CSS.Properties;
	searchInputStyles?: CSS.Properties;
	favorites: any;
	disabled: boolean;
}

const IconPicker: React.SFC<IconPickerProps> = ({
	value,
	onChange,
	hideSearch,
	containerStyles,
	buttonStyles,
	buttonIconStyles,
	pickerIconStyles,
	searchInputStyles,
	favorites,
	disabled
}) => {
	const ref = useRef(null);
	const [display, changeDisplay] = useState(false);
	const [searchString, setSearchString] = useState('');
	useEffect(() => {
		function handleClickOutside(event: any) {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				changeDisplay(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
	const buttonClick = disabled ? () => {} : () => changeDisplay(!display);
	const onChangeSearch = (event: any) => {
		setSearchString(event.target.value);
	};
	return display && !disabled ? (
		<Container style={buttonStyles} ref={ref} onClick={() => buttonClick()}>
			<IconPickerItem containerStyles={buttonIconStyles} icon={value} />

			{display && (
				<PickerContainer
					className="IconPickerContainer"
					style={containerStyles}
					onClick={(e) => e.stopPropagation()}
				>
					{!hideSearch && (
						<AppInput
							style={searchInputStyles}
							onChange={onChangeSearch}
							value={searchString}
							placeholder="Search"
						/>
					)}
					{favorites ? (
						<div className="w-100">
							<div className="title w-100">Used</div>
							{favorites
								.filter((i: string) =>
									i
										.toLowerCase()
										.includes(searchString.toLowerCase())
								)
								.map((icon: any) => (
									<FontAwesomeIcon
										icon={fal[icon]}
										onClick={(value: any) => {
											onChange(icon);
											changeDisplay(false);
											setSearchString('');
										}}
									></FontAwesomeIcon>
								))}
						</div>
					) : null}
					{favorites ? (
						<div className="title w-100">Unused</div>
					) : null}
					{iconList
						.filter((i: string) =>
							i.toLowerCase().includes(searchString.toLowerCase())
						)
						.map((icon: any) => (
							<FontAwesomeIcon
								icon={fal[icon]}
								onClick={(value: any) => {
									onChange(icon);
									changeDisplay(false);
									setSearchString('');
								}}
							></FontAwesomeIcon>
						))}
				</PickerContainer>
			)}
		</Container>
	) : (
		<Tooltip title={disabled ? null : 'Edit icon'}>
			<Container
				style={buttonStyles}
				ref={ref}
				onClick={() => buttonClick()}
			>
				<IconPickerItem
					containerStyles={buttonIconStyles}
					icon={value}
				/>

				{display && (
					<PickerContainer
						className="IconPickerContainer"
						style={containerStyles}
						onClick={(e) => e.stopPropagation()}
					>
						{!hideSearch && (
							<AppInput
								style={searchInputStyles}
								onChange={onChangeSearch}
								value={searchString}
								placeholder="Search"
							/>
						)}
						{iconList
							.filter((i: string) =>
								i
									.toLowerCase()
									.includes(searchString.toLowerCase())
							)
							.map((icon: any) => (
								<FontAwesomeIcon
									icon={fal[icon]}
									onClick={(value: any) => {
										onChange(icon);
										changeDisplay(false);
										setSearchString('');
									}}
								></FontAwesomeIcon>
							))}
					</PickerContainer>
				)}
			</Container>
		</Tooltip>
	);
};

IconPicker.defaultProps = {
	hideSearch: false
};

const Container = styled.div`
	position: relative;
	padding: 5px;
	width: 50px;
	min-height: 40px;
	border-radius: 4px;
	border-width: 1px;
	border-color: #000;
	border-style: solid;
	display: flex;
	justify-content: center;
	align-items: center;
	:hover {
		cursor: pointer;
	}
`;

const PickerContainer = styled.div`
	position: absolute;
	top: 45px;
	left: -2px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow-y: scroll;
	background-color: #fff;
	padding: 5px;
	width: 200px;
	max-height: 300px;
	border-radius: 4px;
	border-width: 2px;
	border-color: #000;
	border-style: solid;
	z-index: 10;
`;

const AppInput = styled.input`
	width: 100%;
`;

export { IconPicker };
