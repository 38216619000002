import { setContext } from '@apollo/link-context';
import { ApolloLink, HttpLink } from '@apollo/client';

export const createAppSyncHttpLink = function ({ appSyncApiUrl, getJwtToken }) {
	const authorizationHeaderLink = setContext(
		async (request, previousContext) => ({
			...previousContext,
			headers: {
				...previousContext.headers,
				Authorization: await getJwtToken()
			}
		})
	);

	return ApolloLink.concat(
		authorizationHeaderLink,
		new HttpLink({ uri: appSyncApiUrl })
	);
};
