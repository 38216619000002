import React from 'react';
import { useAuth0 } from './react-auth0-spa';
import useAsyncEffect from 'use-async-effect';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { createAppSyncHybridLink } from 'utils/graphql/appSyncHybridLink';
import { onError } from '@apollo/client/link/error';
import { history } from './utils/history';

import AppSyncConfig from './app-sync-config.json';
import Loader from './components/Loading/Loader';

// @ts-ignore
let subdomain: string = document.location.hostname.replace(/\.gerositus.*/, '');
const config: any =
	import.meta.env.VITE_APP_MODE === 'corporate'
		? AppSyncConfig[import.meta.env.VITE_APP_MODE][import.meta.env.VITE_APP_CLIENT || subdomain][
		import.meta.env.VITE_APP_ENV
		]
		: AppSyncConfig[import.meta.env.VITE_APP_MODE][
		import.meta.env.VITE_APP_ENV
		];

interface IProps {
	children: any;
	// any other props that come into the component
}

export const AuthorizedApolloProvider: any = ({ children }: IProps) => {
	const [token, setToken] = React.useState<string>('');
	const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
	const [client, setClient] = React.useState<any>(null);
	const { loading, getIdTokenClaims, loginWithRedirect } = useAuth0();

	const getJwtToken = async () => {
		const claims = await getIdTokenClaims();
		return claims ? claims.__raw : null;
	};

	const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {

		console.log('errorLink', { graphQLErrors, networkError, operation });

		if (graphQLErrors) {
			graphQLErrors.forEach(({ message, locations, path }) =>
				console.error(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			);
		}

		if (networkError) {
			console.error(`[Network error]: ${networkError}`);

			// If the network error is due to an expired token, refresh and retry
			if (networkError.statusCode === 401) {
				// Redirect to login
				localStorage.removeItem('token');
				return loginWithRedirect({
					appState: { targetUrl: window.location.pathname }
				});
			}
		}
	});

	useAsyncEffect(async () => {
		if (loading) return <Loader></Loader>;

		const getClient = async () => {
			return new ApolloClient({
				link: ApolloLink.from([
					errorLink,
					await createAppSyncHybridLink({
						appSyncApiUrl: config.ApiUrl,
						getJwtToken
					})
				]),
				cache: new InMemoryCache()
			});
		};

		const finalClient = await getClient();
		setClient(finalClient);
	}, [loading]);

	return client ? (
		<ApolloProvider client={client as any}>{children}</ApolloProvider>
	) : null;
};
