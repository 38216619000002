type Props = {
	blocks: Array<InputBlock>;
	width: number;
	height: number;
};

type RemainingSpace = {
	x1: number;
	y1: number;
	x2: number;
	y2: number;
	width: number;
	height: number;
};

type InputBlock = {
	id: string;
	verticalAlign: string | undefined;
	horizontalAlign: string | undefined;
	width: number | undefined;
	height: number | undefined;
};

export const remainingSpace = (props: Props) => {
	let remainingSpace: RemainingSpace = {
		x1: 0,
		y1: 0,
		x2: props.width,
		y2: props.height,
		width: 0,
		height: 0
	};

	props.blocks.forEach((block: InputBlock) => {
		if (block.verticalAlign === 'top') {
			remainingSpace.y1 += block.height || 0;
		}
		if (block.verticalAlign === 'bottom') {
			remainingSpace.y2 -= block.height || 0;
		}
		if (block.horizontalAlign === 'left') {
			remainingSpace.x1 += block.width || 0;
		}
		if (block.horizontalAlign === 'right') {
			remainingSpace.x2 -= block.width || 0;
		}
	});

	remainingSpace.width = remainingSpace.x2 - remainingSpace.x1;
	remainingSpace.height = remainingSpace.y2 - remainingSpace.y1;
	return remainingSpace;
};
