import { gql } from '@apollo/client';

export const useXImpactController = () => {
	// Create
	const handleCreateXImpact = async (
		hook: Function,
		originType: string,
		originId: string,
		targetType: string,
		targetId: string
	) => {
		const originTypeLower = originType.toLowerCase();
		const targetTypeLower = targetType.toLowerCase();
		const methodName = `create${originType}${targetType}Impact`;
		const typeName = `${originType}${targetType}Impact`;

		let result = await hook({
			variables: {
				[originTypeLower + 'Id']: originId,
				[targetTypeLower + 'Id']: targetId
			},
			update(cache, { data }) {
				cache.modify({
					id: `${originType}:${originId}`,
					fields: {
						[`impacted${targetType}s`](existingRefs) {
							const newStakeholderIndicatorImpact =
								cache.writeFragment({
									id: typeName + ':' + data[methodName].id,
									data: data[methodName],
									fragment: gql`
										fragment ${typeName}Fragment on ${typeName} {
											${originTypeLower}Id
											${targetTypeLower}Id
                                            strength
                                            createdAt
										}
									`
								});
							return [
								...existingRefs,
								newStakeholderIndicatorImpact
							];
						}
					}
				});
			},

			optimisticResponse: {
				[methodName]: {
					__typename: typeName,
					id: 'temp-id',
					[`${originTypeLower}Id`]: originId,
					[`${targetTypeLower}Id`]: targetId,
					createdAt: new Date().toISOString(),
					strength: 1
				}
			}
		});

		return result.data[methodName];
	};

	// Delete
	const handleDeleteXImpact = async (
		hook: Function,
		id: string,
		originType: string,
		originId: string,
		targetType: string
	) => {
		const methodName = `delete${originType}${targetType}Impact`;

		await hook({
			variables: {
				id
			},
			update(cache) {
				cache.modify({
					id: `${originType}:${originId}`,
					fields: {
						[`impacted${targetType}s`](
							existingRefs,
							{ readField }
						) {
							return existingRefs.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				[methodName]: true
			}
		});
	};

	const handleChangeImpactStrength = (
		hook: Function,
		impactedElement: any,
		originType: string,
		targetType: string,
		strength: number
	) => {
		const methodName = `update${originType}${targetType}ImpactStrength`;
		const typeName = `${originType}${targetType}Impact`;

		hook({
			variables: { id: impactedElement.id, strength },
			update(cache) {
				cache.modify({
					id: `${typeName}:${impactedElement.id}`,
					fields: {
						strength() {
							return strength;
						}
					}
				});
			},

			optimisticResponse: {
				[methodName]: {
					__typename: typeName,
					id: impactedElement.id,
					...impactedElement,
					strength: strength
				}
			}
		});
	};

	const handleDiff = async (
		hooks: object,
		originType: string,
		origin: object,
		targetType: string,
		values = []
	) => {
		console.group('handleDiff');
		let promises = [];
		const targetTypeIdName = targetType.toLowerCase() + 'Id';

		const existingBefore = origin['impacted' + targetType + 's'];
		const existingAfter = values;

		const toDelete = existingBefore.filter(
			(existing) =>
				!existingAfter.some(
					(after) => after === existing[targetTypeIdName]
				)
		);

		const toCreate = existingAfter.filter(
			(after) =>
				!existingBefore.some(
					(existing) => after === existing[targetTypeIdName]
				)
		);

		toDelete.forEach((deleted) => {
			console.log('Delete', deleted);
			promises.push(
				handleDeleteXImpact(
					hooks.delete,
					deleted.id,
					originType,
					origin.id,
					targetType
				)
			);
		});

		toCreate.forEach((created) => {
			console.log('Create', created);
			promises.push(
				handleCreateXImpact(
					hooks.create,
					originType,
					origin.id,
					targetType,
					created
				)
			);
		});

		await Promise.all(promises);
		console.groupEnd();
	};

	return {
		create: handleCreateXImpact,
		updateStrength: handleChangeImpactStrength,
		delete: handleDeleteXImpact,
		diff: handleDiff
	};
};
