import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { history } from './utils/history';
import { Auth0Provider } from './react-auth0-spa';
import authConfig from './auth-config.json';
import { AuthorizedApolloProvider } from './authorized-apollo-client';
import { AuthorizedApolloProvider as AuthorizedApolloProviderWithoutAuth0 } from './authorized-apollo-client-without-auth0';
import './i18n';

// @ts-ignore
let subdomain: string = document.location.hostname.replace(/\.gerositus.*/, '');
const config =
	import.meta.env.VITE_APP_MODE === 'corporate'
		? authConfig[import.meta.env.VITE_APP_MODE][import.meta.env.VITE_APP_CLIENT || subdomain][
		import.meta.env.VITE_APP_ENV
		]
		: authConfig[import.meta.env.VITE_APP_MODE][
		import.meta.env.VITE_APP_ENV
		];

console.log('config', config);

const onRedirectCallback = async (url?: string) => {
	history.push(url ?? window.location.pathname);
};

if (document.location.host != 'localhost:3000') {
	Sentry.init({
		dsn: 'https://33d74f02ec6b4a5dbe4c7317eaede7dc@o23207.ingest.sentry.io/6492582',
		integrations: [
			Sentry.replayIntegration(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.1,
		environment: import.meta.env.VITE_SENTRY_ENV || "dev",
		ignoreErrors: [
			"Response not successful: Received status code 401"
		],
		replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0,
	});
}

if (import.meta.env.VITE_APP_GTM) {
	const tagManagerArgs = {
		gtmId: 'GTM-' + import.meta.env.VITE_APP_GTM
	};

	TagManager.initialize(tagManagerArgs);
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if (!rootElement) throw new Error('Failed to find the root element');

root.render(
	<React.StrictMode>
		{import.meta.env.VITE_APP_FEATURE_TOKEN_LOGIN === 'enabled' ? (
			<AuthorizedApolloProviderWithoutAuth0>
				<App
					returnTo={config.returnTo}
					paymentPublicKey={config.paymentPublicKey}
				/>
			</AuthorizedApolloProviderWithoutAuth0>
		) : (
			<Auth0Provider
				domain={config.domain}
				client_id={config.clientID}
				redirect_uri={config.redirectUri}
				audience={config.audience}
				onRedirectCallback={onRedirectCallback}
				cacheLocation="localstorage"
			>
				<AuthorizedApolloProvider>
					<App
						returnTo={config.returnTo}
						auth0Domain={config.domain}
						paymentPublicKey={config.paymentPublicKey}
					/>
				</AuthorizedApolloProvider>
			</Auth0Provider>
		)}
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
