import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'antd';

import { compact, concat, find, flatten, keyBy, maxBy, set, sortBy, uniqBy, values } from 'lodash';
import { Axis } from './RiskScatterFullChart/Axis';
import { PointsCloud } from './RiskScatterFullChart/PointsCloud';
import { IndicatorsPointsCloud } from './RiskScatterFullChart/IndicatorsPointsCloud';
import { getItemAlteredByScenario, isItemInactive } from 'utils/rules';
import { baseMargin, circlePlacementDx, circlePlacementDy, circleSpacer, indicatorsMargin } from './RiskScatterFullChart.functions';

import { useTranslation } from 'react-i18next';
import Weight from 'components/controls/Weight';
import { MiniSlider } from 'components/controls/MiniSlider';

import { StakeholdersPointsCloud } from './RiskScatterFullChart/StakeholdersPointsCloud';
import { getStakeholderScoreForScenarioDetailsColor, itemActiveness } from 'utils/ranking';

import './RiskScatterFullChart.sass';

const adaptStakeholderScore = (stakeholder) => {
	const score = getStakeholderScoreForScenarioDetailsColor(stakeholder);

	if (score === null) return null

	return score.toFixed(0)
}

export const RiskScatterFullChart = (props: any) => {
	const svgRef = useRef();

	const { t } = useTranslation();
	const { style, likelihoods = [], impacts = [], risks = [], opportunities = [], indicators = [], stakeholders = [] } = props;

	const [hoveredElement, setHoveredElement] = React.useState(null);
	const [xy, setXY] = React.useState([0, 0]);

	// Indicators
	const alteredIndicators = props.showStakes ? sortBy(
		indicators.map((c, index) => ({
			...getItemAlteredByScenario(c, props.scenario),
			index: index
		})
		),
		'name'
	) : [];
	const indicatorsCount = alteredIndicators.reduce((acc, indicator) => {
		const key = "" + indicator.trend
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});
	const similarIndicators = keyBy((alteredIndicators || []).map(r => ({
		...r,
		similarCount: indicatorsCount[r.trend] - 1,
		index: alteredIndicators.filter(fr => fr.trend == r.trend)
			.findIndex(d => d.id === r.id)
	})), "id");

	// Stakeholders
	const alteredStakeholders = props.showStakeholders ? sortBy(
		stakeholders.map((c, index) => ({
			...getItemAlteredByScenario(c, props.scenario),
			index: index
		})
		),
		'name'
	) : [];
	const filteredStakeholders = alteredStakeholders.filter(
		s => adaptStakeholderScore(s) !== null
	)
	const stakeholdersCount = filteredStakeholders.reduce((acc, stakeholder) => {
		const key = "" + getStakeholderScoreForScenarioDetailsColor(stakeholder)
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});
	const similarStakeholders = keyBy((filteredStakeholders || []).map(r => ({
		...r,
		similarCount: stakeholdersCount[adaptStakeholderScore(r)] - 1,
		index: filteredStakeholders.filter(fr => getStakeholderScoreForScenarioDetailsColor(fr) == getStakeholderScoreForScenarioDetailsColor(r))
			.findIndex(d => d.id === r.id)
	})), "id");

	// Risks
	const filteredRisks = props.showRisks ? risks : []
	const risksCount = filteredRisks.reduce((acc, risk) => {
		const key = "" + (risk.impact || risk?.o?.impact) + "-" + risk.likelihood;
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});

	const similarRisks = keyBy(filteredRisks.map(r => ({
		...r,
		similarCount: risksCount["" + (r.impact || r?.o?.impact) + "-" + r.likelihood] - 1,
		index: filteredRisks.filter(fr => (fr.impact || fr?.o?.impact) === (r.impact || r?.o?.impact) && fr.likelihood === r.likelihood)
			.findIndex(d => d.id === r.id)
	})), "id");

	const filteredOpportunities = props.showOpportunities ? opportunities : []
	const opportunitiesCount = filteredOpportunities.reduce((acc, opportunity) => {
		const key = "" + opportunity.impact + "-" + opportunity.likelihood;
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});

	const similarOpportunities = keyBy(filteredOpportunities.map(r => ({
		...r,
		similarCount: opportunitiesCount["" + r.impact + "-" + r.likelihood] - 1,
		index: filteredOpportunities.filter(fr => (fr.impact || fr?.o?.impact) === (r.impact || r?.o?.impact) && fr.likelihood === r.likelihood)
			.findIndex(d => d.id === r.id)
	})), "id");

	const [selectedRisks, setSelectedRisks] = React.useState([]);
	const [selectedOpportunities, setSelectedOpportunities] = React.useState([]);
	const [selectedIndicators, setSelectedIndicators] = React.useState([]);
	const [selectedStakeholders, setSelectedStakeholders] = React.useState([]);

	const visibleLinks = props.showAllLinks ? compact(flatten([values(similarRisks), values(similarOpportunities), props.showStakes && values(similarIndicators), values(similarStakeholders)])) : []

	const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
	const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

	const margin = { top: 0, right: 0, bottom: 0, left: 0 },
		width = props.width - margin.left - margin.right,
		height = props.height - margin.top - margin.bottom;

	const zonesMargin = 10
	const axisMargin = 40
	const chartMargin = 0.25
	const stakeBandHeight = 100
	const stakeholdersBandHeight = 100

	const negativeWidth = (width - zonesMargin) / 2
	const negativeHeight = height - stakeBandHeight * 2 - zonesMargin * 2
	const negativeProperties = {
		width: negativeWidth,
		height: negativeHeight,
		x: d3.scaleLinear()
			.domain([1 - chartMargin, maxImpact + chartMargin])
			.range([axisMargin, negativeWidth]),
		y: d3.scaleLinear()
			.domain([1 - chartMargin, maxLikelihood + chartMargin])
			.range([negativeHeight - axisMargin, 0]),
		margin: {
			top: stakeBandHeight + zonesMargin,
			right: 0,
			bottom: 0,
			left: 0
		}
	}

	const stakeBandProperties = {
		width,
		height: stakeBandHeight,
		x: d3.scaleLinear()
			.domain([0 - chartMargin * 10, 100 + chartMargin * 10])
			.range([0, width - 2 * axisMargin]),
	}

	const positiveWidth = (width - zonesMargin) / 2
	const positiveHeight = height - stakeBandProperties.height * 2 - zonesMargin * 2
	const positiveProperties = {
		width: positiveWidth,
		height: positiveHeight,
		x: d3.scaleLinear()
			.domain([1 - chartMargin, maxImpact + chartMargin])
			.range([0, positiveWidth - axisMargin]),
		y: d3.scaleLinear()
			.domain([1 - chartMargin, maxLikelihood + chartMargin])
			.range([positiveHeight - axisMargin, 0]),
		margin: {
			top: stakeBandProperties.height + zonesMargin,
			right: 0,
			bottom: 0,
			left: negativeWidth + zonesMargin
		}
	}

	const stakeholderBandProperties = {
		width,
		height: stakeBandProperties.height,
		x: d3.scaleLinear()
			.domain([0 - chartMargin * 10, 100 + chartMargin * 10])
			.range([0, width - 2 * axisMargin]),
		margin: {
			top: stakeholdersBandHeight + zonesMargin * 2 + negativeHeight,
			right: 0,
			bottom: 0,
			left: 0
		}
	}

	const selectPoint = (point) => {

		setSelectedStakeholders([]);
		setSelectedIndicators([]);
		setSelectedRisks([]);
		setSelectedOpportunities([]);

		if (point && point.__typename === "Risk") {
			// Select all indicators from point.impactedIndicators
			if (!point.isOpportunity) {
				setSelectedRisks([point]);
				setSelectedOpportunities(uniqBy(compact(opportunities.filter(o => o.id === point.matchingOpportunityId)), "id"));
			} else {
				setSelectedOpportunities([point]);
				setSelectedRisks(uniqBy(compact(risks.filter(r => r.matchingOpportunityId === point.id)), "id"));
			};
			setSelectedStakeholders(uniqBy(
				compact(
					stakeholders
						.filter(s => s.impactedRisks.map(ir => ir.riskId).includes(point.id)
						)
				), "id")
			);
			setSelectedIndicators(uniqBy(compact(point.impactedIndicators.map(i => similarIndicators[i.indicatorId])), "id"));
		}

		if (point && point.__typename === "Indicator") {
			// Select all risks and opportunities from point.impactedRisks and point.impactedOpportunities
			setSelectedIndicators([point]);
			setSelectedRisks(uniqBy(compact(filteredRisks.filter(r => r.impactedIndicators.map(ii => ii.indicatorId).includes(point.id))), "id"));
			setSelectedOpportunities(uniqBy(compact(filteredOpportunities.filter(r => r.impactedIndicators.map(ii => ii.indicatorId).includes(point.id))), "id"));
			setSelectedStakeholders(uniqBy(compact(stakeholders.filter(r => r.impactedIndicators.map(ii => ii.indicatorId).includes(point.id))), "id"));
		}

		if (point && point.__typename === "Stakeholder") {
			setSelectedStakeholders([point]);
			setSelectedRisks(uniqBy(compact(
				filteredRisks.filter(r => point.impactedRisks.map(ir => ir.riskId).includes(r.id))), "id")
			);
			setSelectedOpportunities(uniqBy(compact(
				filteredOpportunities.filter(r => point.impactedRisks.map(ir => ir.riskId).includes(r.id))), "id")
			);
			setSelectedIndicators(uniqBy(compact(
				indicators.filter(i => point.impactedIndicators.map(ii => ii.indicatorId).includes(i.id))), "id")
			);
		}

	}

	useEffect(() => {
		const svg = d3.select(svgRef.current)
	}, []);

	useEffect(() => {
		selectPoint(props.selection);
	}, [props.selection, props.risks, props.opportunities, props.indicators]);

	const stakeBandXY = (point) => {
		if (!point) return null

		return [
			axisMargin + stakeBandProperties.x(point.trend) +
			circleSpacer(similarIndicators[point.id].similarCount) * (stakeBandProperties.width * indicatorsMargin) * circlePlacementDx(similarIndicators[point.id], similarIndicators)
			,
			stakeBandProperties.height / 2 +
			circleSpacer(similarIndicators[point.id].similarCount) * (stakeBandProperties.width * indicatorsMargin) * circlePlacementDy(similarIndicators[point.id], similarIndicators)

		]
	}

	const stakeholdersBandXY = (point) => {
		if (!point) return null

		const score = adaptStakeholderScore(point);
		if (score === null) return null;
		const x = stakeholderBandProperties.x(score);
		const similarCount = similarStakeholders[point.id].similarCount;
		const similarStakeholdersList = similarStakeholders[point.id]

		const spaceBase = circleSpacer(similarCount) * (stakeholderBandProperties.width * indicatorsMargin)
		const result = [
			axisMargin + x +
			spaceBase * circlePlacementDx(similarStakeholdersList, similarStakeholders)
			,
			stakeholderBandProperties.height / 2 +
			stakeholderBandProperties.margin.top +
			spaceBase * circlePlacementDy(similarStakeholdersList, similarStakeholders)
		]

		return result
	}

	const riskXY = (point) => {
		if (!point) return null

		return [
			negativeProperties.x(point.impact) +
			circleSpacer(similarRisks[point.id].similarCount) * circlePlacementDx(similarRisks[point.id], similarRisks) * negativeProperties.width * baseMargin,
			negativeProperties.y(point.likelihood) +
			circleSpacer(similarRisks[point.id].similarCount) * circlePlacementDy(similarRisks[point.id], similarRisks) * negativeProperties.width * baseMargin + negativeProperties.margin.top
		]
	}

	const opportunityXY = (point) => {
		if (!point) return null

		return [
			positiveProperties.x(point.impact) +
			circleSpacer(similarOpportunities[point.id].similarCount) * circlePlacementDx(similarOpportunities[point.id], similarOpportunities) * positiveProperties.width * baseMargin + positiveProperties.margin.left,
			positiveProperties.y(point.likelihood) +
			circleSpacer(similarOpportunities[point.id].similarCount) * circlePlacementDy(similarOpportunities[point.id], similarOpportunities) * positiveProperties.width * baseMargin + positiveProperties.margin.top
		]
	}

	return (
		<div id="RiskScatterFullChart" style={{ ...style, position: "relative" }}>

			<svg
				width={"100%"}
				height={"100%"}
				style={{
					...style || {},
					width: "100%",
				}}
				ref={svgRef}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					props.onSelect(null);
				}}
			>
				<defs>
					<radialGradient id="gradientNegative" cx="95%" cy="5%" r="100%" >
						<stop offset="50%" stopColor={"#E70549"} stopOpacity={1} style={{
							transition: "stop-color 0.5s ease-out"
						}} />
						<stop className="color" offset="240%" stopColor="#FFFFFF" stopOpacity={1} />
					</radialGradient>
					<radialGradient id="gradientPositive" cx="95%" cy="5%" r="100%" >
						<stop offset="50%" stopColor={"#24CCB8"} stopOpacity={1} style={{
							transition: "stop-color 0.5s ease-out"
						}} />
						<stop className="color" offset="240%" stopColor="#FFFFFF" stopOpacity={1} />
					</radialGradient>
					<linearGradient id="gradientStakeBand" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="0%" stopColor="#E70549" />
						<stop offset="100%" stopColor="#24CCB8" />
					</linearGradient>
					<linearGradient id="gradientInfluence" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="0%" stopColor="#FFA500" />
						<stop offset="50%" stopColor="#FFdd00" />
						<stop offset="100%" stopColor="#FFA500" />
					</linearGradient>
				</defs>

				{
					<g transform={`translate(0, 0)`}>
						<rect
							width={stakeBandProperties.width - negativeProperties.x(2 * chartMargin)}
							height={stakeBandProperties.height}
							x={0}
							y={0}
							fill={props.showStakes ? 'url(#gradientStakeBand)' : "#FFFFFF06"}
						></rect>
						<text x={props.width / 2} y={stakeBandProperties.height / 2 + 26} textAnchor={"middle"} style={{ fontSize: "70", fontWeight: "600" }} fill={props.showStakes ? "#00000011" : "#FFFFFF00"}>STAKES</text>
					</g>
				}
				<g transform={`translate(0, ${negativeProperties.margin.top})`}>
					<rect
						className="background"
						x={0}
						y={0}
						width={negativeProperties.width}
						height={negativeProperties.height}
						fill={props.showRisks ? "url(#gradientNegative)" : "#FFFFFF06"}
						style={{
							transition: "fill 2.5s"
						}}
					></rect>
					<text x={(negativeProperties.width / 2) - 5} y={negativeProperties.y(1 - chartMargin) / 2 + 14} style={{ fontSize: "70", fontWeight: "600" }} fill={props.showRisks ? "#00000011" : "#FFFFFF00"} textAnchor='middle'>RISKS</text>
				</g>
				<g transform={`translate(${positiveProperties.margin.left}, ${positiveProperties.margin.top})`}>
					<rect
						className="background"
						x={0}
						y={0}
						width={positiveProperties.width}
						height={positiveProperties.height}
						fill={props.showOpportunities ? "url(#gradientPositive)" : "#FFFFFF06"}
						style={{
							transition: "fill 2.5s"
						}}
					></rect>
					<text x={(positiveProperties.width / 2) + 5} y={positiveProperties.y(1 - chartMargin) / 2} style={{ fontSize: "40", fontWeight: "600" }} fill={props.showOpportunities ? "#00000011" : "#FFFFFF00"} textAnchor='middle'>OPPORTUNITIES</text>

				</g>

				<g transform={`translate(${stakeholderBandProperties.margin.left}, ${stakeholderBandProperties.margin.top})`}>
					<rect
						width={stakeholderBandProperties.width}
						height={stakeholderBandProperties.height}
						x={0}
						y={0}
						fill={props.showStakeholders ? 'url(#gradientStakeBand)' : "#FFFFFF06"}
					></rect> : null
					<text x={(stakeholderBandProperties.width / 2) + 5} y={stakeholderBandProperties.height / 2 + 25} style={{ fontSize: "70", fontWeight: "600" }} fill={props.showStakeholders ? "#00000011" : "#FFFFFF00"} textAnchor='middle'>STAKEHOLDERS</text>
				</g>


				{
					!props.selection && props.showAllLinks && visibleLinks.map((origin, index) => {

						return visibleLinks.map((target, index) => {
							if (origin.id == target.id) return null;
							if (origin.__typename === "Risk" && target.__typename === "Risk" && origin.matchingOpportunityId != target.id) return null;

							let impacts = []
							if (origin.impactedRisks && target.__typename === "Risk") impacts = concat(impacts, origin.impactedRisks)
							if (origin.impactedIndicators && target.__typename === "Indicator") impacts = concat(impacts, origin.impactedIndicators)
							if (origin.__typename === "Risk" && target.__typename === "Risk") impacts = concat({
								riskId: origin.id,
								opportunityId: origin.matchingOpportunityId
							}, impacts)


							const impact = find(impacts, i => (i.riskId === origin.id || i.stakeholderId === origin.id) && (i.indicatorId === target.id || i.riskId === target.id || i.opportunityId === target.id));

							if (!impact) return null;

							const isBetweenRisks = origin && target && origin.__typename === "Risk" && target.__typename === "Risk";
							const hasIndicatorSource = origin && origin.__typename === "Indicator";
							const hasStakeholderSource = origin && origin.__typename === "Stakeholder";

							const originPositionFunction = origin.__typename === "Indicator" ? stakeBandXY :
								origin.__typename === "Stakeholder" ? stakeholdersBandXY :
									origin.isOpportunity ? opportunityXY :
										riskXY
							const targetPositionFunction = similarIndicators[target.id] ? stakeBandXY : similarStakeholders[target.id] ? stakeholdersBandXY : similarOpportunities[target.id] ? opportunityXY : riskXY

							const strength = impact?.strength;



							return origin && target && <>
								<path
									stroke={isBetweenRisks ? 'url(#gradientInfluence)' : 'orange'}
									key={`all-${origin.id}-${target.id}`}
									style={{ transitionDuration: "2.5s", transitionProperty: "d" }}
									className={isBetweenRisks ? 'influence' : (hasIndicatorSource || hasStakeholderSource) ? "reversed" : ""}

									strokeWidth={isBetweenRisks ? 1.5 : 1.5}
									strokeDasharray={isBetweenRisks ? "none" : strength && [
										'',
										'2, 8',
										'5, 4',
										'10 1'
									][strength] || "5,5"}
									d={`M${originPositionFunction(origin)[0]},${originPositionFunction(origin)[1]} L${targetPositionFunction(target)[0]},${targetPositionFunction(target)[1]}`}></path >
								<path
									stroke={"#00000002"}
									style={{ transitionDuration: "2.5s", transitionProperty: "d" }}
									key={`all-${origin.id}-${target.id}-bg`}

									strokeWidth={8}
									strokeDasharray={"none"}
									d={`M${originPositionFunction(origin)[0]},${originPositionFunction(origin)[1]} L${targetPositionFunction(target)[0]},${targetPositionFunction(target)[1]}`}></path>
							</>
						})

					})
				}
				{props.selection && flatten([selectedIndicators, selectedRisks, selectedOpportunities, selectedStakeholders]).map((targetObject, index) => {

					if (props.selection && props.selection.id && targetObject && targetObject.id && props.selection.id === targetObject.id) return null;
					if (!targetObject) return null;

					const origin = getItemAlteredByScenario(props.selection.__typename === "Indicator" ? similarIndicators[props.selection.id] :
						props.selection.__typename === "Stakeholder" ? similarStakeholders[props.selection.id] :
							props.selection.isOpportunity ? similarOpportunities[props.selection.id] :
								similarRisks[props.selection.id], props.scenario
					);
					const originPositionFunction = props.selection.__typename === "Indicator" ? stakeBandXY :
						props.selection.__typename === "Stakeholder" ? stakeholdersBandXY :
							props.selection.isOpportunity ? opportunityXY :
								riskXY

					const target = getItemAlteredByScenario(similarIndicators[targetObject.id] || similarOpportunities[targetObject.id] || similarRisks[targetObject.id] || similarStakeholders[targetObject.id], props.scenario)
					const targetPositionFunction = similarIndicators[targetObject.id] ? stakeBandXY : similarStakeholders[targetObject.id] ? stakeholdersBandXY : similarOpportunities[targetObject.id] ? opportunityXY : riskXY

					if (!target) return null;
					const impacts = concat(origin.impactedRisks || [], origin.impactedIndicators || [], target.impactedRisks || [], target.impactedIndicators || [])
					const impact = find(impacts, i => i.riskId === target.id || i.indicatorId === target.id || i.stakeholderId === target.id);

					const isBetweenRisks = origin && target && origin.__typename === "Risk" && target.__typename === "Risk";
					const hasIndicatorSource = origin && origin.__typename === "Indicator";
					const hasStakeholderSource = origin && origin.__typename === "Stakeholder";

					const strength = impact?.strength;

					return origin && target && <>
						<path
							stroke={isBetweenRisks ? 'url(#gradientInfluence)' : 'orange'}
							key={`${origin.id}-${target.id}`}
							style={{ transitionDuration: "2.5s", transitionProperty: "d" }}
							className={isBetweenRisks ? 'influence' : (hasIndicatorSource || hasStakeholderSource) ? "reversed" : ""}

							strokeWidth={isBetweenRisks ? 1.5 : 1.5}
							strokeDasharray={isBetweenRisks ? "none" : strength && [
								'',
								'2, 8',
								'5, 4',
								'10 1'
							][strength] || "5,5"}
							d={`M${originPositionFunction(origin)[0]},${originPositionFunction(origin)[1]} L${targetPositionFunction(target)[0]},${targetPositionFunction(target)[1]}`}></path >
						<path
							stroke={"#00000002"}
							style={{ transitionDuration: "2.5s", transitionProperty: "d" }}
							key={`${origin.id}-${target.id}-bg`}

							strokeWidth={8}
							strokeDasharray={"none"}
							d={`M${originPositionFunction(origin)[0]},${originPositionFunction(origin)[1]} L${targetPositionFunction(target)[0]},${targetPositionFunction(target)[1]}`}></path>
					</>
				})}

				<g transform={`translate(${axisMargin}, 0)`}>
					<g transform={`translate(0, ${stakeBandProperties.height / 2 - 1})`}>
						<IndicatorsPointsCloud
							data={alteredIndicators}
							width={stakeBandProperties.width - (axisMargin) - negativeProperties.x(3 * chartMargin)}
							{...stakeBandProperties}
							selectedPoints={selectedIndicators}
							isSelectedMode={selectedIndicators.length > 0 || selectedRisks.length > 0 || selectedOpportunities.length > 0}
							onHover={(element) => {
								setHoveredElement(element);
								setXY(stakeBandXY(element))
							}}
							onClick={(indicator) => {
								setSelectedIndicators([]);
								setSelectedStakeholders([]);
								setSelectedRisks([]);
								setSelectedOpportunities([]);
								props.onSelect({ ...indicator });

							}}
						>
						</IndicatorsPointsCloud>
					</g>
				</g>

				<g className="stakeholders" transform={`translate(${axisMargin}, ${stakeholderBandProperties.margin.top})`}>
					<g transform={`translate(0, ${stakeholderBandProperties.height / 2 - 1})`}>
						<StakeholdersPointsCloud
							data={alteredStakeholders}
							width={stakeholderBandProperties.width}
							{...stakeholderBandProperties}
							selectedPoints={selectedStakeholders}
							isSelectedMode={selectedIndicators.length > 0 || selectedRisks.length > 0 || selectedOpportunities.length > 0}
							onHover={(element) => {
								setHoveredElement(element);
								setXY(stakeholdersBandXY(element))
							}}
							onClick={(stakeholder) => {
								setSelectedIndicators([]);
								setSelectedStakeholders([]);
								setSelectedRisks([]);
								setSelectedOpportunities([]);
								props.onSelect({ ...stakeholder });

							}}
						>
						</StakeholdersPointsCloud>
					</g>
				</g>

				{props.showRisks ? <g transform={`translate(0, ${negativeProperties.margin.top})`}>
					<Axis
						{...negativeProperties}
						chartMargin={chartMargin}
						axisMargin={axisMargin}
						likelihoods={likelihoods}
						impacts={impacts}
					>
						<PointsCloud
							width={negativeProperties.width}
							{...negativeProperties}
							data={filteredRisks}
							likelihoods={likelihoods}
							impacts={impacts}
							isRiskView={true}
							selectedPoints={selectedRisks}
							isSelectedMode={selectedIndicators.length > 0 || selectedRisks.length > 0 || selectedOpportunities.length > 0}
							onHover={(element) => {
								setHoveredElement(element);
								setXY(riskXY(element))
							}}
							onClick={(risk) => {
								setSelectedIndicators([]);
								setSelectedStakeholders([]);
								setSelectedRisks([]);
								setSelectedOpportunities([]);
								props.onSelect(risk)
							}}
						></PointsCloud>
					</Axis>
				</g> : null}

				{props.showOpportunities ? <g transform={`translate(${positiveProperties.margin.left}, ${positiveProperties.margin.top})`}>
					<Axis

						{...positiveProperties}
						chartMargin={chartMargin}
						axisMargin={axisMargin}
						likelihoods={likelihoods}
						impacts={impacts}
						inverted={true}
					>
						<PointsCloud
							width={negativeProperties.width}
							{...positiveProperties}
							data={filteredOpportunities}
							likelihoods={likelihoods}
							impacts={impacts}
							isRiskView={false}
							selectedPoints={selectedOpportunities}
							isSelectedMode={selectedIndicators.length > 0 || selectedRisks.length > 0 || selectedOpportunities.length > 0}
							onHover={(element) => {
								setHoveredElement(element);
								setXY(opportunityXY(element))
							}}
							onClick={(risk) => {
								setSelectedIndicators([]);
								setSelectedStakeholders([]);
								setSelectedRisks([]);
								setSelectedOpportunities([]);
								props.onSelect(risk)
							}}
						></PointsCloud>
					</Axis>
				</g > : null}

			</svg >

			{
				hoveredElement && <Tooltip className="tooltip" overlayStyle={{ zIndex: 1000, minWidth: "300px", maxWidth: "400px" }} title={hoveredElement?.id && <div>
					<b>{hoveredElement ? hoveredElement.name : null}</b>


					{hoveredElement.__typename === "Risk" ? <>
						<div className="mt-2 mb-1">
							{t("models.risk.fields.impact")}: {impacts[parseInt(hoveredElement.impact)] ? hoveredElement.impact + " - " + impacts[parseInt(hoveredElement.impact)]?.name : hoveredElement.impact}
						</div>
						<div className="mb-1">
							{t("models.risk.fields.likelihood")}: {likelihoods[parseInt(hoveredElement.likelihood)] ? hoveredElement.likelihood + " - " + likelihoods[parseInt(hoveredElement.likelihood)]?.name : hoveredElement.likelihood}
						</div>
					</> :
						hoveredElement.__typename === "Indicator" ? <>
							<div className="mt-2 mb-1 nowrap d-flex">
								<span className="mr-1">{t("models.indicator.fields.trend")}:</span>
								<MiniSlider readOnly style={{
									width: "250px",
									height: "10px",
									marginTop: "3px"
								}} className="flex-grow-0 mt-05" value={hoveredElement.trend}></MiniSlider>
							</div>
							<div className="mb-1 nowrap d-flex">
								<span className="mr-1">{t("models.indicator.fields.weight")}: </span><Weight className="mini">{hoveredElement.weight}</Weight>
							</div>
						</> : <>
							<div className="mt-2 mb-1 nowrap d-flex">
								<ul>
									<li className="EditableListItem">
										<div style={{ width: '110px', textAlign: 'left' }}>
											Engagement
										</div>
										<div>
											{hoveredElement?.impact
												? {
													low: 'Low',
													medium: 'Medium',
													high: 'High',
													undefined: 'Inactive / Neutral'
												}[
												itemActiveness(
													hoveredElement?.x,
													hoveredElement?.y
												)
												]
												: null}{' '}
											{!hoveredElement || isItemInactive(hoveredElement)
												? ''
												: hoveredElement?.y > 0
													? 'positive'
													: 'negative'}
										</div>
									</li>

									<li className="EditableListItem">
										<div style={{ width: '110px', textAlign: 'left' }}>
											Importance
										</div>
										<div>
											{hoveredElement?.impact
												? {
													low: 'Low',
													medium: 'Medium',
													high: 'High'
												}[hoveredElement.impact]
												: 'Low'}
										</div>
									</li>

									<li className="EditableListItem">
										<div style={{ width: '110px', textAlign: 'left' }}>
											Trust
										</div>
										<div>
											{hoveredElement?.trend
												? {
													green: 'Positive',
													orange: 'At risk',
													neutral: 'Neutral',
													red: 'Negative'
												}[hoveredElement?.trend]
												: 'Neutral'}
										</div>
									</li>
									{hoveredElement?.description && (
										<li className="EditableListItem">
											<div>{hoveredElement?.description}</div>
										</li>
									)}
								</ul>

							</div>
						</>
					}

					< div className='text-center'>
						<small>
							Click to select
						</small>
					</div>
				</div>} open={hoveredElement} >
					<div id="Tooltip-root" key={hoveredElement?.id || "empty"}
						style={{ zIndex: 1000, position: "absolute", top: xy ? xy[1] - 15 + "px" : 0, left: xy ? xy[0] + margin.left + "px" : 0 }}>

					</div>
				</Tooltip >
			}




		</div >
	);
};
