import models from './models.js';
import pages from './pages/index.js';
import actions from './actions.js';
import messages from './messages.js';

export default {
	models,
	pages,
	actions,
	messages
};
