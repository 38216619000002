import {
	UpdateProjectMutationVariables,
	useCloneProjectMutation,
	useCreateProjectMutation,
	useCreateRiskCategoryMutation,
	useCreateTagMutation,
	useDeleteProjectMutation,
	useUpdateProjectMutation
} from 'graph/dist/generated';

export const useProjectController = () => {
	const [updateProject] = useUpdateProjectMutation();
	const [createProject] = useCreateProjectMutation();
	const [cloneProject] = useCloneProjectMutation();
	const [deleteProject] = useDeleteProjectMutation();
	const [createRiskCategory] = useCreateRiskCategoryMutation();
	const [createTag] = useCreateTagMutation();

	const handleClone = async (
		id: string,
		values: any,
		callback: Function | null = null
	) => {
		const result = await cloneProject({
			variables: {
				id,
				...values
			}
		});
		if (callback) callback(result?.data?.cloneProject?.id);

		return result?.data?.cloneProject?.id;
	};

	const handleCreate = async (values, callback: Function | null = null) => {
		const result = await createProject({
			variables: {
				...values
			}
		});

		// Create risk categories
		if (result?.data?.createProject?.id) {
			if (values.riskCategories) {
				await Promise.all(
					values.riskCategories.map(async (riskCategory: any) => {
						return createRiskCategory({
							variables: {
								name: riskCategory.name,
								projectId: result?.data?.createProject?.id
							}
						});
					})
				);
			}

			// Create or update each tag if needed
			if (values.tags) {
				await Promise.all(
					values.tags.map(async (tag: any) => {
						return createTag({
							variables: {
								name: tag.name,
								projectId: result?.data?.createProject?.id
							}
						});
					})
				);
			}
		}

		// Create tags

		if (callback) callback(result?.data?.createProject?.id);
		return result?.data?.createProject?.id;
	};

	const handleUpdate = async (id: string, values) => {
		let newValues: UpdateProjectMutationVariables = { id };

		if (values.name != undefined) newValues.name = values.name;
		if (values.baselineScenarioDescription != undefined)
			newValues.baselineScenarioDescription =
				values.baselineScenarioDescription;
		if (values.baselineScenarioImpacts != undefined)
			newValues.baselineScenarioImpacts = values.baselineScenarioImpacts;

		const result = await updateProject({
			variables: {
				...newValues
			},
			update(cache) {
				let fields = {};

				if (newValues.name !== undefined)
					fields.name = () => newValues.name;
				if (newValues.baselineScenarioDescription !== undefined)
					fields.baselineScenarioDescription = () =>
						newValues.baselineScenarioDescription;
				if (newValues.baselineScenarioImpacts !== undefined)
					fields.baselineScenarioImpacts = () =>
						newValues.baselineScenarioImpacts;

				cache.modify({
					id: `Project:${id}`,
					fields
				});
			},
			optimisticResponse: {
				updateProject: true
			}
		});
	};

	const handleDelete = async (
		id: string,
		callback: Function | null = null
	) => {
		if (id == null) {
			return;
		}

		await deleteProject({
			variables: { id }
		});

		if (callback) callback();
	};

	return {
		createProject: handleCreate,
		updateProject: handleUpdate,
		cloneProject: handleClone,
		deleteProject: handleDelete
	};
};
