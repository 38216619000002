export default {
	title: 'Scenarios',
	subtitle: 'List all the possible scenarios that you will evaluate',
	no_description: 'No scenario',

	context_worse: 'Negative',
	context_best: 'Positive',

	tabs: {
		list: 'List',
		details: 'Details',
		visualization: 'Visualization'
	}
};
