import React from 'react';

import { sortBy, filter, values } from 'lodash';

import Loader from 'components/Loading/Loader';

import {
	useGetPricesQuery,
	useMakePaymentMutation
} from 'graph/dist/generated';
import { Switch } from 'antd';

export const PlansList = (props: any) => {
	const [makePayment] = useMakePaymentMutation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [billingMode, setBillingMode] = React.useState<String>('year');
	const getPricesQuery = useGetPricesQuery();

	const startPaymentProcess = async (price: string) => {
		setLoading(true);
		let result = await makePayment({
			variables: {
				price: price,
				referer: document.location.href.replace(/\/profile.*/, '')
			}
		});
		console.log('result', result.data.makePayment);
		document.location.href = result.data.makePayment;

		return true;
	};

	return (
		<div className="plans">
			{getPricesQuery.loading ? (
				<Loader></Loader>
			) : (
				<>
					{filter(
						sortBy(getPricesQuery.data?.getPrices, 'unit_amount'),
						(p: any) =>
							JSON.parse(p.recurring).interval == billingMode
					).map((p, index) => {
						let metadata = JSON.parse(p.metadata);
						let recurringInfos = JSON.parse(p.recurring);
						return (
							<div className="plan" key={index}>
								<h3>{metadata.name}</h3>
								<p>{metadata.description} </p>
								<a
									className={[
										'paymentButton',
										loading ? 'disabled' : ''
									].join(' ')}
									onClick={() => startPaymentProcess(p.id)}
								>
									{!loading && 'Validate'}
									{loading && (
										<Loader
											size={20}
											color="white"
										></Loader>
									)}
								</a>
								<div className="price">
									{(recurringInfos.interval == 'month'
										? p.unit_amount / 100
										: p.unit_amount / 1200
									).toFixed(2)}{' '}
									USD/month
								</div>
								<div className="mt-3">
									<Switch
										checked={
											recurringInfos.interval == 'year'
										}
										unCheckedChildren="Paid monthly"
										checkedChildren="Paid yearly"
										onClick={() => {
											billingMode == 'year'
												? setBillingMode('month')
												: setBillingMode('year');
										}}
									></Switch>
								</div>
							</div>
						);
					})}
					<div className="plan">
						<h3>Pro</h3>
						<p>Custom plan </p>
						<a
							className={['paymentButton'].join(' ')}
							href="mailto:contact@gerositus.com"
						>
							Contact us
						</a>
					</div>
				</>
			)}
		</div>
	);
};

export default PlansList;
