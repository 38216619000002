// @ts-nocheck
import React from 'react';

import { Slider as SliderAntd, Tooltip } from 'antd';
import './MiniSlider.sass';
import { range } from 'lodash';

export const MiniSlider = (props: any) => {

	let marks = {
		0: ' ',
		16: ' ',
		33: ' ',
		50: ' ',
		66: ' ',
		83: ' ',
		100: ' '
	};

	const min = props.min || 0
	const max = props.max || 100

	if (props.step) {
		marks = {}
		range(1, props.max + props.step, props.step).forEach((i) => {
			marks[i] = ' ';
		});
	}

	return (
		<div
			className={
				props.className +
				' MiniSliderWrapper ' +
				(props.readOnly ? 'readonly' : '')
			}
			onClick={props.onClick}
			style={props.style}
		>
			{props.additionalMarkers &&
				props.additionalMarkers.map((m, index) => {

					console.log("props.additionalMarkers", props.additionalMarkers, 'm.value', m.value, "min", min, "max", max, "props.max", props.max, "props.value", props.value);

					return (
						<Tooltip key={index}
							title={m.name}
							zIndex={3000}
						>
							<div
								className="marker"
								style={{ left: props.max ? (((m.value - min) / (max - min) * 100) + '%') : ((m.value) + '%') }}
							></div>
						</Tooltip>
					);
				})}

			{props.readOnly ? (
				<SliderAntd
					disabled={props.readOnly ? props.readOnly : false}
					onAfterChange={(value) => {
						if (props.onChange) props.onChange(value);
					}}
					defaultValue={props.value || 0}
					value={props.readOnly ? props.value : undefined}
					marks={marks}
					step={props.step || null}
					tooltip={{
						open: false
					}}
					min={props.step ? 1 : 0}
					max={props.max || 100}
				/>
			) : (
				<SliderAntd
					onAfterChange={(value) => {
						if (props.onChange) props.onChange(value);
					}}
					defaultValue={props.value || 0}
					tooltip={{ open: false }}
					included={true}
					marks={marks}
					step={props.step || null}
					min={props.step ? 1 : 0}
					max={props.max || 100}
				/>
			)}
		</div>
	);
};

// <animated.div style={{ transform: `translate3d(${x}px,-5px,0)` }}>	</animated.div>
