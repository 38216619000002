import { getItemAlteredByScenario } from 'utils/rules';

export const filterStakeholders = (
	stakeholders: StakeholderModel[],
	filters,
	scenario
) => {
	if (!stakeholders) return [];

	let search = filters.name.replace(/tag:[^, ]+/g, '').trim();

	let filteredStakeholders = stakeholders.filter((s: any) => {
		let matching = true;
		if (search && s.name) {
			matching = s.name.toLowerCase().trim().match(search.toLowerCase());
		}

		if (filters.tags) {
			for (let i = 0; i < filters.tags.length; i++) {
				matching =
					matching &&
					s.taggings &&
					s.taggings
						.map((s: any) => s.tagId)
						.includes(filters.tags[i]);
			}
		} else {
			matching = matching && s.tags.length == 0;
		}

		return matching;
	});

	if (filters.icon) {
		filteredStakeholders = filteredStakeholders.filter((s: any) => {
			if (!s.icon || s.icon == 'faUser') return filters.icon === 'faUser';
			return filters.icon === s.icon;
		});
	}

	if (filters.indicator) {
		filteredStakeholders = filteredStakeholders.filter((s: any) => {
			return s.impactedIndicators
				?.map((ii: any) => ii.indicatorId)
				.includes(filters.indicator);
		});
	}

	if (filters.constraint) {
		filteredStakeholders = filteredStakeholders.filter((s: any) => {
			return s.impactedConstraints
				?.map((ii: any) => ii.constraintId)
				.includes(filters.constraint);
		});
	}

	if (filters.risk) {
		filteredStakeholders = filteredStakeholders.filter((s: any) => {
			return s.impactedRisks
				?.map((ii: any) => ii.riskId)
				.includes(filters.risk);
		});
	}

	if (scenario?.id) {
		//console.log('filteredStakeholders scenario', scenario?.id, filteredStakeholders);
		filteredStakeholders = filteredStakeholders.map((s: any) =>
			getItemAlteredByScenario(s, scenario)
		);

		//console.log('filteredStakeholders scenario after', scenario?.id, filteredStakeholders);
	}

	return filteredStakeholders;
};
