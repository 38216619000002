export const getNextStep = (step, projects, canCreateNewProjects) => {
	if (canCreateNewProjects && step == null) {
		return 1;
	}

	if (!canCreateNewProjects && step == null) {
		return 2;
	}

	if (step < 3) return step + 1;

	if (projects && projects.length > 0 && step < 3) {
		return 3;
	}

	return null;
};

export const getInitialStep = (projects, canCreateNewProjects) => {
	if (canCreateNewProjects) {
		return 1;
	}

	return 2;
};
