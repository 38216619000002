import React from 'react';

import { withTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import('./HorizontalVulnerabilityScale.sass');

class HorizontalVulnerabilityScale extends React.Component {
	render() {

		const { vulnerabilities, t } = this.props;

		return (
			<div
				className="HorizontalVulnerabilityScale"
				style={{ width: '500px' }}
			>
				<div className="top noselection">
					{t('pages.scenarios.context_best')}
				</div>
				<div className="middle">
					<hr />
				</div>
				<div className="bottom noselection">
					{t('pages.scenarios.context_worse')}
				</div>

				{vulnerabilities.map((v) => (
					v.vulnerability !== null ? <Tooltip
						placement="top"
						key={v.id || "baseline"}
						title={<div>{v.name || t("models.scenario.baseline")}</div>}
					>
						<div
							id={
								v.id == this.props.scenario?.id
									? 'bar_marker'
									: 'initial_bar_marker'
							}
							style={{ left: (v.vulnerability) + '%' }}
						></div>
					</Tooltip> : null
				))}
			</div>
		);
	}
}

export default withTranslation()(HorizontalVulnerabilityScale);
