import { findIndex, pick, keys, isFunction } from 'lodash';

import {
	useCreateStakeholderConstraintImpactMutation,
	useCreateStakeholderIndicatorImpactMutation,
	useCreateStakeholderRiskImpactMutation,
	useDeleteStakeholderConstraintImpactMutation,
	useDeleteStakeholderIndicatorImpactMutation,
	useDeleteStakeholderRiskImpactMutation,
	useUpdateStakeholderConstraintImpactStrengthMutation,
	useUpdateStakeholderIndicatorImpactStrengthMutation,
	useUpdateStakeholderMutation,
	useUpdateStakeholderRiskImpactStrengthMutation,
	useUpdateStakeholderScenarioMutation
} from 'graph/dist/generated';
import { optimisticlyUpdateScenario } from 'utils/graphql';
import { useXImpactController } from './useXImpact';

export const useStakeholderController = () => {
	const [updateStakeholder] = useUpdateStakeholderMutation();
	const [updateStakeholderScenario] = useUpdateStakeholderScenarioMutation();

	const stakeholderImpactController = useXImpactController();
	const [createStakeholderRiskImpact] =
		useCreateStakeholderRiskImpactMutation();
	const [deleteStakeholderRiskImpact] =
		useDeleteStakeholderRiskImpactMutation();
	const [updateStakeholderRiskImpactStrength] =
		useUpdateStakeholderRiskImpactStrengthMutation();
	const [createStakeholderIndicatorImpact] =
		useCreateStakeholderIndicatorImpactMutation();
	const [deleteStakeholderIndicatorImpact] =
		useDeleteStakeholderIndicatorImpactMutation();
	const [updateStakeholderIndicatorImpactStrength] =
		useUpdateStakeholderIndicatorImpactStrengthMutation();
	const [createStakeholderConstraintImpact] =
		useCreateStakeholderConstraintImpactMutation();
	const [deleteStakeholderConstraintImpact] =
		useDeleteStakeholderConstraintImpactMutation();
	const [updateStakeholderConstraintImpactStrength] =
		useUpdateStakeholderConstraintImpactStrengthMutation();

	const handleUpdateStakeholder = async (
		id,
		stakeholders,
		scenario,
		values
	) => {
		let index = findIndex(stakeholders, (s: any) => s.id === id);

		const newValues = {
			...pick(values, [
				'name',
				'description',
				'icon',
				'impact',
				'trend',
				'x',
				'y'
			])
		};

		let newStakeholder = {
			...pick(stakeholders[index], [keys(newValues)]),
			...newValues
		};

		if (values.evaluatedAt !== undefined)
			newStakeholder.evaluatedAt =
				!newStakeholder.evaluatedAt ||
				!isFunction(newStakeholder.evaluatedAt?.toISOString)
					? ''
					: newStakeholder.evaluatedAt.toISOString();

		if (scenario) {
			let scenarioId = scenario.id;
			let stakeholder = stakeholders[index];
			let scenarioValues =
				stakeholder.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				...pick(values, ['likelihood', 'impact'])
			};

			await optimisticlyUpdateScenario(
				stakeholder,
				'stakeholder',
				scenarioId,
				scenarioValues,
				updateStakeholderScenario
			);
		} else {
			const result = await updateStakeholder({
				variables: {
					id: id,
					...newStakeholder
				},
				update(cache) {
					let fields = {};
					if (values.name !== undefined)
						fields.name = () => values.name;
					if (values.description !== undefined)
						fields.description = () => values.description;
					if (values.impact !== undefined)
						fields.impact = () => values.impact;
					if (values.icon !== undefined)
						fields.icon = () => values.icon;
					if (values.trend !== undefined)
						fields.trend = () => values.trend;
					if (values.x !== undefined) fields.x = () => values.x;
					if (values.y !== undefined)
						fields.y = () => newStakeholder.y;

					cache.modify({
						id: `Stakeholder:${id}`,
						fields: {
							...fields
						}
					});
				},
				optimisticResponse: {
					updateStakeholder: {
						__typename: 'Stakeholder',
						id,
						...stakeholders[index],
						...newStakeholder
					}
				}
			});
		}
	};

	return {
		updateStakeholder: handleUpdateStakeholder,
		createRiskImpact: async (stakeholderId: string, riskId: string) => {
			console.log('createRiskImpact', stakeholderId, riskId);
			return await stakeholderImpactController.create(
				createStakeholderRiskImpact,
				'Stakeholder',
				stakeholderId,
				'Risk',
				riskId
			);
		},
		updateRiskImpactStrength: async (impact, strength) => {
			return await stakeholderImpactController.updateStrength(
				updateStakeholderRiskImpactStrength,
				impact,
				'Stakeholder',
				'Risk',
				strength
			);
		},
		deleteRiskImpact: async (id: string, stakeholderId: string) => {
			return await stakeholderImpactController.delete(
				deleteStakeholderRiskImpact,
				id,
				'Stakeholder',
				stakeholderId,
				'Risk'
			);
		},
		createIndicatorImpact: async (
			stakeholderId: string,
			indicatorId: string
		) => {
			console.log('createIndicatorImpact', stakeholderId, indicatorId);
			return await stakeholderImpactController.create(
				createStakeholderIndicatorImpact,
				'Stakeholder',
				stakeholderId,
				'Indicator',
				indicatorId
			);
		},
		updateIndicatorImpactStrength: async (impact, strength) => {
			return await stakeholderImpactController.updateStrength(
				updateStakeholderIndicatorImpactStrength,
				impact,
				'Stakeholder',
				'Indicator',
				strength
			);
		},
		deleteIndicatorImpact: async (id: string, stakeholderId: string) => {
			return await stakeholderImpactController.delete(
				deleteStakeholderIndicatorImpact,
				id,
				'Stakeholder',
				stakeholderId,
				'Indicator'
			);
		},
		createConstraintImpact: async (
			stakeholderId: string,
			constraintId: string
		) => {
			console.log('createConstraintImpact', stakeholderId, constraintId);
			return await stakeholderImpactController.create(
				createStakeholderConstraintImpact,
				'Stakeholder',
				stakeholderId,
				'Constraint',
				constraintId
			);
		},
		updateConstraintImpactStrength: async (impact, strength) => {
			return await stakeholderImpactController.updateStrength(
				updateStakeholderConstraintImpactStrength,
				impact,
				'Stakeholder',
				'Constraint',
				strength
			);
		},
		deleteConstraintImpact: async (id: string, stakeholderId: string) => {
			return await stakeholderImpactController.delete(
				deleteStakeholderConstraintImpact,
				id,
				'Stakeholder',
				stakeholderId,
				'Constraint'
			);
		}
	};
};
