import { MiniSlider } from 'components/controls/MiniSlider';

import Weight from 'components/controls/Weight';
import { filter } from 'lodash';
import { getItemAlteredByScenario } from 'utils/rules';

import './FishboneBranchPopover.sass';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const FishboneBranchPopover = (props: any) => {
	return (
		<div className="FishboneBranchPopover">
			<div className="d-flex">
				<div className="title noselection">Weight</div>

				<Weight
					dark={true}
					onClick={(values: any) => {
						let newWeight = (props.weight || 1) + 1;
						if (newWeight > 3) newWeight = 1;
						props.onUpdate(props.id, { weight: newWeight });
					}}
				>
					{props.weight || 1}
				</Weight>
			</div>
		</div>
	);
};
