import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'antd';

import { keyBy, keys, maxBy, values } from 'lodash';

export const Axis = (props: any) => {
    const svgRef = useRef();

    const { likelihoods = [], impacts = [] } = props;

    const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
    const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

    useEffect(() => {
        const svg = d3.select(svgRef.current)

        // Add X axis - without axis line
        let xAxisGenerator = d3.axisBottom(props.x);
        xAxisGenerator.tickSize(0)
        xAxisGenerator.ticks(maxImpact)

        // Add the axis label
        svg.append("text")
            .attr("x", props.inverted ? props.x(maxLikelihood - 0.5) : props.x(1.5))
            .attr("y", `${props.height - 10}`)
            .style("text-anchor", "middle")
            // Size
            .style("font-size", "12px")
            // Color
            .style("fill", "#888888")
            .style("text-shadow", "0px 0px 0px black")
            .style("stroke", "0")
            .text("Impact →")


        svg.append("g")
            .attr("transform", `translate(0, ${props.height - 21})`)
            .call(xAxisGenerator)
            .select("path, line")
            .remove();

        let yAxisGenerator = (props.inverted ? d3.axisRight : d3.axisLeft)(props.y);
        yAxisGenerator.tickSize(0);
        yAxisGenerator.ticks(maxLikelihood);

        svg.append("g")
            .attr("transform", `translate(${props.inverted ? props.width - 17 : 15}, 0)`)
            .call(yAxisGenerator)
            .select("path, line")
            .remove();

        // Make the axis lines white
        svg.selectAll(".tick text, .tick line")
            .style("fill", "#777777");

        // Make the ticks white
        svg.selectAll("line")
            .style("stroke", "#777777");

        // Add the other axis label
        svg.append("g")
            .attr("transform", `translate(${props.inverted ? props.width - 7 : 13}, ${props.y(1.5)})`)
            .append("text")
            .attr("transform", "rotate(-90)")
            .style("text-anchor", "middle")
            // Size
            .style("font-size", "12px")
            // Color
            .style("fill", "#888888")
            .text("Likelihood →")

        /* svg.append("circle")
            .attr("cx", props.x(1 - props.chartMargin))
            .attr("cy", props.y(1 - props.chartMargin))
            .attr("r", 2)
            .style("fill", "white");

        svg.append("circle")
            .attr("cx", props.x(1))
            .attr("cy", props.y(1))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact))
            .attr("cy", props.y(maxLikelihood))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact + props.chartMargin))
            .attr("cy", props.y(maxLikelihood + props.chartMargin))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(1))
            .attr("cy", props.y(maxLikelihood))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(1 - props.chartMargin))
            .attr("cy", props.y(maxLikelihood + props.chartMargin))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact))
            .attr("cy", props.y(1))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact + props.chartMargin))
            .attr("cy", props.y(1 - props.chartMargin))
            .attr("r", 2)
            .style("fill", "grey"); */
    }, []);

    return (
        <svg className="axes" ref={svgRef}>{props.children}</svg>
    );
};
