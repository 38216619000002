import { Modal, Button, Form, Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const ScenarioEditionModal = (props: any) => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const [isCloning, setIsCloning] = useState(false);

	const handleFormSubmit = () => {
		console.log('handleFormSubmit');
		form.validateFields()
			.then((values) => {
				console.log('validated', values);
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			{props.onDelete && props.id && (
				<Button
					onClick={() => {
						props.onDelete(props);
					}}
					danger={true}
					style={{ marginRight: 'auto' }}
				>
					Delete
				</Button>
			)}

			{props.onPromoteAsNewBaseline && props.id && (<Button
				onClick={() => {
					setIsCloning(true)
					props.onPromoteAsNewBaseline(props, () => { setIsCloning(false) });
				}}
				loading={isCloning}
				danger={true} style={{ marginRight: 'auto' }}>
				Save as new baseline
			</Button>)}
			<Button
				onClick={() => {
					props.onHide();
				}}
				style={{ marginRight: 'auto' }}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	return (
		<Modal
			open={true}
			title={props.id !== false ? 'Scenario edition' : 'Create a new scenario'}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			maskClosable={false}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values);
					props.onHide();
				}}
				disabled={isCloning}
				initialValues={props.values}
			>
				<Form.Item
					name="name"
					label="Name"
					required={true}
					rules={[
						{
							max: 35,
							message:
								t("messages.form.max_length_exceeded_error", { length: 35 })
						},
						{
							required: true,
							message: t("messages.form.required_error")
						}
					]}

				>
					<Input
						autoComplete='off'
						disabled={props.id !== null ? false : true}
						count={{
							show: true,
							max: 35,
						}}></Input>
				</Form.Item>
				<Form.Item name="description" label="Description" rules={[
					{
						max: 300,
						message:
							t("messages.form.max_length_exceeded_error", { length: 300 })
					}
				]}>
					<Input.TextArea
						rows={5}
						count={{
							show: true,
							max: 300,
						}}></Input.TextArea>
				</Form.Item>
				<Form.Item name="impacts" label="Impacts description" rules={[
					{
						max: 500,
						message:
							t("messages.form.max_length_exceeded_error", { length: 500 })
					}
				]}>
					<Input.TextArea
						rows={7}
						count={{
							show: true,
							max: 500,
						}}
					></Input.TextArea>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ScenarioEditionModal;
