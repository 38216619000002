import React from 'react';

import { Input, Tag } from "antd";
import { useTranslation } from "react-i18next";

export const FiltersTab = (props: any) => {

    const { t } = useTranslation();
    let {
        categories = [],

        tags = [],
        usedTags = [],
        tagFilter = [],

        likelihoods = [],
        likelihoodFilter = [],

        impacts = [],
        impactFilter = [],

        stakeholders = [],
        stakeholderFilter = [],

        indicators = [],
        indicatorFilter = []
    } = props;

    impacts = impacts || [];
    likelihoods = likelihoods || [];

    return (
        <div className="FiltersTab">
            <Input
                onChange={(e) => {
                    props.setSearchTerm(e.target.value);
                }}
                value={props.searchTerm}

                placeholder="Search"
                className="SearchInput w-100"
            ></Input>

            <div
                className="text-left mt-4"
                style={{ marginBottom: '0.5rem' }}
            >
                {t('pages.risks.all_categories')}
            </div>

            <div className="text-left">
                {categories.map((t, index) => (
                    <Tag
                        key={'t' + index}
                        onClick={() => {
                            // Add filter if not present, remove it if already present
                            props.categoryFilter.includes(t.id)
                                ? props.setCategoryFilter(
                                    props.categoryFilter.filter(
                                        (category) =>
                                            category !==
                                            t.id
                                    )
                                )
                                : props.setCategoryFilter([
                                    ...props.categoryFilter,
                                    t.id
                                ]);
                            //tagFilter == t ? setTagFilter(null) : setTagFilter(t)
                        }}
                        className={[
                            'tag',
                            props.categoryFilter.includes(t.id)
                                ? ''
                                : 'unselected'
                        ].join(' ')}
                    >
                        {t.name || t.id}
                    </Tag>
                ))}
            </div>


            <div
                className="text-left mt-4"
                style={{ marginBottom: '0.5rem' }}
            >
                {t('pages.risks.all_impacts')}
            </div>

            <div className="text-left">
                {impacts.map((t, index) => (
                    <Tag
                        key={'t' + index}
                        onClick={() => {
                            // Add filter if not present, remove it if already present
                            impactFilter.includes(t.id)
                                ? props.setImpactFilter(
                                    impactFilter.filter(
                                        (category) =>
                                            category !==
                                            t.id
                                    )
                                )
                                : props.setImpactFilter([
                                    ...impactFilter,
                                    t.id
                                ]);
                            //tagFilter == t ? setTagFilter(null) : setTagFilter(t)
                        }}
                        className={[
                            'tag',
                            impactFilter.includes(t.id)
                                ? ''
                                : 'unselected'
                        ].join(' ')}
                    >
                        {t.name || t.id}
                    </Tag>
                ))}
            </div>

            <div
                className="text-left mt-4"
                style={{ marginBottom: '0.5rem' }}
            >
                {t('pages.risks.all_likelihoods')}
            </div>

            <div className="text-left">
                {likelihoods.map((t, index) => (
                    <Tag
                        key={'t' + index}
                        onClick={() => {
                            // Add filter if not present, remove it if already present
                            likelihoodFilter.includes(t.id)
                                ? props.setLikelihoodFilter(
                                    likelihoodFilter.filter(
                                        (category) =>
                                            category !==
                                            t.id
                                    )
                                )
                                : props.setLikelihoodFilter([
                                    ...likelihoodFilter,
                                    t.id
                                ]);
                            //tagFilter == t ? setTagFilter(null) : setTagFilter(t)
                        }}
                        className={[
                            'tag',
                            likelihoodFilter.includes(t.id)
                                ? ''
                                : 'unselected'
                        ].join(' ')}
                    >
                        {t.name || t.id}
                    </Tag>
                ))}
            </div>

            <div
                className="text-left mt-4"
                style={{ marginBottom: '0.5rem' }}
            >
                {t('pages.navigator.all_tags')}
            </div>

            {
                tags.length == 0 ? (
                    <div
                        className="text-center"
                        style={{
                            color: 'rgba(46, 160, 223, 0.3)'
                        }}
                    >
                        {t('pages.navigator.no_tag')}
                    </div>
                ) : (
                    <div className="text-left">
                        {tags.map((t, index) => (
                            <Tag
                                key={'t' + index}
                                onClick={() => {
                                    // Add filter if not present, remove it if already present
                                    tagFilter.includes(t.id)
                                        ? props.setTagFilter(
                                            tagFilter.filter(
                                                (tag) =>
                                                    tag !==
                                                    t.id
                                            )
                                        )
                                        : props.setTagFilter([
                                            ...tagFilter,
                                            t.id
                                        ]);
                                    //tagFilter == t ? setTagFilter(null) : setTagFilter(t)
                                }}
                                className={[
                                    'tag',
                                    usedTags.includes(t.id) ? "used" : "unused",
                                    tagFilter.includes(t.id)
                                        ? ''
                                        : 'unselected'
                                ].join(' ')}
                            >
                                {t.name}
                            </Tag>
                        ))}
                    </div>
                )
            }

            <div
                className="text-left mt-4"
                style={{ marginBottom: '0.5rem' }}
            >
                {t('pages.risks.all_stakes')}
            </div>

            {
                indicators.length == 0 ? (
                    <div
                        className="text-center"
                        style={{
                            color: 'rgba(46, 160, 223, 0.3)'
                        }}
                    >
                        {t('models.indicator.no_x')}
                    </div>
                ) : (
                    <div className="text-left">
                        {indicators.map((t, index) => (
                            <Tag
                                key={'t' + index}
                                onClick={() => {
                                    // Add filter if not present, remove it if already present
                                    indicatorFilter.includes(t.id)
                                        ? props.setIndicatorFilter(
                                            indicatorFilter.filter(
                                                (tag) =>
                                                    tag !==
                                                    t.id
                                            )
                                        )
                                        : props.setIndicatorFilter([
                                            ...indicatorFilter,
                                            t.id
                                        ]);
                                    //indicatorFilter == t ? setStakeholderFilter(null) : setStakeholderFilter(t)
                                }}
                                className={[
                                    'tag',
                                    indicatorFilter.includes(t.id)
                                        ? ''
                                        : 'unselected'
                                ].join(' ')}
                            >
                                {t.name}
                            </Tag>
                        ))}
                    </div>
                )
            }

            <div
                className="text-left mt-4"
                style={{ marginBottom: '0.5rem' }}
            >
                {t('pages.risks.all_stakeholders')}
            </div>

            {
                stakeholders.length == 0 ? (
                    <div
                        className="text-center"
                        style={{
                            color: 'rgba(46, 160, 223, 0.3)'
                        }}
                    >
                        {t('models.item.no_x')}
                    </div>
                ) : (
                    <div className="text-left">
                        {stakeholders.map((t, index) => (
                            <Tag
                                key={'t' + index}
                                onClick={() => {
                                    // Add filter if not present, remove it if already present
                                    stakeholderFilter.includes(t.id)
                                        ? props.setStakeholderFilter(
                                            stakeholderFilter.filter(
                                                (tag) =>
                                                    tag !==
                                                    t.id
                                            )
                                        )
                                        : props.setStakeholderFilter([
                                            ...stakeholderFilter,
                                            t.id
                                        ]);
                                    //stakeholderFilter == t ? setStakeholderFilter(null) : setStakeholderFilter(t)
                                }}
                                className={[
                                    'tag',
                                    stakeholderFilter.includes(t.id)
                                        ? ''
                                        : 'unselected'
                                ].join(' ')}
                            >
                                {t.name}
                            </Tag>
                        ))}
                    </div>
                )
            }
        </div >
    );
}