import { useState } from 'react';
import { MiniSlider } from 'components/controls/MiniSlider';

import Weight from 'components/controls/Weight';
import { filter, keyBy, maxBy, values } from 'lodash';
import { getItemAlteredByScenario } from 'utils/rules';

import './ElementPopover.sass';
import { useTranslation } from 'react-i18next';


const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const ElementPopover = (props: any) => {

	const { likelihoods = [], impacts = [] } = props;

	const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
	const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

	const indexedLikelihoods = keyBy(likelihoods, 'id');
	const indexedImpacts = keyBy(impacts, 'id');

	const [temporaryValue, setTemporaryValue] = useState(null);

	const { t } = useTranslation();

	const otherScenarios = filter(
		props.scenarios || [],
		(sc) => sc.id !== props.scenario?.id
	)

	if (props.scenario) {
		otherScenarios.push({ id: null });
	}

	return (
		<div className="ElementPopover">
			<div className="d-flex description">
				<small>{props.description}</small>
			</div>
			{props.originalElement?.__typename !== 'Risk' &&
				<>
					<div className="mb-4">
						<div className="title noselection">Status</div>

						<MiniSlider
							value={props.trend}
							key={props?.id + '-' + props.scenario?.id}
							onChange={(value) =>
								props.onUpdate(props.id, { trend: '' + value })
							}
							min={0}
							max={100}
							className="highContrast w-100"
							additionalMarkers={otherScenarios.map((sc) => {
								return {
									id: sc.id,
									name: sc.name || t("models.scenario.baseline"),
									value: getItemAlteredByScenario(
										props.originalElement,
										sc
									).trend
								};
							})}
						></MiniSlider>
					</div>
					<div className="d-flex">
						<div className="title noselection">Weight</div>

						<Weight
							dark={true}
							onClick={(values: any) => {
								let newWeight = props.weight + 1;
								if (newWeight > 3) newWeight = 0;
								props.onUpdate(props.id, { weight: newWeight });
							}}
						>
							{props.weight}
						</Weight>
					</div>
				</>
			}

			{props.originalElement?.__typename === 'Risk' &&
				<>
					<div className="mb-4">
						<div className="title noselection nowrap">
							Impact:
							&nbsp;
							{indexedImpacts && indexedImpacts[parseInt(props.impact)] ? <span className="value">{props.impact} - {indexedImpacts[parseInt(props.impact)].name}</span> : props.impact}
						</div>

						<MiniSlider
							value={props.impact}
							key={props?.id + '-' + props.scenario?.id}
							onChange={(value) => {
								props.onUpdate(props.id, { impact: '' + value })
								setTemporaryValue(null);
							}}

							className={`highContrast w-100 ${props.isOpportunity ? 'positive' : 'negative'}`}
							step={0.5}
							max={maxImpact}
							min={1}
							additionalMarkers={otherScenarios.map((sc) => {
								return {
									id: sc.id,
									name: sc.name || t("models.scenario.baseline"),
									value: getItemAlteredByScenario(
										props.originalElement,
										sc
									).impact
								};
							})}
						></MiniSlider>

					</div>

					<div className="mb-2 w-100">
						<div className="title noselection nowrap">Likelihood:
							&nbsp;
							{indexedLikelihoods && indexedLikelihoods[parseInt(props.likelihood)] ? <span className="value">{props.likelihood} - {indexedLikelihoods[parseInt(props.likelihood)].name}</span> : props.impact}
						</div>

						<MiniSlider
							value={props.likelihood}
							key={props?.id + '-' + props.scenario?.id}
							onChange={(value) => {
								props.onUpdate(props.id, { likelihood: '' + value })
								setTemporaryValue(null);
							}}
							className={`highContrast w-100 ${props.isOpportunity ? 'positive' : 'negative'}`}
							step={0.5}
							min={1}
							max={maxLikelihood}
							additionalMarkers={otherScenarios.map((sc) => {
								return {
									id: sc.id,
									name: sc.name || t("models.scenario.baseline"),
									value: getItemAlteredByScenario(
										props.originalElement,
										sc
									).likelihood
								};
							})}
						></MiniSlider>
					</div>

				</>}
		</div >
	);
};
