import { Variable } from './data';
import * as d3 from 'd3';

//
// Constants
//
export const INNER_RADIUS = 20;
const GRID_NUMBER = 5;
const GRID_COLOR = '#e0e0e031';
const TEXT_COLOR = '#e0e0e0BB';
const TICK_LENGTH = 10;

//
// Types
//
export type AxisConfig = {
    name: Variable;
    max: number;
};

type RadarGridProps = {
    outerRadius: number;
    xScale: d3.ScaleBand<string>;
    axisConfig: AxisConfig[];
};

const polarToCartesian = (angle: number, distance: number) => {
    const x = distance * Math.cos(angle);
    const y = distance * Math.sin(angle);
    return { x, y };
};


/*
  A react component that adds a grid background 
  for a radar chart in polar coordinates
*/
export const RadarGrid = ({
    outerRadius,
    xScale,
    axisConfig,
}: RadarGridProps) => {
    const lineGenerator = d3.lineRadial();

    // Compute Axes = from center to outer
    const allAxes = axisConfig.map((axis, i) => {
        const angle = xScale(axis.name);

        if (angle === undefined) {
            return null;
        }

        const path = lineGenerator([
            [angle, INNER_RADIUS],
            [angle, outerRadius],
        ]);

        const labelPosition = polarToCartesian(
            angle - Math.PI / 2,
            outerRadius + 10
        );

        const ticks = [...Array(GRID_NUMBER).keys()].map((tickIndex) => {
            const tickDistance = INNER_RADIUS + (tickIndex * (outerRadius - INNER_RADIUS)) / GRID_NUMBER;
            const { x: x1, y: y1 } = polarToCartesian(angle - Math.PI / 2, tickDistance);
            const { x: x2, y: y2 } = polarToCartesian(angle - Math.PI / 2, tickDistance);

            // Perpendicular tick calculation
            const tickAngle = angle - Math.PI / 2; // perpendicular to the axis angle
            const { x: tx1, y: ty1 } = polarToCartesian(tickAngle - Math.PI / 2, TICK_LENGTH / 2);
            const { x: tx2, y: ty2 } = polarToCartesian(tickAngle + Math.PI / 2, TICK_LENGTH / 2);

            return (
                <line
                    key={tickIndex}
                    x1={x1 + tx1}
                    y1={y1 + ty1}
                    x2={x2 + tx2}
                    y2={y2 + ty2}
                    stroke={GRID_COLOR}
                    strokeWidth={0.5}
                />
            );
        });

        return (
            <g key={i}>
                <path d={path} stroke={GRID_COLOR} strokeWidth={0.5} rx={1} />
                {ticks}
                <text
                    x={labelPosition.x}
                    y={labelPosition.y}
                    fontSize={12}
                    fill={TEXT_COLOR}
                    textAnchor={labelPosition.x > 2 ? 'start' : labelPosition.x < -2 ? 'end' : 'middle'}
                    dominantBaseline="middle"
                >
                    {axis.label}
                </text>
            </g>
        );
    });

    return (
        <g>
            {allAxes}
        </g>
    );
};
