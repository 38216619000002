import { sortBy, findIndex } from 'lodash';

import dayjs from 'dayjs';

import EditableListItem from 'components/List/EditableListItem';

import './CommentsList.sass';

//onDelete={() => props.onDelete(o.id)}

export const CommentsList = (props: any) => {
	return (
		<div className="CommentsList mb-3 w-100">
			{sortBy(
				props.items || [],
				(o) => -dayjs(o.commentedAt).unix()
			).map((o: any, index: number) => (
				<EditableListItem
					deletable={true}
					editable={true}
					key={o.id}
					onEdit={() => props.onEdit(o.id)}
				>
					<div className="pb-2 pt-2">
						<small>
							{o.commentedAt
								? dayjs(o.commentedAt).format('DD/MM/YYYY')
								: dayjs(o.createdAt).format('DD/MM/YYYY')}
						</small>
						&nbsp;{' - '}&nbsp;
						{o.description}
					</div>
				</EditableListItem>
			))}
		</div>
	);
};

export default CommentsList;
