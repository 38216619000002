import { keyBy, sortBy, find, set } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Input, Tag } from 'antd';

import './BotTab.sass';
import React, { useState } from 'react';
import { BotMessage } from 'components/bot/BotMessage';
import Markdown from 'react-markdown';

import { useUpdateProjectConsentMutation } from 'graph/dist/generated';

// Props
interface GeneralTabProps {
    messages: any[];
    onSendMessage: Function
    consent: string
    projectId: string
}

export const BotTab = (props: GeneralTabProps) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [aiConsentChecked, setAiConsentChecked] = useState(false);
    const [aiProjectConsentChecked, setAiProjectConsentChecked] = useState(false);

    const [updateConsent] = useUpdateProjectConsentMutation();
    const handleUpdateConsent = () => {

        const newValue = aiConsentChecked && aiProjectConsentChecked ? "FULL" : aiConsentChecked ? "BASIC" : "NONE";
        console.log("update consent", newValue)

        updateConsent({
            variables: {
                projectId: props.projectId,
                aiConsent: newValue
            },
            update(cache) {
                cache.modify({
                    id: `Project:${props.projectId}`,
                    fields: {
                        aiConsent() {
                            return newValue;
                        }
                    }
                });
            },
        })
    }

    const handleSendMessage = (callback) => {
        setLoading(true);
        props.onSendMessage(message, callback);
        setMessage("");
    }

    return (
        <>
            <div className="BotTab">

                {props.consent && props.consent !== "NONE" ? <>

                    <div className="BodyWrapper">
                        {props.messages.map((message, index) => {
                            console.log("message", message)
                            return (
                                <BotMessage key={index} message={message}>

                                </BotMessage>
                            )
                        })}
                        {loading && <BotMessage loading={true} role={"assistant"}></BotMessage>}
                    </div>
                </> :
                    <>
                        <BotMessage role="assistant">
                            <Markdown>{t(import.meta.env.VITE_APP_DISABLE_FULL_AI ? 'messages.legal.ai_disclaimer_limited' : 'messages.legal.ai_disclaimer_consent')}</Markdown>

                            <Checkbox
                                type="checkbox"
                                id="disclaimer"
                                className='mr-05'
                                checked={aiConsentChecked}
                                onChange={() => setAiConsentChecked(!aiConsentChecked)}
                            />
                            <label htmlFor="disclaimer">
                                {t('messages.legal.ai_disclaimer_consent')}
                            </label>

                            <br />

                            {
                                !import.meta.env.VITE_APP_DISABLE_FULL_AI &&

                                <>
                                    <br />
                                    <Markdown>{t('messages.legal.ai_project_disclaimer')}</Markdown>

                                    <>
                                        <Checkbox
                                            type="checkbox"
                                            id="disclaimer"
                                            className='mr-05'
                                            checked={aiConsentChecked && aiProjectConsentChecked}
                                            disabled={!aiConsentChecked}
                                            onChange={() => setAiProjectConsentChecked(!aiProjectConsentChecked)}
                                        />
                                        <label htmlFor="disclaimer">
                                            {t('messages.legal.ai_project_consent')}
                                        </label>

                                    </>
                                </>
                            }

                            <Button className="mt-4" onClick={() => handleUpdateConsent()}>Save my preferences</Button>

                        </BotMessage>
                        <div className="disclaimer">

                        </div>
                    </>
                }


                {props.consent && props.consent !== "NONE" && <><div className='InputWrapper pt-1 pb-05'>
                    <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 10 }}
                        className='input'
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                        placeholder='Enter your question here'
                    />

                    <Button
                        onClick={(message) => handleSendMessage(() => {
                            setLoading(false)
                        })}
                        className="w-100 mt-2"
                        type="primary"
                        style={{ marginBottom: 10 }}
                    >
                        {t('actions.send')}
                    </Button>
                </div>
                </>}

            </div>
        </>
    );
};

export default BotTab;
